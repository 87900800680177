import { takeEvery } from 'redux-saga/effects';
import createAsyncSaga from '../lib';
import { USER_CNTN_LIST, findCntnListAsync } from './actions';
import { userCntnLogList } from '../../api/cntn';

const userCntnSaga = createAsyncSaga(findCntnListAsync, userCntnLogList);

export function* cntnSaga() {
  yield takeEvery(USER_CNTN_LIST, userCntnSaga);
}
