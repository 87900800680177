import axios from 'axios';
import config from '../config';
import { PotalCommDto } from '../types/PotalCommDto';
import { ErrorResponse, SuccessResponse } from '../types/Response';
import { ResultPaymntDto, SelectPaymntDto } from '../types/BuyDto';
import accessTokenPortalApi from './api';


export async function paymentsList(selectPaymntDto: SelectPaymntDto): Promise<SuccessResponse<ResultPaymntDto[], PotalCommDto> | ErrorResponse> {
    try {
        const response = await accessTokenPortalApi.get<SuccessResponse<ResultPaymntDto[], PotalCommDto> | ErrorResponse>(
            `/buy/admin/paymnts`,
            {
                params: selectPaymntDto, // 쿼리 스트링으로 DTO 전달
            }
        );

        if (response.data.status === 'success') {
            return response.data;
        } else {
            return {
                status: 'error',
                error: {
                    code: 'API_CALL_FAILED',
                    message: 'Failed to fetch payment list',
                },
            };
        }
    } catch (error) {
        console.error('Error fetching payment list:', error);

        return {
            status: 'error',
            error: {
                code: 'NETWORK_ERROR',
                message: 'An error occurred while fetching the payment list',
            },
        };
    }
}