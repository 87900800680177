import { createReducer } from 'typesafe-actions';
import { asyncState, createAsyncReducer, transformToArray } from '../lib/reducerUtils';
import { MyPageState } from './types';
import {
  getNeospectraUsagesServiesLeftAsync,
  getMapprimeDashboardAsync,
  getNeospectraProjectsStatsAsync,
  getNeospectraJobsStatsAsync,
  getInfoseedDashboardAsync,
  getBuyScrtnListAsync,
  getBuyUseListAsync,
  getNeospectraUsagesCurrentAsync
} from './actions';

import { AnyAction } from 'redux';

const initialState: MyPageState = {
  // GEO_NIC
  infoseedDashboard: asyncState.initial(),
  // SEMMETA
  neoJobsStat: asyncState.initial(),
  neoProjectsStat: asyncState.initial(),
  neoUsagesServies: asyncState.initial(),
  neoUsagesCurrent: asyncState.initial(),
  // MAPPRIME
  mapprimeDashboard: asyncState.initial(),
  buyScrtnList: asyncState.initial(),
  buyUseList: asyncState.initial(),
};

const myPageReducer = createReducer<MyPageState, AnyAction>(initialState)
  .handleAction(
    transformToArray(getInfoseedDashboardAsync),
    createAsyncReducer(getInfoseedDashboardAsync, 'infoseedDashboard')
  )
  .handleAction(
    transformToArray(getNeospectraJobsStatsAsync),
    createAsyncReducer(getNeospectraJobsStatsAsync, 'neoJobsStat')
  )
  .handleAction(
    transformToArray(getNeospectraProjectsStatsAsync),
    createAsyncReducer(getNeospectraProjectsStatsAsync, 'neoProjectsStat')
  )
  .handleAction(
    transformToArray(getNeospectraUsagesServiesLeftAsync),
    createAsyncReducer(getNeospectraUsagesServiesLeftAsync, 'neoUsagesServies')
  )
  .handleAction(
    transformToArray(getNeospectraUsagesCurrentAsync),
    createAsyncReducer(getNeospectraUsagesCurrentAsync, 'neoUsagesCurrent')
  )
  .handleAction(
    transformToArray(getMapprimeDashboardAsync),
    createAsyncReducer(getMapprimeDashboardAsync, 'mapprimeDashboard')
  )
  .handleAction(
    transformToArray(getBuyScrtnListAsync),
    createAsyncReducer(getBuyScrtnListAsync, 'buyScrtnList')
  )
  .handleAction(
    getBuyUseListAsync.success, // 성공 액션
    (state, action) => ({
      ...state,
      buyUseList: asyncState.success(action.payload),
      loading: false,
      error: null,// 성공 상태로 업데이트
    })
  )
  .handleAction(
    getBuyUseListAsync.failure, // 실패 액션
    (state, action) => ({
      ...state,
      buyUseList: asyncState.error(action.payload), // 에러 상태로 업데이트
      loading: false,
      error: action.payload,
    })
  )
  ;

export default myPageReducer;