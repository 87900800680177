import { createReducer } from 'typesafe-actions';
import { SupportState, SupportAction } from './types';
import { getNoticesAsync , getNoticeAsync , getFaqListAsync , getFaqAsync , postFaqAsync , putFaqAsync , deleteFaqAsync , postNoticesAsync , putNoticesAsync , deleteNoticesAsync  } from './actions';
import { getContactUsListAsync , getContactUsAsync , postContactUsAsync , putContactUsAsync , deleteContactUsAsync  } from './actions';
import { AnyAction } from 'redux';
import { asyncState, createAsyncReducer, transformToArray } from '../lib/reducerUtils';
import support from '.';

const initialState: SupportState = {
  notices: asyncState.initial(),
  notice: asyncState.initial(),
  noticeInsert: asyncState.initial(),
  noticeUpdate: asyncState.initial(),
  noticeDelete: asyncState.initial(),

  faqList   : asyncState.initial(),
  faq       : asyncState.initial(),
  faqInsert : asyncState.initial(),
  faqUpdate : asyncState.initial(),
  faqDelete : asyncState.initial(),

  data: asyncState.initial(),

  contactUsList: asyncState.initial(),
  contactUs: asyncState.initial(),
  contactUsInsert: asyncState.initial(),
  contactUsUpdate: asyncState.initial(),
  contactUsDelete: asyncState.initial(),


};
const supportReducer = createReducer<SupportState, AnyAction>(initialState)
  .handleAction(
    transformToArray(getNoticesAsync),
    createAsyncReducer(getNoticesAsync, 'notices')
  )
  .handleAction(
    transformToArray(getNoticeAsync),
    createAsyncReducer(getNoticeAsync, 'notice')
  )
  .handleAction(
    transformToArray(postNoticesAsync),
    createAsyncReducer(postNoticesAsync, 'noticeInsert')
  )
  .handleAction(
    transformToArray(putNoticesAsync),
    createAsyncReducer(putNoticesAsync, 'noticeUpdate')
  )
  .handleAction(
    transformToArray(deleteNoticesAsync),
    createAsyncReducer(deleteNoticesAsync, 'noticeDelete')
  )
  .handleAction(
    transformToArray(getFaqListAsync),
    createAsyncReducer(getFaqListAsync, 'faqList')
  )
  .handleAction(
    transformToArray(getFaqAsync),
    createAsyncReducer(getFaqAsync, 'faq')
  )
  .handleAction(
    transformToArray(postFaqAsync),
    createAsyncReducer(postFaqAsync, 'faqInsert')
  )
  .handleAction(
    transformToArray(putFaqAsync),
    createAsyncReducer(putFaqAsync, 'faqUpdate')
  )
  .handleAction(
    transformToArray(deleteFaqAsync),
    createAsyncReducer(deleteFaqAsync, 'faqDelete')
  )
  .handleAction(
    transformToArray(getContactUsListAsync),
    createAsyncReducer(getContactUsListAsync, 'data')
  )
  .handleAction(
    transformToArray(getContactUsAsync),
    createAsyncReducer(getContactUsAsync, 'data')
  )
  .handleAction(
    transformToArray(postContactUsAsync),
    createAsyncReducer(postContactUsAsync, 'contactUsInsert')
  )
  .handleAction(
    transformToArray(putContactUsAsync),
    createAsyncReducer(putContactUsAsync, 'contactUsUpdate')
  )
  .handleAction(
    transformToArray(deleteContactUsAsync),
    createAsyncReducer(deleteContactUsAsync, 'contactUsDelete')
  )
  ;

export default supportReducer;