import { createReducer } from 'typesafe-actions';
import { StatState, StatAction } from './types';
import { getOverviewAsync, getPurcasesAsync, getSignupAsync } from './actions';
import { asyncState, createAsyncReducer, transformToArray } from '../lib/reducerUtils';

const initialState: StatState = {
  data: asyncState.initial(),
  signup: asyncState.initial(),
  purchases: asyncState.initial(),
};

const stat = createReducer<StatState, StatAction>(initialState)
.handleAction(
  getOverviewAsync.request,
  (state) =>({
    ...state,
    data: asyncState.load(),
  })
)
.handleAction(
  getOverviewAsync.success,
  (state, action) => ({
    ...state,
    data: asyncState.success(action.payload),  // 목록 성공
  })
)
.handleAction(
  getOverviewAsync.failure,
  (state, action) => ({
    ...state,
    data: asyncState.error(action.payload),  // 목록 실패
  })
).handleAction(
  getSignupAsync.request,
  (state) =>({
    ...state,
    signup: asyncState.load(),
  })
)
.handleAction(
  getSignupAsync.success,
  (state, action) => ({
    ...state,
    signup: asyncState.success(action.payload),  // 목록 성공
  })
)
.handleAction(
  getSignupAsync.failure,
  (state, action) => ({
    ...state,
    signup: asyncState.error(action.payload),  // 목록 실패
  })
).handleAction(
  getPurcasesAsync.request,
  (state) =>({
    ...state,
    purchases: asyncState.load(),
  })
)
.handleAction(
  getPurcasesAsync.success,
  (state, action) => ({
    ...state,
    purchases: asyncState.success(action.payload),  // 목록 성공
  })
)
.handleAction(
  getPurcasesAsync.failure,
  (state, action) => ({
    ...state,
    purchases: asyncState.error(action.payload),  // 목록 실패
  })
);

export default stat;