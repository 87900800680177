import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

export const GET_LOGIN = 'api/GET_LOGIN'
export const GET_LOGIN_SUCCESS = 'api/GET_LOGIN_SUCCESS'
export const GET_LOGIN_ERROR = 'api/GET_LOGIN_ERROR'
export const POST_LOGOUT = 'api/POST_LOGOUT'
export const POST_LOGOUT_SUCCESS = 'api/POST_LOGOUT_SUCCESS'
export const POST_LOGOUT_ERROR = 'api/POST_LOGOUT_ERROR'
export const POST_FIND_USER_ID = 'api/POST_FIND_USER_ID'
export const POST_FIND_USER_ID_SUCCESS = 'api/POST_FIND_USER_ID_SUCCESS'
export const POST_FIND_USER_ID_ERROR = 'api/POST_FIND_USER_ID_ERROR'


export const loginAsync = createAsyncAction(
  GET_LOGIN,
  GET_LOGIN_SUCCESS,
  GET_LOGIN_ERROR
)<{ username: string; password: string }, void, string>();
// <TRequestPayload, TSuccessPayload, TFailurePayload>

export const logoutAsync = createAsyncAction(
  POST_LOGOUT,
  POST_LOGOUT_SUCCESS,
  POST_LOGOUT_ERROR
)<any, void, string>();

export const postFindUserIdAsync = createAsyncAction(
  POST_FIND_USER_ID,
  POST_FIND_USER_ID_SUCCESS,
  POST_FIND_USER_ID_ERROR
)<{name: string, email: string}, string | null, AxiosError>();