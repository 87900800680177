import { FC } from 'react';
import { PotalCommDto } from '../types/PotalCommDto';

interface SearchFormProps {
    pageingDto : PotalCommDto;
    onClick: (value: number) => void; // onChange prop 추가
}

const Pagination:FC<SearchFormProps> =({ pageingDto , onClick }) => {
    
    const handleClick = (pageNo: number) => {
        onClick(pageNo); // 선택된 값 전달
    };

    
   //  console.log('페이징 결과:', JSON.stringify(pageingDto, null, 2));

    const pageingList = [];

    for ( let pageNo =  Number(pageingDto.firstPageNo); pageNo <=  Number(pageingDto.lastPageNo); pageNo++) {
      if (pageingDto.currentPageNo ==  pageNo) {
         pageingList.push (<li key={"pagination_"+pageNo} className="page-item"><span className='page-link' style={{backgroundColor:"#000000" , color:"#ffffff"}}>{pageNo}</span></li>);
      } else {
         pageingList.push (<li key={"pagination_"+pageNo} className="page-item" onClick={() => handleClick(pageNo)} style={{cursor:"pointer"}}><span className='page-link' >{pageNo}</span></li>);
      }
    }

    if (pageingList.length == 0) {
      pageingList.push (<li key={"pagination_1"} className="page-item"><span className='page-link' style={{backgroundColor:"#000000" , color:"#ffffff"}}>1</span></li>);
    }

    return (
      <nav className='d-flex justify-content-center mt-4'>
            <ul className='pagination'>
            {Number(pageingDto.prevPageNo ) > 0 ? ( 
               <li key="pagination_prev" className="page-item" onClick={() => handleClick(Number(pageingDto.prevPageNo))}><span className='page-link' style={{cursor:"pointer"}} >Prev</span></li>
            ):( 
               <span></span>
            )}

           {pageingList}

           {Number(pageingDto.nextPageNo ) > 0 ? ( 
              <li key="pagination_next" className="page-item" onClick={() => handleClick(Number(pageingDto.nextPageNo))}><span className='page-link' style={{cursor:"pointer"}} >Next</span></li>
           ) : (
              <span></span>
           ) }
           </ul>
        </nav>
    )
}

export default Pagination;