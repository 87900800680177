import { createReducer } from 'typesafe-actions';
import { BuyState, BuyAction } from './types';
import { findScrtnListAsync } from './actions';
import { asyncState } from '../lib/reducerUtils';

const initialState: BuyState = {
  data: asyncState.initial(),
};

const buy = createReducer<BuyState, BuyAction>(initialState)
.handleAction(
  findScrtnListAsync.request,
  (state) =>({
    ...state,
    loading: true,
    error: null,
    data: asyncState.load(),
  })
)
.handleAction(
  findScrtnListAsync.success,
  (state, action) => ({
    ...state,
    data: asyncState.success(action.payload),  // 목록 성공
    loading: false,
    error: null,
  })
)
.handleAction(
  findScrtnListAsync.failure,
  (state, action) => ({
    ...state,
    data: asyncState.error(action.payload),  // 목록 실패
    loading: false,
    error: action.payload,
  })
);

export default buy;