import { call, delay, put, takeEvery } from 'redux-saga/effects';
import { loginAsync, logoutAsync, postFindUserIdAsync, POST_FIND_USER_ID } from './actions';
import { getProfile, login, logout, refreshToken } from '../../api/authuser';
import { Token } from './types';
import { AsyncState } from '../lib/reducerUtils';
import createAsyncSaga from '../lib';
import { UserDto } from '../../types/UserDto';


function* loginSaga(action: ReturnType<typeof loginAsync.request>) {
  try {
    const response: AsyncState<Token> | null = yield call(
      login,
      action.payload.username,
      action.payload.password
    );
    if (response?.data) {
      const profile: AsyncState<UserDto> = yield call(getProfile);
      if (profile.data) {
        localStorage.setItem('userName', profile.data?.userName!);
        localStorage.setItem('userId', profile.data?.userId);
      }
      yield put(loginAsync.success());

    } else {
      yield put(loginAsync.failure('Invalid credentials'));
    }
  } catch (error) {
    yield put(loginAsync.failure('An error occurred during login'));
  }
}

function* logoutSaga(action: ReturnType<typeof logoutAsync.request>) {
  try {
    yield call(logout, action.payload.accessToken);
    yield put(logoutAsync.success());
  } catch (error) {
    if (error instanceof Error) {
      yield put(logoutAsync.failure(error.message));
    } else {
      yield put(logoutAsync.failure('로그아웃 중 오류 발생'));
    }
  }
}

export function* authSaga() {
  yield takeEvery(loginAsync.request, loginSaga);
  yield takeEvery(logoutAsync.request, logoutSaga);
}