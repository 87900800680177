// src/config.ts
const config = {
  portalApiUrl: process.env.REACT_APP_PORTAL_API_URL!,
  portalApiKey: process.env.REACT_APP_PORTAL_API_KEY!,
  mapprimeApiUrl: process.env.REACT_APP_MAPPRIME_API_URL!,
  mapprimeApiVer: process.env.REACT_APP_MAPPRIME_API_VER!,
  neospectraApiUrl: process.env.REACT_APP_NEOSPECTRA_API_URL!,
  neospectraApiVer: process.env.REACT_APP_NEOSPECTRA_API_VER!,
  neospectraApiKey: process.env.REACT_APP_NEOSPECTRA_API_KEY!,
  infoseedApiUrl: process.env.REACT_APP_INFOSEED_API_URL!,
  infoseedApiVer: process.env.REACT_APP_INFOSEED_API_VER!,
  infoseedApiKey: process.env.REACT_APP_INFOSEED_API_KEY!,
};

export default config;
