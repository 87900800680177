import { FC } from 'react';
import { Link } from 'react-router-dom';

const Paging: FC = () => {
    return(
        <nav className='d-flex justify-content-center mt-4'>
            <ul className='pagination'>
                <li className="page-item"><Link to={'/'} className='page-link'>Prev</Link></li>
                <li className="page-item"><Link to={'/'} className='page-link'>1</Link></li>
                <li className="page-item"><Link to={'/'} className='page-link'>2</Link></li>
                <li className="page-item"><Link to={'/'} className='page-link'>3</Link></li>
                <li className="page-item"><Link to={'/'} className='page-link'>Next</Link></li>
            </ul>
        </nav>
    )
}

export default Paging;