import { createReducer } from 'typesafe-actions';
import { OgnzState, OgnzAction } from './types';
import { createOgnzAsync, deleteOgnzAsync, findOgnzAsync, findOgnzListAsync, updateOgnzAsync } from './actions';
import { asyncState, createAsyncReducer, transformToArray } from '../lib/reducerUtils';

const initialState: OgnzState = {
  ognzList: asyncState.initial(),
  selectedOgnz: asyncState.initial(),
  data: asyncState.initial(),
  createOgnz: asyncState.initial(),
};

const ognz = createReducer<OgnzState, OgnzAction>(initialState)
.handleAction(
  findOgnzListAsync.request,
  (state) =>({
    ...state,
    loading: true,
    error: null,
    ognzList: asyncState.load(),
  })
)
.handleAction(
  findOgnzListAsync.success,
  (state, action) => ({
    ...state,
    ognzList: asyncState.success(action.payload),  // 목록 성공
    loading: false,
    error: null,
  })
)
.handleAction(
  findOgnzListAsync.failure,
  (state, action) => ({
    ...state,
    ognzList: asyncState.error(action.payload),  // 목록 실패
    loading: false,
    error: action.payload,
  })
)
.handleAction(
  findOgnzAsync.request,
  (state) =>({
    ...state,
    loading: true,
    error: null,
    selectedUser: asyncState.load(),  // 선택된 유저 로딩 중
  })
)
.handleAction(
  findOgnzAsync.success,
  (state, action) => ({
    ...state,
    selectedOgnz: asyncState.success(action.payload),  // 선택된 유저 성공
    loading: false,
    error: null,
  })
)
.handleAction(
  findOgnzAsync.failure,
  (state, action) => ({
    ...state,
    selectedUser: asyncState.error(action.payload),  // 선택된 유저 실패
    loading: false,
    error: action.payload,
  })
).handleAction(
  updateOgnzAsync.request,
  (state) =>({
    ...state,
    loading: true,
    error: null,
  })
)
.handleAction(
  updateOgnzAsync.success,
  (state, action) => ({
    ...state,
    data: asyncState.success(action.payload),
    loading: false,
    error: null,
  })
)
.handleAction(
  updateOgnzAsync.failure,
  (state, action) => ({
    ...state,
    data: asyncState.error(action.payload),
    loading: false,
    error: action.payload,
  })
).handleAction(
  deleteOgnzAsync.request,
  (state) =>({
    ...state,
    loading: true,
    error: null,
  })
)
.handleAction(
  deleteOgnzAsync.success,
  (state, action) => ({
    ...state,
    data: asyncState.success(action.payload),
    loading: false,
    error: null,
  })
)
.handleAction(
  deleteOgnzAsync.failure,
  (state, action) => ({
    ...state,
    data: asyncState.error(action.payload),
    loading: false,
    error: action.payload,
  })
).handleAction(
  createOgnzAsync.request,
  (state) =>({
    ...state,
    createOgnz: asyncState.load(),
  })
)
.handleAction(
  createOgnzAsync.success,
  (state, action) => ({
    ...state,
    createOgnz: asyncState.success(action.payload),
  })
)
.handleAction(
  createOgnzAsync.failure,
  (state, action) => ({
    ...state,
    createOgnz: asyncState.error(action.payload),
  })
);

export default ognz;