import { createAsyncAction } from 'typesafe-actions';
import { AxiosError } from 'axios';
import { SearchSupportDto } from '../../types/support/SearchSupportDto';
import { CreateNoticetDto } from '../../types/support/CreateNoticetDto';
import { UpdateNoticetDto } from '../../types/support/UpdateNoticetDto';

import { CreateFaqDto } from '../../types/support/CreateFaqDto';
import { UpdateFaqDto } from '../../types/support/UpdateFaqDto';

import { CreateContactUsDto } from '../../types/support/CreateContactUsDto';
import { UpdateContactUsDto } from '../../types/support/UpdateContactUsDto';

export const GET_NOTICES              = 'api/GET_NOTICES';
export const GET_NOTICES_SUCCESS      = 'api/GET_NOTICES_SUCCESS';
export const GET_NOTICES_ERROR        = 'api/GET_NOTICES_ERROR';
export const GET_NOTICE_BY_ID         = 'api/GET_NOTICE';
export const GET_NOTICE_BY_ID_SUCCESS = 'api/SET_NOTICES_SUCCESS';
export const GET_NOTICE_BY_ID_ERROR   = 'api/SET_NOTICES_ERROR';
export const POST_NOTICES             = 'api/POST_NOTICES';
export const POST_NOTICES_SUCCESS     = 'api/POST_NOTICES_SUCCESS';
export const POST_NOTICES_ERROR       = 'api/POST_NOTICES_ERROR';
export const PUT_NOTICES              = 'api/PUT_NOTICES';
export const PUT_NOTICES_SUCCESS      = 'api/PUT_NOTICES_SUCCESS';
export const PUT_NOTICES_ERROR        = 'api/PUT_NOTICES_ERROR';
export const DELETE_NOTICES           = 'api/DELETE_NOTICES';
export const DELETE_NOTICES_SUCCESS   = 'api/DELETE_NOTICES_SUCCESS';
export const DELETE_NOTICES_ERROR     = 'api/DELETE_NOTICES_ERROR';

export const GET_FAQ               = 'api/GET_FAQ';
export const GET_FAQ_SUCCESS       = 'api/GET_FAQ_SUCCESS';
export const GET_FAQ_ERROR         = 'api/GET_FAQ_ERROR';
export const GET_FAQ_BY_ID         = 'api/GET_FAQ_BY_ID';
export const GET_FAQ_BY_ID_SUCCESS = 'api/GET_FAQ_BY_ID_SUCCESS';
export const GET_FAQ_BY_ID_ERROR   = 'api/GET_FAQ_BY_ID_ERROR';

export const POST_FAQ         = 'api/POST_FAQ';
export const POST_FAQ_SUCCESS = 'api/POST_FAQ_SUCCESS';
export const POST_FAQ_ERROR   = 'api/POST_FAQ_ERROR';

export const PUT_FAQ         = 'api/PUT_FAQ';
export const PUT_FAQ_SUCCESS = 'api/PUT_FAQ_SUCCESS';
export const PUT_FAQ_ERROR   = 'api/PUT_FAQ_ERROR';

export const DELETE_FAQ         = 'api/DELETE_FAQ';
export const DELETE_FAQ_SUCCESS = 'api/DELETE_FAQ_SUCCESS';
export const DELETE_FAQ_ERROR   = 'api/DELETE_FAQ_ERROR';

export const GET_CONTACT_US = 'api/GET_CONTACT_US';
export const GET_CONTACT_US_SUCCESS = 'api/GET_CONTACT_US_SUCCESS';
export const GET_CONTACT_US_ERROR = 'api/GET_CONTACT_US_ERROR';
export const GET_CONTACT_US_BY_ID = 'api/GET_CONTACT_US_BY_ID';
export const GET_CONTACT_US_BY_ID_SUCCESS = 'api/GET_CONTACT_US_BY_ID_SUCCESS';
export const GET_CONTACT_US_BY_ID_ERROR = 'api/GET_CONTACT_US_BY_ID_ERROR';

export const POST_CONTACT_US = 'api/POST_CONTACT_US';
export const POST_CONTACT_US_SUCCESS = 'api/POST_CONTACT_US_SUCCESS';
export const POST_CONTACT_US_ERROR = 'api/POST_CONTACT_US_ERROR';

export const PUT_CONTACT_US = 'api/PUT_CONTACT_US';
export const PUT_CONTACT_US_SUCCESS = 'api/PUT_CONTACT_US_SUCCESS';
export const PUT_CONTACT_US_ERROR = 'api/PUT_CONTACT_US_ERROR';

export const DELETE_CONTACT_US = 'api/DELETE_CONTACT_US';
export const DELETE_CONTACT_US_SUCCESS = 'api/DELETE_CONTACT_US_SUCCESS';
export const DELETE_CONTACT_US_ERROR = 'api/DELETE_CONTACT_US_ERROR';

export const getNoticesAsync = createAsyncAction(
  GET_NOTICES,
  GET_NOTICES_SUCCESS,
  GET_NOTICES_ERROR
)<SearchSupportDto, any | null , AxiosError>();

export const getNoticeAsync = createAsyncAction(
  GET_NOTICE_BY_ID,
  GET_NOTICE_BY_ID_SUCCESS,
  GET_NOTICE_BY_ID_ERROR
)<SearchSupportDto, any | null , AxiosError>();

export const postNoticesAsync = createAsyncAction(
  POST_NOTICES,
  POST_NOTICES_SUCCESS,
  POST_NOTICES_ERROR
)<CreateNoticetDto, any | null , AxiosError>();

export const putNoticesAsync = createAsyncAction(
  PUT_NOTICES,
  PUT_NOTICES_SUCCESS,
  PUT_NOTICES_ERROR
)<UpdateNoticetDto, any | null , AxiosError>();

export const deleteNoticesAsync = createAsyncAction(
  DELETE_NOTICES,
  DELETE_NOTICES_SUCCESS,
  DELETE_NOTICES_ERROR
)<CreateNoticetDto, any | null , AxiosError>();

export const getFaqListAsync = createAsyncAction(
  GET_FAQ,
  GET_FAQ_SUCCESS,
  GET_FAQ_ERROR
)<SearchSupportDto, any | null , AxiosError>();

export const getFaqAsync = createAsyncAction(
  GET_FAQ_BY_ID,
  GET_FAQ_BY_ID_SUCCESS,
  GET_FAQ_BY_ID_ERROR
)<number, any | null , AxiosError>();

export const postFaqAsync = createAsyncAction(
  POST_FAQ,
  POST_FAQ_SUCCESS,
  POST_FAQ_ERROR
)<CreateFaqDto, any  , AxiosError>();

export const putFaqAsync = createAsyncAction(
  PUT_FAQ,
  PUT_FAQ_SUCCESS,
  PUT_FAQ_ERROR
)<UpdateFaqDto, any  , AxiosError>();

export const deleteFaqAsync = createAsyncAction(
  DELETE_FAQ,
  DELETE_FAQ_SUCCESS,
  DELETE_FAQ_ERROR
)<number, any  , AxiosError>();

export const getContactUsListAsync = createAsyncAction(
  GET_CONTACT_US,
  GET_CONTACT_US_SUCCESS,
  GET_CONTACT_US_ERROR
)<SearchSupportDto, any  , AxiosError>();

export const getContactUsAsync = createAsyncAction(
  GET_CONTACT_US_BY_ID,
  GET_CONTACT_US_BY_ID_SUCCESS,
  GET_CONTACT_US_BY_ID_ERROR
)<number, any  , AxiosError>();

export const postContactUsAsync = createAsyncAction(
  POST_CONTACT_US,
  POST_CONTACT_US_SUCCESS,
  POST_CONTACT_US_ERROR
)<CreateContactUsDto, any  , AxiosError>();

export const putContactUsAsync = createAsyncAction(
  PUT_CONTACT_US,
  PUT_CONTACT_US_SUCCESS,
  PUT_CONTACT_US_ERROR
)<UpdateContactUsDto, any  , AxiosError>();

export const deleteContactUsAsync = createAsyncAction(
  DELETE_CONTACT_US,
  DELETE_CONTACT_US_SUCCESS,
  DELETE_CONTACT_US_ERROR
)<number, any  , AxiosError>();