import { createAsyncAction } from 'typesafe-actions';
import { AxiosError } from 'axios';
import { ErrorResponse, SuccessResponse } from '../../types/Response';
import { PotalCommDto } from '../../types/PotalCommDto';
import { OgnzDto, SelectOgnzDto } from '../../types/OgnzDto';

export const FIND_OGNZ_LIST = 'api/FIND_OGNZ_LIST';
export const FIND_OGNZ_LIST_SUCCESS = 'api/FIND_OGNZ_LIST_SUCCESS';
export const FIND_OGNZ_LIST_ERROR = 'api/FIND_OGNZ_LIST_ERROR';
export const FIND_OGNZ = 'api/FIND_OGNZ';
export const FIND_OGNZ_SUCCESS = 'api/FIND_OGNZ_SUCCESS';
export const FIND_OGNZ_ERROR = 'api/FIND_OGNZ_ERROR';
export const UPDATE_OGNZ = 'api/UPDATE_OGNZ';
export const UPDATE_OGNZ_SUCCESS = 'api/UPDATE_OGNZ_SUCCESS';
export const UPDATE_OGNZ_ERROR = 'api/UPDATE_OGNZ_ERROR';
export const DELETE_OGNZ = 'api/DELETE_OGNZ';
export const DELETE_OGNZ_SUCCESS = 'api/DELETE_OGNZ_SUCCESS';
export const DELETE_OGNZ_ERROR = 'api/DELETE_OGNZ_ERROR';
export const CREATE_OGNZ = 'api/CREATE_OGNZ';
export const CREATE_OGNZ_SUCCESS = 'api/CREATE_OGNZ_SUCCESS';
export const CREATE_OGNZ_ERROR = 'api/CREATE_OGNZ_ERROR';

export const findOgnzListAsync = createAsyncAction(
  FIND_OGNZ_LIST,
  FIND_OGNZ_LIST_SUCCESS,
  FIND_OGNZ_LIST_ERROR
)<SelectOgnzDto, SuccessResponse<OgnzDto[], PotalCommDto> | ErrorResponse, AxiosError>();

export const findOgnzAsync = createAsyncAction(
  FIND_OGNZ,
  FIND_OGNZ_SUCCESS,
  FIND_OGNZ_ERROR
)<string, SuccessResponse<OgnzDto> | ErrorResponse, AxiosError>();

export const updateOgnzAsync = createAsyncAction(
  UPDATE_OGNZ,
  UPDATE_OGNZ_SUCCESS,
  UPDATE_OGNZ_ERROR
)<{ognzId: string, ognzDto: OgnzDto}, SuccessResponse<null> | ErrorResponse, AxiosError>();

export const deleteOgnzAsync = createAsyncAction(
  DELETE_OGNZ,
  DELETE_OGNZ_SUCCESS,
  DELETE_OGNZ_ERROR
)<string[], SuccessResponse<null> | ErrorResponse, AxiosError>();

export const createOgnzAsync = createAsyncAction(
  CREATE_OGNZ,
  CREATE_OGNZ_SUCCESS,
  CREATE_OGNZ_ERROR
)<OgnzDto, SuccessResponse<OgnzDto> | ErrorResponse, AxiosError>();