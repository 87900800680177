import { createReducer } from 'typesafe-actions';
import { PrdctState, PrdctAction } from './types';
import { createPrdctAsync, findPrdctItemListAsync, findPrdctListAsync } from './actions';
import { asyncState } from '../lib/reducerUtils';

const initialState: PrdctState = {
  prdctList: asyncState.initial(),
  prdctItemList: asyncState.initial(),
  data: asyncState.initial(),
  buyUserList: asyncState.initial(),
  dashboardInfo: asyncState.initial(),
};

const prdct = createReducer<PrdctState, PrdctAction>(initialState)
.handleAction(
  findPrdctListAsync.request,
  (state) =>({
    ...state,
    prdctList: asyncState.load(),
  })
)
.handleAction(
  findPrdctListAsync.success,
  (state, action) => ({
    ...state,
    prdctList: asyncState.success(action.payload),  // 목록 성공
  })
)
.handleAction(
  findPrdctListAsync.failure,
  (state, action) => ({
    ...state,
    prdctList: asyncState.error(action.payload),  // 목록 실패
  })
).handleAction(
  findPrdctItemListAsync.request,
  (state) =>({
    ...state,
    prdctItemList: asyncState.load(),
  })
)
.handleAction(
  findPrdctItemListAsync.success,
  (state, action) => ({
    ...state,
    prdctItemList: asyncState.success(action.payload),  // 목록 성공
  })
)
.handleAction(
  findPrdctItemListAsync.failure,
  (state, action) => ({
    ...state,
    prdctItemList: asyncState.error(action.payload),  // 목록 실패
  })
).handleAction(
  createPrdctAsync.request,
  (state) =>({
    ...state,
    data: asyncState.load(),
  })
)
.handleAction(
  createPrdctAsync.success,
  (state, action) => ({
    ...state,
    data: asyncState.success(action.payload),  // 목록 성공
  })
)
.handleAction(
  createPrdctAsync.failure,
  (state, action) => ({
    ...state,
    data: asyncState.error(action.payload),  // 목록 실패
  })
)
;

export default prdct;