import { FC, useState } from "react";
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import SideLayout from '../../containers/sidemenu';
import Header from '../../containers/header';
import Paging from '../../components/Paging';
import ContentLayout from '../../containers/contentLayout';

const List :FC = () => {
    const [ismodalOpen, setIsmodalOpen] = useState(false);
    const handleModal = () => {
        setIsmodalOpen(!ismodalOpen);
    }
    const [isdropOpen, setIsdropOpen] = useState(false);
    const handleDrop = () => {
        setIsdropOpen(!isdropOpen);
    }

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div className="wrapper">
            <SideLayout />
            {/* leftside-menu */}
            <div className='content-page'>
                <Header />
                <ContentLayout title='회원 조회'>
                <div className='row'>
                            <form action="">
                                <div className='dash-wrap-cont dash-info-wrap'>
                                    <div className="row mb-3">
                                        <div className="col-1 col-form-label">
                                            서비스 대상
                                        </div>
                                        <div className="col-11">
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault01" />
                                                <label className="form-check-label" htmlFor="flexCheckDefault01">
                                                    서비스 대상
                                                </label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault02" />
                                                <label className="form-check-label" htmlFor="flexCheckDefault02">
                                                    영상촬영서비스
                                                </label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault03" />
                                                <label className="form-check-label" htmlFor="flexCheckDefault03">
                                                    AI 데이터 생성관리 서비스
                                                </label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault04" />
                                                <label className="form-check-label" htmlFor="flexCheckDefault04">
                                                    디지털트윈 지도 서비스
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-1 col-form-label">
                                            권한
                                        </div>
                                        <div className="col-3">
                                            <select className="form-select form-select-sm" aria-label="Default select example">
                                                <option selected>전체</option>
                                                <option value="1">관리</option>
                                                <option value="2">일반</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-1 col-form-label">
                                            검색어
                                        </div>  
                                        <div className="col-3">
                                            <select className="form-select form-select-sm" aria-label="Default select example">
                                                <option selected>Open this select menu</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                        <div className="col-8">
                                            <input className="form-control form-control-sm" type="text" placeholder="제목, 내용, 이름, 이메일로검색" aria-label=".form-control-sm example" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-1 col-form-label">
                                            조회기간
                                        </div>  
                                        <div className="col-2">
                                            <input className="form-control form-control-sm" type="date" placeholder="" aria-label="" />
                                        </div>
                                        <div className="col-2">
                                            <input className="form-control form-control-sm" type="date" placeholder="" aria-label="" />
                                        </div>
                                        
                                        <div className="col-7">
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="dataAll" />
                                                <label className="form-check-label" htmlFor="dataAll">
                                                    전체
                                                </label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="Year" />
                                                <label className="form-check-label" htmlFor="Year">
                                                    1년
                                                </label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="Month6" />
                                                <label className="form-check-label" htmlFor="Month6">
                                                    6개월
                                                </label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="Month1" />
                                                <label className="form-check-label" htmlFor="Month1">
                                                    1개월
                                                </label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="Week" />
                                                <label className="form-check-label" htmlFor="Week">
                                                    1주일
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center gap-2 mt-3">
                                        <button type="button" className="btn btn-secondary btn-sm">초기화</button>
                                        <button type="button" className="btn btn-primary btn-sm">검색</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="row">

                            <div className="display-count mb-1 d-flex justify-content-between">
                                <span>총 게시물 : 9,999</span>
                                <button type="button" className="btn btn-secondary btn-sm">선택 회원 삭제</button>
                            </div>
                            <table className="table table-bordered table-hover">
                                <thead>
                                    <th className='text-center'>
                                            <input className="form-check-input" type="checkbox" value="" id="checkAll" />
                                    </th>
                                    <th className='text-center'>번호</th>
                                    <th className='text-center'>등록일</th>
                                    <th className='text-center'>이름</th>
                                    <th className='text-center'>구매제품</th>
                                    <th className='text-center'>소속 기관</th>
                                    <th className='text-center'>아이디(이메일)</th>
                                    <th className='text-center'>등급</th>
                                    <th className='text-center'>권한</th>
                                    <th className='text-center'>휴대전화</th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='text-center'>
                                            <input className="form-check-input" type="checkbox" value="" id="check01" />
                                        </td>
                                        <td>내용</td>
                                        <td><button className='link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover' onClick={handleShow}>내용</button></td>
                                        <td>내용</td>
                                        <td>내용</td>
                                        <td>내용</td>
                                        <td>내용</td>
                                        <td>내용</td>
                                        <td>내용</td>
                                        <td>내용</td>
                                    </tr>
                                </tbody>
                            </table>
                            <Paging />
                        </div>
                </ContentLayout> 
                <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>홍길동님 회원 정보</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h3 className='dash-ti mb-2'>필수 정보</h3>
                    <Table bordered size="sm">
                        <colgroup>
                            <col style={{width: '120px' }} />
                            <col style={{width: 'auto' }} />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>이름</th>
                                <td>홍길동</td>
                            </tr>
                            <tr>
                                <th>회원유형</th>
                                <td>홍길동</td>
                            </tr>
                            <tr>
                                <th>아이디</th>
                                <td>hong</td>
                            </tr>
                            <tr>
                                <th>이메일</th>
                                <td>이메일</td>
                            </tr>
                            <tr>
                                <th>휴대전화</th>
                                <td>010-2222-2222</td>
                            </tr>
                        </tbody>
                    </Table>

                    <h3 className='dash-ti mb-2'>구독 정보</h3>
                    <Table bordered size="sm">
                        <colgroup>
                            <col style={{width: '120px' }} />
                            <col style={{width: 'auto' }} />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>기관</th>
                                <td colSpan={2}>서울시</td>
                            </tr>
                            <tr>
                                <th>권한</th>
                                <td colSpan={2}>
                                <Form.Select aria-label="Default select example">
                                    <option>전체</option>
                                    <option value="1">관리</option>
                                    <option value="2">일반</option>
                                </Form.Select>
                                </td>
                            </tr>
                            <tr>
                                <th rowSpan={3}>구매제품</th>
                                <td>영상촬영 서비스</td>
                                <td>2024.12.01~2024.12.01</td>
                            </tr>
                            <tr>
                                <td>AI 데이터 생성관리 서비스</td>
                                <td>2024.12.01~2024.12.01</td>
                            </tr>
                            <tr>
                                <td>디지털트윈 지도 서비스</td>
                                <td>2024.12.01~2024.12.01</td>
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    닫기
                </Button>
                <Button variant="primary" onClick={handleClose}>
                    변경
                </Button>
                </Modal.Footer>
            </Modal>
            </div>
        </div>
    )
    
}
export default List;
