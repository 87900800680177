import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

export const GET_MYPAGE_NEO_PROJECT_STATS         = 'api/GET_MYPAGE_NEO_PROJECT_STATS'
export const GET_MYPAGE_NEO_PROJECT_STATS_SUCCESS = 'api/GET_MYPAGE_NEO_PROJECT_STATS_SUCCESS'
export const GET_MYPAGE_NEO_PROJECT_STATS_ERROR   = 'api/GET_MYPAGE_NEO_PROJECT_STATS_ERROR'

export const GET_MYPAGE_NEO_JOBS_STATS         = 'api/GET_MYPAGE_NEO_JOBS_STATS'
export const GET_MYPAGE_NEO_JOBS_STATS_SUCCESS = 'api/GET_MYPAGE_NEO_JOBS_STATS_SUCCESS'
export const GET_MYPAGE_NEO_JOBS_STATS_ERROR   = 'api/GET_MYPAGE_NEO_JOBS_STATS_ERROR'

export const GET_MYPAGE_NEO_USAGES_SERVIES_LEFT         = 'api/GET_MYPAGE_NEO_USAGES_SERVIES_LEFT'
export const GET_MYPAGE_NEO_USAGES_SERVIES_LEFT_SUCCESS = 'api/GET_MYPAGE_NEO_USAGES_SERVIES_LEFT_SUCCESS'
export const GET_MYPAGE_NEO_USAGES_SERVIES_LEFT_ERROR   = 'api/GET_MYPAGE_NEO_USAGES_SERVIES_LEFT_ERROR'

export const GET_MAPPRIME_DASHBOARD         = 'api/GET_MAPPRIME_DASHBOARD'
export const GET_MAPPRIME_DASHBOARD_SUCCESS = 'api/GET_MAPPRIME_DASHBOARD_SUCCESS'
export const GET_MAPPRIME_DASHBOARD_ERROR   = 'api/GET_MAPPRIME_DASHBOARD_ERROR'

export const GET_INFOSEED_DASHBOARD         = 'api/GET_INFOSEED_DASHBOARD'
export const GET_INFOSEED_DASHBOARD_SUCCESS = 'api/GET_INFOSEED_DASHBOARD_SUCCESS'
export const GET_INFOSEED_DASHBOARD_ERROR   = 'api/GET_INFOSEED_DASHBOARD_ERROR'

export const GET_BUY_SCRTN_LIST         = 'api/GET_BUY_SCRTN_LIST'
export const GET_BUY_SCRTN_LIST_SUCCESS = 'api/GET_BUY_SCRTN_LIST_SUCCESS'
export const GET_BUY_SCRTN_LIST_ERROR   = 'api/GET_BUY_SCRTN_LIST_ERROR'

export const GET_BUY_USE_LIST         = 'api/GET_BUY_USE_LIST'
export const GET_BUY_USE_LIST_SUCCESS = 'api/GET_BUY_USE_LIST_SUCCESS'
export const GET_BUY_USE_LIST_ERROR   = 'api/GET_BUY_USE_LIST_ERROR'

export const GET_MYPAGE_NEO_USAGES_CURRENT        = 'api/GET_MYPAGE_NEO_USAGES_CURRENT'
export const GET_MYPAGE_NEO_USAGES_CURRENT_SUCCESS = 'api/GET_MYPAGE_NEO_USAGES_CURRENT_SUCCESS'
export const GET_MYPAGE_NEO_USAGES_CURRENT_ERROR   = 'api/GET_MYPAGE_NEO_USAGES_CURRENT_ERROR'

export const getNeospectraProjectsStatsAsync = createAsyncAction(
  GET_MYPAGE_NEO_PROJECT_STATS,
  GET_MYPAGE_NEO_PROJECT_STATS_SUCCESS,
  GET_MYPAGE_NEO_PROJECT_STATS_ERROR
)<any, any, AxiosError>();

export const getNeospectraJobsStatsAsync = createAsyncAction(
  GET_MYPAGE_NEO_JOBS_STATS,
  GET_MYPAGE_NEO_JOBS_STATS_SUCCESS,
  GET_MYPAGE_NEO_JOBS_STATS_ERROR
)<any, any, AxiosError>();

export const getNeospectraUsagesServiesLeftAsync = createAsyncAction(
  GET_MYPAGE_NEO_USAGES_SERVIES_LEFT,
  GET_MYPAGE_NEO_USAGES_SERVIES_LEFT_SUCCESS,
  GET_MYPAGE_NEO_USAGES_SERVIES_LEFT_ERROR
)<number, any[] | null, AxiosError>();

export const getMapprimeDashboardAsync = createAsyncAction(
  GET_MAPPRIME_DASHBOARD,
  GET_MAPPRIME_DASHBOARD_SUCCESS,
  GET_MAPPRIME_DASHBOARD_ERROR
)<any, any[] | null, AxiosError>();

export const getInfoseedDashboardAsync = createAsyncAction(
  GET_INFOSEED_DASHBOARD,
  GET_INFOSEED_DASHBOARD_SUCCESS,
  GET_INFOSEED_DASHBOARD_ERROR
)<{ognzId:string, ognzUserGrpSn:number}, any[] | null, AxiosError>();

export const getBuyScrtnListAsync = createAsyncAction(
  GET_BUY_SCRTN_LIST,
  GET_BUY_SCRTN_LIST_SUCCESS,
  GET_BUY_SCRTN_LIST_ERROR
)<{ sltnId: string, currentPageNo: number, pageUnit: number, ognzUserGrpSn: number }, any[] | null, AxiosError>();

export const getBuyUseListAsync = createAsyncAction(
  GET_BUY_USE_LIST,
  GET_BUY_USE_LIST_SUCCESS,
  GET_BUY_USE_LIST_ERROR
)<number, any, AxiosError>();

export const getNeospectraUsagesCurrentAsync = createAsyncAction(
  GET_MYPAGE_NEO_USAGES_CURRENT,
  GET_MYPAGE_NEO_USAGES_CURRENT_SUCCESS,
  GET_MYPAGE_NEO_USAGES_CURRENT_ERROR
)<number, any, AxiosError>();