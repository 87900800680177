import axios from 'axios';
import { CreateUserDto, UserDto } from '../types/UserDto';
import config from '../config';
import { AsyncState } from '../modules/lib/reducerUtils';
import { InfoseedToken, MapprimeToken, Token } from '../modules/authuser';
import accessTokenPortalApi from './api';

export async function login(userId: string, password: string): Promise<AsyncState<Token> | null> {
  // console.log('Login function called');
  try {
    const response = await axios.post<AsyncState<Token>>(
      `${config.portalApiUrl}/auth/admin/login`,
      {
        userId,
        password
      } // POST 요청의 본문에 데이터를 포함
    );

    // response.data.token이 존재하면 로그인 성공으로 처리
    if (response.data.data) {
      // console.log('토큰 받아오기 성공');
      localStorage.setItem('access_token', response.data.data.access_token);
      localStorage.setItem('refresh_token', response.data.data.refresh_token);
      
      let serverTime = response.data.data.server_time;
      let currentTime = new Date();
      let diffTime = new Date(serverTime).getTime() - currentTime.getTime();
      
      let accessTokenExpiry = new Date(new Date(response.data.data.access_token_expires_in).getTime() - diffTime);
      localStorage.setItem('access_token_expires_in', accessTokenExpiry.toString());
      let refreshTokenExpiry = new Date(new Date(response.data.data.refresh_token_expires_in).getTime() - diffTime);
      localStorage.setItem('refresh_token_expires_in', refreshTokenExpiry.toString());

      // console.log(`access_token_expires_in: ${response.data.data.access_token_expires_in}`);
      // console.log(`refresh_token_expires_in: ${response.data.data.refresh_token_expires_in}`);

      // console.log(`access_token_expires_in: ${accessTokenExpiry}`);
      // console.log(`refresh_token_expires_in: ${refreshTokenExpiry}`);
      getProfile();
      getMapprimeToken();
      return response.data;
    } else {
      // console.log('로그인 실패');
      return null;
    }
  } catch (error) {
    // 오류 메시지 출력 및 null 반환
    if (axios.isAxiosError(error)) {
      console.error('Axios error:', error.response?.data || error.message);
    } else {
      console.error('Unknown error:', error);
    }
    return null;
  }
}

export async function logout(access_token: string | null): Promise<null> {
  const keysToRemove = [
    "access_token",
    "access_token_expires_in",
    "refresh_token",
    "refresh_token_expires_in",
    "userName",
    "organizationId",
    "userId",
    "mapprimeToken",
  ]
  try {
    // console.log("로그아웃 토큰 값:" + access_token);
    const response = await axios.post<string>(
      `${config.portalApiUrl}/auth/admin/logout`,
      {},
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }
      }
    );

    return null;
  } catch (error) {
    // 오류 메시지 출력 및 null 반환
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      console.error('Unauthorized: Access token expired or invalid.');
    } else if (axios.isAxiosError(error)) {
      console.error('Axios error:', error.response?.data || error.message);
    } else {
      console.error('Unknown error:', error);
    }
    return null;
  } finally {
    keysToRemove.forEach((key) => localStorage.removeItem(key));
  }
}

export async function getProfile(): Promise<AsyncState<UserDto> | null> {
  try {
    const response = await accessTokenPortalApi.get<AsyncState<UserDto>>(
      `/auth/admin/profile`,
    );

    // response.data.token이 존재하면 로그인 성공으로 처리
    if (response.data) {
      // console.log('프로필 받아오기 성공');

      return response.data;
    } else {
      // console.log('프로필 받아오기 실패');
      return null;
    }
  } catch (error) {
    // 오류 메시지 출력 및 null 반환
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      console.error('Unauthorized: Access token expired or invalid.');
    } else if (axios.isAxiosError(error)) {
      console.error('Axios error:', error.response?.data || error.message);
    } else {
      console.error('Unknown error:', error);
    }
    return null;
  }
}

// 토큰 리프레시
export async function refreshToken(refreshToken: string): Promise<AsyncState<Token> | null> {
  try {
    const response = await axios.post<AsyncState<Token>>(
      `${config.portalApiUrl}/auth/refresh`,
      { refreshToken }
    );
    if (response.data) {
      // console.log('토큰 갱신 성공');
      // console.log(response.data);
      // localStorage.setItem('access_token', response.data?.data?.token); 
      return response.data;
    } else {
      // console.log('토큰 갱신 실패');
      return null;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('Axios error:', error.response?.data || error.message);
    } else {
      console.error('Unknown error:', error);
    }
    return null;
  }
}

// 토큰 리프레시
export async function getMapprimeToken(): Promise<MapprimeToken | null> {
  try {
    const response = await axios.post<MapprimeToken>(
      `https://kftwihqemy.apigw.gov-ntruss.com/mpapi/srvgw/v1/account/signin`,
      {
        t: localStorage.getItem('access_token')
      }
    );
    if (response.data) {
      // console.log('맵프라임 토큰 갱신 성공');
      localStorage.setItem("mapprimeToken", response.data.token_access);
      return response.data;
    } else {
      // console.log('맵프라임 토큰 갱신 실패');
      return null;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('Axios error:', error.response?.data || error.message);
    } else {
      console.error('Unknown error:', error);
    }
    return null;
  }
}

// 토큰 리프레시
export async function getInfoseedToken(): Promise<InfoseedToken | null> {
  try {
    const response = await axios.post<InfoseedToken>(
      `${config.infoseedApiUrl}/${config.mapprimeApiVer}/common/auth`,
      {

      },
      {
        // 요청 헤더에 추가할 데이터
        headers: {
          PTOKEN: localStorage.getItem('access_token'),
          APIKEY: config.infoseedApiKey
        }
      }
    );
    if (response.data) {
      // console.log('인포시드 토큰 갱신 성공');
      localStorage.setItem("infoseed_token", response.data.IFTOKEN);
      return response.data;
    } else {
      // console.log('인포시드 토큰 갱신 실패');
      return null;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('Axios error:', error.response?.data || error.message);
    } else {
      console.error('Unknown error:', error);
    }
    return null;
  }
}