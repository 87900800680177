import { createAsyncAction } from 'typesafe-actions';
import { AxiosError } from 'axios';
import { CreateUserDto, SelectUserDto, UserDto } from '../../types/UserDto';
import { ErrorResponse, SuccessResponse } from '../../types/Response';
import { PotalCommDto } from '../../types/PotalCommDto';
import { SelectUserCntnLogDto, UserCntnLog } from '../../types/CntnDto';

export const USER_CNTN_LIST = 'api/USER_CNTN_LIST';
export const USER_CNTN_LIST_SUCCESS = 'api/USER_CNTN_LIST_SUCCESS';
export const USER_CNTN_LIST_ERROR = 'api/USER_CNTN_LIST_ERROR';

export const findCntnListAsync = createAsyncAction(
  USER_CNTN_LIST,
  USER_CNTN_LIST_SUCCESS,
  USER_CNTN_LIST_ERROR
)<SelectUserCntnLogDto, SuccessResponse<UserCntnLog[], PotalCommDto> | ErrorResponse, AxiosError>();