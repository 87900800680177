import { FC } from 'react';
import { Link } from 'react-router-dom';

const SideLayout: FC = () => {
    const currentPath = window.location.pathname; // 경로
    
    return (
        <div className="leftside-menu">
                <Link to={'/'} className='logo'><img src="/logo@216x39-white.png" alt="logo" /></Link>
                <div id='leftside-menu-cotanienr'>
                    <nav className="side-nav">
                        <ul>
                            <li className="side-nav-item"><Link to={'/'} className='side-nav-link active'><span>회원 관리</span></Link>
                                <div className="side-nav-third" id='sidebar-01'>
                                    <ul>
                                        <li><Link to={'/userList'} className={currentPath === '/userList' ? 'active' : ''}>회원 조회</Link></li>
                                        <li><Link to={'/ognzMngList'} className={currentPath === '/ognzMngList' ? 'active' : ''}>기관(기업) 그룹 관리</Link></li>
                                    </ul>
                                </div>
                            </li>
                            <li className="side-nav-item"><Link to={'/'} className='side-nav-link active'><span>기관 관리</span></Link>
                                <div className="side-nav-third" id='sidebar-02'>
                                    <ul>
                                        <li><Link to={'/ognzList'} className={currentPath === '/ognzList' ? 'active' : ''}>기관(기업) 관리</Link></li>
                                    </ul>
                                </div>
                            </li>
                            {/* <li className="side-nav-item"><Link to={'/'} className='side-nav-link'><span>권한 관리</span></Link>
                                <div className="side-nav-third" id='sidebar-03'>
                                    <ul>
                                        <li><Link to={'/userAuth'} className={currentPath === '/userAuth' ? 'active' : ''}>사용자 권한 관리</Link></li>
                                    </ul>
                                </div>
                            </li> */}
                            <li className="side-nav-item"><Link to={'/'} className='side-nav-link'><span>결제 관리</span></Link>
                                <div className="side-nav-third" id='sidebar-04'>
                                    <ul>
                                        <li><Link to={'/prdct'} className={currentPath === '/prdct' ? 'active' : ''}>제품 관리</Link></li>
                                        <li><Link to={'/scrtn'} className={currentPath === '/scrtn' ? 'active' : ''}>제품 결제 관리</Link></li>
                                    </ul>
                                </div>
                            </li>
                            <li className="side-nav-item"><Link to={'/'} className='side-nav-link'><span>고객 지원 관리</span></Link>
                                <div className="side-nav-third" id='sidebar-05'>
                                    <ul>
                                        <li><Link to={'/noticeList'} className={currentPath.indexOf('/noticeList')>=0  || currentPath.indexOf('/noticeWrite')>=0 ? 'active' : ''}>공지사항 관리</Link></li>
                                        <li><Link to={'/faqList'} className={currentPath.indexOf('/faqList')>=0 || currentPath.indexOf('/faqWrite')>=0 ? 'active' : ''}>FAQ 관리</Link></li>
                                        <li><Link to={'/contactUsList'} className={currentPath.indexOf('/contactUsList')>=0 || currentPath.indexOf('/contactUsWrite')>=0 ? 'active' : ''}>문의 내역 관리</Link></li>
                                    </ul>
                                </div>
                            </li>
                            <li className="side-nav-item"><Link to={'/'} className='side-nav-link'><span>사용 현황 관리</span></Link>
                                <div className="side-nav-third" id='sidebar-06'>
                                    <ul>
                                        <li><Link to={'/cntnLog'} className={currentPath === '/cntnLog' ? 'active' : ''}>회원 접속 관리</Link></li>
                                        {/* <li><Link to={'/'} className={currentPath === '/prdct' ? 'active' : ''}>서비스 접속 관리</Link></li> */}
                                    </ul>
                                </div>
                            </li>
                            {localStorage.getItem("userId") == "admin" ? (
                                <li className="side-nav-item"><Link to={'/'} className='side-nav-link'><span>사이트 관리</span></Link>
                                    <div className="side-nav-third" id='sidebar-06'>
                                        <ul>
                                            <li><Link to={'/codeGrp'} className={currentPath === '/codeGrp' ? 'active' : ''}>공통 코드 관리</Link></li>
                                        </ul>
                                    </div>
                                </li>
                            ) : null}
                            
                        </ul>
                    </nav>
                </div>
            </div>
    )
}

export default SideLayout;