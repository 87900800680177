import { FC, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../modules';
import { logout } from '../../api/authuser';
import config from '../../config';
import { logoutAsync, POST_LOGOUT } from '../../modules/authuser';

const HeaderLayout: FC = () => {
    const [userName, setUserName] = useState<string | null>(localStorage.getItem("userName"));
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const logoutSuccess = useSelector((state: RootState) => state.auth.data);

    const handleLogout = async () => {
        // 로그아웃 API 호출 (필요에 따라 서버에 로그아웃 요청)
        const accessToken = localStorage.getItem('access_token');
        if (accessToken) {
            dispatch(logoutAsync.request({ accessToken: accessToken, type: POST_LOGOUT }));
        } else {
            console.error('No refresh token found in local storage.');
            // 필요시 추가적인 처리 로직 (예: 사용자 로그아웃 처리 또는 토큰 재발급 시도)
        }
    };

    useEffect(() => {
        // 컴포넌트가 렌더링될 때 localStorage에서 userName 가져오기
        const storedUserName = localStorage.getItem('userName');
        setUserName(storedUserName);
    }, []);

    // 로그아웃 성공 시 로그인 페이지로 이동
    useEffect(() => {
        if (!localStorage.getItem('access_token')) {
            navigate("/login"); // 로그아웃 성공 후 로그인 페이지로 이동
        }
    }, [logoutSuccess, navigate]);

    return (
        <nav className="navbar navbar-custom">
            <div className='d-flex align-items-center'>
                <span className="m-1"><b>{userName}</b>님 반갑습니다.</span>
                <button type='button' className='btn btn-primary m-1' onClick={handleLogout}>로그아웃</button>
            </div>
            {/* <div className="dropdown-center">
                <button className="btn btn-secondary dropdown-toggle m-1" type="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={handleDrop}>
                    서비스 바로가기<i className="bi bi-three-dots-vertical"></i>
                </button>
                {isdropOpen &&
                    <ul className="dropdown-menu show">
                        <li>
                            <a
                                className="dropdown-item"
                                href="/login"
                                target="manager-window"
                                rel="noopener noreferrer"
                            >
                                영상촬영서비스
                            </a>
                        </li>
                        <li>
                            <a
                                onClick={(e) => {
                                    e.preventDefault();
                                    const form = document.createElement('form');
                                    form.method = 'POST';
                                    form.action = `${config.neospectraApiUrl}/redirect/${config.neospectraApiVer}/`; // action URL 설정
                                    form.target = "manager-window";

                                    // access_token
                                    const access_token = document.createElement('input');
                                    access_token.type = 'hidden';
                                    access_token.name = 'access_token';
                                    access_token.value = localStorage.getItem('access_token') || ''; // 적절한 토큰 값을 사용하세요
                                    form.appendChild(access_token);

                                    // refresh_token
                                    const refresh_token = document.createElement('input');
                                    refresh_token.type = 'hidden';
                                    refresh_token.name = 'refresh_token';
                                    refresh_token.value = localStorage.getItem('refresh_token') || ''; // 적절한 토큰 값을 사용하세요
                                    form.appendChild(refresh_token);

                                    // target
                                    const target = document.createElement('input');
                                    target.type = 'hidden';
                                    target.name = 'target';
                                    target.value = 'home'; // 적절한 토큰 값을 사용하세요
                                    form.appendChild(target);

                                    // 폼을 body에 추가하고 제출
                                    document.body.appendChild(form);
                                    form.submit();

                                    // 폼 제거 (더 이상 필요하지 않음)
                                    document.body.removeChild(form);
                                }}
                                className="dropdown-item"
                                rel="noopener noreferrer"
                            >
                                AI 데이터 생성관리 서비스</a></li>
                        <li>
                            <a
                            onClick={(e) => {
                                e.preventDefault();
                                const form = document.createElement('form');
                                form.method = 'POST';
                                form.action = `${config.mapprimeApiUrl}/${config.mapprimeApiVer}/gate-m`; // action URL 설정
                                form.target = "manager-window";

                                // 필요한 hidden input 생성 (예: 토큰)
                                const accessField = document.createElement('input');
                                accessField.type = 'hidden';
                                accessField.name = 't';
                                accessField.value = localStorage.getItem('access_token') || ''; // 적절한 토큰 값을 사용하세요
                                form.appendChild(accessField);

                                // 필요한 hidden input 생성 (예: 토큰)
                                const refreshField = document.createElement('input');
                                refreshField.type = 'hidden';
                                refreshField.name = 'r';
                                refreshField.value = localStorage.getItem('refresh_token') || ''; // 적절한 토큰 값을 사용하세요
                                form.appendChild(refreshField);

                                // 폼을 body에 추가하고 제출
                                document.body.appendChild(form);
                                form.submit();

                                // 폼 제거 (더 이상 필요하지 않음)
                                document.body.removeChild(form);
                            }}
                                className="dropdown-item"
                                href="https://manager.mapprime.co.kr/"
                                target="manager-window"
                                rel="noopener noreferrer"
                            >
                                디지털트윈 지도 서비스
                            </a>
                        </li>
                    </ul>
                }
            </div> */}
        </nav>
    )
}

export default HeaderLayout;