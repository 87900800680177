import { createAsyncAction } from 'typesafe-actions';
import { AxiosError } from 'axios';
import { CreateUserDto, SelectUserDto, UserDto } from '../../types/UserDto';
import { ErrorResponse, SuccessResponse } from '../../types/Response';
import { PotalCommDto } from '../../types/PotalCommDto';
import { SelectUserGroupDto } from '../../types/UserGroupDto';

export const FIND_USER_GROUP_LIST = 'api/FIND_USER_GROUP_LIST';
export const FIND_USER_GROUP_LIST_SUCCESS = 'api/FIND_USER_GROUP_LIST_SUCCESS';
export const FIND_USER_GROUP_LIST_ERROR = 'api/FIND_USER_GROUP_LIST_ERROR';
export const FIND_USER_GROUP_MEMBER_LIST = 'api/FIND_USER_GROUP_MEMBER_LIST';
export const FIND_USER_GROUP_MEMBER_LIST_SUCCESS = 'api/FIND_USER_MEMBER_GROUP_SUCCESS';
export const FIND_USER_GROUP_MEMBER_LIST_ERROR = 'api/FIND_USER_MEMBER_GROUP_ERROR';
export const UPDATE_USER_GROUP_MEMBER = 'api/UPDATE_USER_GROUP_MEMBER_LIST';
export const UPDATE_USER_GROUP_MEMBER_SUCCESS = 'api/UPDATE_USER_GROUP_MEMBER_LIST_SUCCESS';
export const UPDATE_USER_GROUP_MEMBER_ERROR = 'api/UPDATE_USER_GROUP_MEMBER_LIST_ERROR';
export const DELETE_USER_GROUP_MEMBER = 'api/DELETE_USER_GROUP_MEMBER';
export const DELETE_USER_GROUP_MEMBER_SUCCESS = 'api/DELETE_USER_GROUP_MEMBER_SUCCESS';
export const DELETE_USER_GROUP_MEMBER_ERROR = 'api/DELETE_USER_GROUP_MEMBER_ERROR';
export const UPDATE_GROUP_USER_ROLE = 'api/UPDATE_GROUP_USER_ROLE';
export const UPDATE_GROUP_USER_ROLE_SUCCESS = 'api/UPDATE_GROUP_USER_ROLE_SUCCESS';
export const UPDATE_GROUP_USER_ROLE_ERROR = 'api/UPDATE_GROUP_USER_ROLE_ERROR';

export const findUserGroupListAsync = createAsyncAction(
  FIND_USER_GROUP_LIST,
  FIND_USER_GROUP_LIST_SUCCESS,
  FIND_USER_GROUP_LIST_ERROR
)<SelectUserDto, SuccessResponse<SelectUserGroupDto[], PotalCommDto> | ErrorResponse, AxiosError>();

export const findUserGroupMemberListAsync = createAsyncAction(
  FIND_USER_GROUP_MEMBER_LIST,
  FIND_USER_GROUP_MEMBER_LIST_SUCCESS,
  FIND_USER_GROUP_MEMBER_LIST_ERROR
)<{ognzGrpSn: number, selectUserDto: SelectUserDto}, SuccessResponse<SelectUserGroupDto[], PotalCommDto> | ErrorResponse, AxiosError>();

export const deleteUserGroupMemberAsync = createAsyncAction(
  DELETE_USER_GROUP_MEMBER,
  DELETE_USER_GROUP_MEMBER_SUCCESS,
  DELETE_USER_GROUP_MEMBER_ERROR
)<{groupId:string, userIds: string[]}, SuccessResponse<null> | ErrorResponse, AxiosError>();

export const updateUserGroupMemberAsync = createAsyncAction(
  UPDATE_USER_GROUP_MEMBER,
  UPDATE_USER_GROUP_MEMBER_SUCCESS,
  UPDATE_USER_GROUP_MEMBER_ERROR
)<{groupId:string, userIds: string[], role: string}, SuccessResponse<null> | ErrorResponse, AxiosError>();

export const updateGroupUserRoleAsync = createAsyncAction(
  UPDATE_GROUP_USER_ROLE,
  UPDATE_GROUP_USER_ROLE_SUCCESS,
  UPDATE_GROUP_USER_ROLE_ERROR
)<{ ognzGrpSn: number; userId: string[]; sltnId: string; role: string }, SuccessResponse<null> | ErrorResponse, AxiosError>();