import { FC, useEffect, useRef, useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import SideLayout from '../../containers/sidemenu';
import Header from '../../containers/header';
import ContentLayout from '../../containers/contentLayout';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../modules";
import { deleteUserAsync, findUserAsync, findUserListAsync, updateUserAsync } from "../../modules/user";
import { ProfileDto, SelectUserDto, UserDto } from "../../types/UserDto";
import { format } from 'date-fns';
import Pagination from "../../components/Pagination";
import { getCommCdListAsync } from "../../modules/common";
import { findScrtnListAsync as findBuyListAsync } from "../../modules/buy";
import { ResultPaymntDto, SelectPaymntDto } from "../../types/BuyDto";
import { SelectUserGroupDto } from "../../types/UserGroupDto";
import { useNavigate } from "react-router-dom";

const BuyList: FC = () => {
    const navigate = useNavigate();
    const [profileDto, setProfileDto] = useState<ProfileDto>({
        user: {
            userId: "",
            userName: "",
            email: "",
            emaildomain: "",
            password: "",
            rePassword: "",
            roleCd: "",
            organizationId: "",
            ognzName: "",
            useYn: "",
            regUserId: "",
            regDt: "",
            modUserId: "",
        },
        products: []
    });
    const [selectedPeriod, setSelectedPeriod] = useState<string>("all");
    const dispatch = useDispatch();
    const comboInfo = useSelector((state: RootState) => state.common.data);


    const buyList = useSelector((state: RootState) => state.buy.data);
    const selectedUser = useSelector((state: RootState) => state.user.selectedUser);
    const [show, setShow] = useState(false);
    const [searchParams, setSearchParams] = useState<SelectPaymntDto>({
        searchType: 'user_nm', // 검색어 조건
        searchKeyword: '',
        currentPageNo: 1,
        searchOrder: '',
        fromDate: '',   // 시작일자
        toDate: '', // 종료일자
    });

    // 현재 날짜를 가져오기 위한 함수
    const getCurrentDate = () => format(new Date(), 'yyyy-MM-dd');

    // 조회기간 버튼 클릭 핸들러
    // 기간 설정 핸들러
    const handlePeriodChange = (period: string) => {
        let fromDate = '', toDate = getCurrentDate();
        setSelectedPeriod(period);
        switch (period) {
            case 'year':
                fromDate = format(new Date(Date.now() - 365 * 24 * 60 * 60 * 1000), 'yyyy-MM-dd');
                break;
            case 'sixMonths':
                fromDate = format(new Date(Date.now() - 183 * 24 * 60 * 60 * 1000), 'yyyy-MM-dd');
                break;
            case 'oneMonth':
                fromDate = format(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000), 'yyyy-MM-dd');
                break;
            case 'oneWeek':
                fromDate = format(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000), 'yyyy-MM-dd');
                break;
            default:
                fromDate = '';
                toDate = '';
        }

        // 상태 업데이트
        setSearchParams(prev => ({
            ...prev,
            fromDate,
            toDate,
        }));
    };

    // 초기화 버튼 클릭 핸들러
    const handleReset = () => {
        // 검색 조건 초기화
        setSearchParams({
            searchType: '',
            searchKeyword: '',
            currentPageNo: 1,
            searchOrder: '',
            fromDate: '',
            toDate: '',
        });
    };

    // 검색어와 권한 변경 핸들러
    const handleInputChange = (field: keyof SelectPaymntDto, value: string) => {
        setSearchParams(prev => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleClose = () => setShow(false);
    const handleShow = async (userId: string) => {
        dispatch(findUserAsync.request(userId));
        setShow(true)
    };

    const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onSearch(1);
    };

    const onSearch = async (pageNo: number) => {
        setSearchParams({ ...searchParams, currentPageNo: pageNo });
        dispatch(findBuyListAsync.request({
            ...searchParams,
            currentPageNo: pageNo,
        }));
    }

    const handleMoveUserGrpShow = async (user: any) => {
        navigate('/dashboard/', { state: { ognzId: user.ognzId, ognzGrpSn: user.ognzUserGrpSn } });
    };

    useEffect(() => {
        dispatch(getCommCdListAsync.request({ cdGrpId: "PORTAL_TYPE_CD" }));
        dispatch(findBuyListAsync.request(searchParams));
    }, []);


    useEffect(() => {
        if (selectedUser.data) {
            setProfileDto({
                ...selectedUser.data.data,
            });
        }
    }, [selectedUser]);

    return (
        <div className="wrapper">
            <SideLayout />
            {/* leftside-menu */}
            <div className='content-page'>
                <Header />
                <ContentLayout title='결제 관리'>
                    {/* 검색 폼 */}
                    <div className='row'>
                        <form onSubmit={handleSearch}>
                            <div className='dash-wrap-cont dash-info-wrap'>
                                {/* 검색 필드 */}
                                {/* 권한 선택 필드 */}
                                <div className="row mb-3">
                                    <div className="col-1 col-form-label">
                                        제품명
                                    </div>
                                    <div className="col-3">
                                        <select
                                            className="form-select form-select-sm"
                                            aria-label="Default select example"
                                            value={searchParams.stlnNm}
                                            onChange={(e) => handleInputChange('stlnNm', e.target.value)}
                                        >
                                            <option value="">전체</option>
                                            <option value='지오닉'>지오닉</option>
                                            <option value='심메타'>심메타</option>
                                            <option value='맵프라임클라우드'>맵프라임클라우드</option>
                                        </select>
                                    </div>
                                </div>
                                {/* 검색어 입력 필드 */}
                                <div className="row mb-3">
                                    <div className="col-1 col-form-label">
                                        검색어
                                    </div>
                                    <div className="col-3">
                                        <select
                                            className="form-select form-select-sm"
                                            aria-label="Default select example"
                                            value={searchParams.searchType}
                                            onChange={(e) => handleInputChange('searchType', e.target.value)}
                                        >
                                            <option value="user_nm">이름</option>
                                            <option value="ognz_nm">기관명</option>
                                            <option value="stlm_nm">솔루션 이름</option>
                                        </select>
                                    </div>
                                    <div className="col-8">
                                        <input
                                            className="form-control form-control-sm"
                                            type="text"
                                            placeholder="이름, 기관명, 솔루션 이름으로 검색"
                                            aria-label=".form-control-sm example"
                                            value={searchParams.searchKeyword}
                                            onChange={(e) => handleInputChange('searchKeyword', e.target.value)}
                                        />
                                    </div>
                                </div>
                                {/* 조회 기간 필드 */}
                                <div className="row">
                                    <div className="col-1 col-form-label">
                                        조회기간
                                    </div>
                                    <div className="col-2">
                                        <input
                                            className="form-control form-control-sm"
                                            type="date"
                                            value={searchParams.fromDate || ''}
                                            onChange={(e) => handleInputChange('fromDate', e.target.value)}
                                            placeholder=""
                                            aria-label="Start Date"
                                        />
                                    </div>
                                    <div className="col-2">
                                        <input
                                            className="form-control form-control-sm"
                                            type="date"
                                            value={searchParams.toDate || ''}
                                            onChange={(e) => handleInputChange('toDate', e.target.value)}
                                            placeholder=""
                                            aria-label="End Date"
                                        />
                                    </div>
                                    {/* 조회기간 라디오 버튼들 */}
                                    <div className="col-7">
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id="dataAll"
                                                checked={selectedPeriod === 'all'}
                                                onChange={() => handlePeriodChange('all')}
                                            />
                                            <label className="form-check-label" htmlFor="dataAll">
                                                전체
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id="Year"
                                                checked={selectedPeriod === 'year'}
                                                onChange={() => handlePeriodChange('year')}
                                            />
                                            <label className="form-check-label" htmlFor="Year">
                                                1년
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id="Month6"
                                                checked={selectedPeriod === 'sixMonths'}
                                                onChange={() => handlePeriodChange('sixMonths')}
                                            />
                                            <label className="form-check-label" htmlFor="Month6">
                                                6개월
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id="Month1"
                                                checked={selectedPeriod === 'oneMonth'}
                                                onChange={() => handlePeriodChange('oneMonth')}
                                            />
                                            <label className="form-check-label" htmlFor="Month1">
                                                1개월
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id="Week"
                                                checked={selectedPeriod === 'oneWeek'}
                                                onChange={() => handlePeriodChange('oneWeek')}
                                            />
                                            <label className="form-check-label" htmlFor="Week">
                                                1주일
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center gap-2 mt-3">
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-sm"
                                        onClick={handleReset}
                                    >
                                        초기화
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-sm"
                                    >
                                        검색
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    {/* 유저 목록 테이블 */}
                    <div className="row">
                        <div className="display-count mb-1 d-flex justify-content-between">
                            <span>총 결제 건수 : {buyList.data?.meta?.totalCnt ? buyList.data.meta.totalCnt.toLocaleString() : 0}</span>
                        </div>
                        <table className="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th className='text-center'>번호</th>
                                    <th className='text-center'>기관명</th>
                                    <th className='text-center'>아이디</th>
                                    <th className='text-center'>이름</th>
                                    <th className='text-center'>구매제품</th>
                                    <th className='text-center'>솔루션 이름</th>
                                    <th className='text-center'>결제금액</th>
                                    <th className='text-center'>결제일</th>
                                    <th className='text-center'>이용기간</th>
                                </tr>
                            </thead>
                            <tbody>
                                {buyList.loading ? (
                                    <tr>
                                        <td className="text-center"> loading...</td>
                                    </tr>
                                ) : buyList.data?.data?.length > 0 ? (
                                    buyList.data.data.map((buy: ResultPaymntDto, index: number) => (
                                        <tr key={index}>
                                            <td className='text-center'>{
                                                buyList.data.meta.totalCnt -
                                                (buyList.data.meta.currentPageNo - 1) * buyList.data.meta.pageSize - index
                                            }
                                            </td>
                                            <td className='text-center'>
                                                <button className='link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover' onClick={() => handleMoveUserGrpShow(buy)}>
                                                    {buy.ognzNm}
                                                </button>
                                            </td>
                                            <td className='text-center'>
                                                <button className='link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover' onClick={() => handleShow(buy.userId!)}>
                                                    {buy.userId}
                                                </button>
                                            </td>
                                            <td className='text-center'>{buy.userNm}</td>
                                            <td className='text-center'>{buy.sltnNm}</td>
                                            <td className='text-center'>{buy.stlmNm}</td>
                                            <td className='text-center'>{Number(buy.sltmAmt).toLocaleString()}</td>
                                            <td className='text-center'>{new Date(buy.regDt!).toLocaleDateString()}</td>
                                            <td className='text-center'>{new Date(buy.startDate!).toLocaleDateString()} ~ {new Date(buy.endDate!).toLocaleDateString()}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={9} className='text-center'>데이터가 없습니다.</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        {buyList.data && buyList.data.meta ? (
                            <Pagination key="1" pageingDto={buyList.data.meta} onClick={onSearch} />
                        ) : (<span></span>)
                        }
                    </div>
                </ContentLayout>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{profileDto.user.userName}님 회원 정보</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h3 className='dash-ti mb-2'>필수 정보</h3>
                        <Table bordered size="sm">
                            <colgroup>
                                <col style={{ width: '120px' }} />
                                <col style={{ width: 'auto' }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>이름</th>
                                    <td style={{ paddingLeft: '10px' }}>
                                        {profileDto.user.userName}
                                    </td>
                                </tr>
                                <tr>
                                    <th>회원유형</th>
                                    <td style={{ paddingLeft: '10px' }}>
                                        {profileDto.user.userRoleCd === 'ADMIN' ? '관리자' : '사용자'}
                                    </td>
                                </tr>
                                <tr>
                                    <th>아이디</th>
                                    <td style={{ paddingLeft: '10px' }}>
                                        {profileDto.user.userId}
                                    </td>
                                </tr>
                                <tr>
                                    <th>이메일</th>
                                    <td style={{ paddingLeft: '10px' }}>{profileDto.user.email}</td>
                                </tr>
                            </tbody>
                        </Table>

                        <h3 className='dash-ti mb-2'>구독 정보</h3>
                        <Table bordered size="sm">
                            <colgroup>
                                <col style={{ width: '120px' }} />
                                <col style={{ width: 'auto' }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>기관</th>
                                    <td colSpan={2}>{profileDto.user.ognzName}</td>
                                </tr>
                                {profileDto.products.map((product, index) => (
                                    <tr key={index}>
                                        {index === 0 && (
                                            <th rowSpan={profileDto.products.length}>구매제품</th>
                                        )}
                                        <td>{product.sltnNm}</td>
                                        <td>{product.startDate}~{product.endDate}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            닫기
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    )
}
export default BuyList;