import { getNoticesAsync , getNoticeAsync, postNoticesAsync , putNoticesAsync , deleteNoticesAsync , getFaqListAsync , getFaqAsync , postFaqAsync , putFaqAsync , deleteFaqAsync , getContactUsListAsync , getContactUsAsync , postContactUsAsync , putContactUsAsync , deleteContactUsAsync } from './actions';
import { GET_NOTICES, GET_NOTICE_BY_ID, GET_FAQ , GET_FAQ_BY_ID , POST_FAQ , PUT_FAQ , DELETE_FAQ , POST_NOTICES , PUT_NOTICES , DELETE_NOTICES , GET_CONTACT_US , GET_CONTACT_US_BY_ID , POST_CONTACT_US , PUT_CONTACT_US , DELETE_CONTACT_US } from './actions';
import { getNotice, getNotices , postNotice , putNotice , deleteNotice , getFaqList , getFaq , postFaq , putFaq , deleteFaq , getContactUsList , getContactUs , postContactUs , putContactUs , deleteContactUs} from '../../api/support';
import { takeEvery } from 'redux-saga/effects';
import createAsyncSaga from '../lib';

const getNoticesSaga = createAsyncSaga(getNoticesAsync, getNotices);
const getNoticeByIdSaga = createAsyncSaga(getNoticeAsync, getNotice);
const postNoticesSaga = createAsyncSaga(postNoticesAsync, postNotice);
const putNoticesSaga = createAsyncSaga(putNoticesAsync, putNotice);
const deleteNoticesSaga = createAsyncSaga(deleteNoticesAsync, deleteNotice);

const getFaqListSaga    = createAsyncSaga(getFaqListAsync, getFaqList);
const getFaqSaga        = createAsyncSaga(getFaqAsync, getFaq);
const postFaqSaga   = createAsyncSaga(postFaqAsync, postFaq);
const putFaqSaga    = createAsyncSaga(putFaqAsync, putFaq);
const deleteFaqSaga = createAsyncSaga(deleteFaqAsync, deleteFaq);

const getContactUsListSaga = createAsyncSaga(getContactUsListAsync , getContactUsList);
const getContactUsSaga     = createAsyncSaga(getContactUsAsync     , getContactUs);
const postContactUsSaga    = createAsyncSaga(postContactUsAsync    , postContactUs);
const putContactUsSaga     = createAsyncSaga(putContactUsAsync     , putContactUs);
const deleteContactUsSaga  = createAsyncSaga(deleteContactUsAsync  , deleteContactUs);

export function* supportSaga() {
  yield takeEvery(GET_NOTICES       , getNoticesSaga);
  yield takeEvery(GET_NOTICE_BY_ID  , getNoticeByIdSaga);
  yield takeEvery(POST_NOTICES      , postNoticesSaga);
  yield takeEvery(PUT_NOTICES       , putNoticesSaga);
  yield takeEvery(DELETE_NOTICES    , deleteNoticesSaga);

  yield takeEvery(GET_FAQ        , getFaqListSaga);
  yield takeEvery(GET_FAQ_BY_ID  , getFaqSaga); 
  yield takeEvery(POST_FAQ       , postFaqSaga);
  yield takeEvery(PUT_FAQ        , putFaqSaga);
  yield takeEvery(DELETE_FAQ     , deleteFaqSaga);
  
  yield takeEvery(GET_CONTACT_US       , getContactUsListSaga);
  yield takeEvery(GET_CONTACT_US_BY_ID , getContactUsSaga);
  yield takeEvery(POST_CONTACT_US      , postContactUsSaga);
  yield takeEvery(PUT_CONTACT_US       , putContactUsSaga);
  yield takeEvery(DELETE_CONTACT_US    , deleteContactUsSaga);
}
