import { takeEvery } from 'redux-saga/effects';
import createAsyncSaga from '../lib';
import { DELETE_USER_GROUP_MEMBER, deleteUserGroupMemberAsync, FIND_USER_GROUP_LIST, FIND_USER_GROUP_MEMBER_LIST, findUserGroupListAsync, findUserGroupMemberListAsync, UPDATE_GROUP_USER_ROLE, UPDATE_USER_GROUP_MEMBER as UPDATE_USER_GROUP_MEMBER, updateGroupUserRoleAsync, updateUserGroupMemberAsync } from './actions';
import { deleteGroupUsers as deleteUserGroupMember, findUserGroupList, findUserGroupMemberList, updateGroupUser, updateGroupUserRole } from '../../api/usersgroup';


const findUserGroupListSaga = createAsyncSaga(findUserGroupListAsync, findUserGroupList);
const findUserGroupMemberListSaga = createAsyncSaga(findUserGroupMemberListAsync, findUserGroupMemberList);
const updateUserGroupMemberListSaga = createAsyncSaga(updateUserGroupMemberAsync, updateGroupUser);
const deleteUserGroupMemberListSaga = createAsyncSaga(deleteUserGroupMemberAsync, deleteUserGroupMember);
const updateUserRoleSaga = createAsyncSaga(updateGroupUserRoleAsync, updateGroupUserRole);

export function* userGroupSaga() {
  yield takeEvery(FIND_USER_GROUP_LIST, findUserGroupListSaga);
  yield takeEvery(FIND_USER_GROUP_MEMBER_LIST, findUserGroupMemberListSaga);
  yield takeEvery(UPDATE_USER_GROUP_MEMBER, updateUserGroupMemberListSaga);
  yield takeEvery(DELETE_USER_GROUP_MEMBER, deleteUserGroupMemberListSaga);
  yield takeEvery(UPDATE_GROUP_USER_ROLE, updateUserRoleSaga);
}
