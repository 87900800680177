import axios from 'axios';
import { SearchSupportDto } from '../types/support/SearchSupportDto';
import { CreateNoticetDto } from '../types/support/CreateNoticetDto';
import { UpdateNoticetDto } from '../types/support/UpdateNoticetDto';

import { CreateContactUsDto } from '../types/support/CreateContactUsDto';
import { UpdateContactUsDto } from '../types/support/UpdateContactUsDto';

import { CreateFaqDto } from '../types/support/CreateFaqDto';
import { UpdateFaqDto } from '../types/support/UpdateFaqDto';

import config from '../config';
import { AsyncState } from '../modules/lib/reducerUtils';
import accessTokenPortalApi from './api';
import {ApiResponseList , ApiResponse} from '../types/common/CommonApi';

export async function getNotices(searchNoticeDto: SearchSupportDto): Promise<any | null> {
  try {
    const response = await accessTokenPortalApi.get<Promise<ApiResponseList> | null>(
      `/admin/notices`,
      {
        headers: {
          'Content-Type': 'multipart/form-data', // multipart/form-data 헤더 설정
        },
        params : searchNoticeDto 
      }
    );

    return response.data;
  } catch (error) {
    console.error('Failed to select:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: 'Failed to select',
      },
    };
  }
};

export async function getNotice(searchNoticeDto: SearchSupportDto): Promise<any | null> {
  try {
    const response = await accessTokenPortalApi.get<ApiResponseList | null>(
      `/admin/notices/`+searchNoticeDto.ntcSn,
      {
        headers: {
          'Content-Type': 'multipart/form-data', // multipart/form-data 헤더 설정
        },
        params : searchNoticeDto 
      }
    );

    return response.data;
  } catch (error) {
    console.error('Failed to select:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: 'Failed to create user',
      },
    };
  }
};


export async function postNotice(createNoticetDto: CreateNoticetDto): Promise<any | null> {
  
  const formData = new FormData();

  // createNoticetDto의 모든 속성을 FormData에 추가
  Object.entries(createNoticetDto).forEach(([key, value]) => {
    // 파일 리스트인 경우, 배열로 처리
    if (Array.isArray(value)) {
      value.forEach(file => {
        formData.append(key, file); // 파일 리스트 추가
      });
    } else {
      formData.append(key, value); // 일반 필드 추가
    }
  });

    
  try {
    const response = await accessTokenPortalApi.post<AsyncState<CreateNoticetDto>>(
      `/admin/notices`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data', // multipart/form-data 헤더 설정
        },
      }
    );

    // console.log('저장 결과 api:', JSON.stringify(response.data, null, 2));

   //  const response = await axios.post(`${config.portalApiUrl}/notices`, formData);
    return response.data;
  } catch (error) {
    // console.log('저장 결과 error api:', JSON.stringify(error, null, 2));
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: 'Failed to create notice',
      },
    };
  }
};

export async function putNotice(updateNoticetDto: UpdateNoticetDto): Promise<any | null> {
  
  const formData = new FormData();

  // createNoticetDto의 모든 속성을 FormData에 추가
  Object.entries(updateNoticetDto).forEach(([key, value]) => {
    // 파일 리스트인 경우, 배열로 처리
    if (key != "ntcSn") {
      if (Array.isArray(value)) {
        value.forEach(file => {
          formData.append(key, file); // 파일 리스트 추가
        });
      } else {
        formData.append(key, value); // 일반 필드 추가
      }
    }
    
  });

    
  try {
    const response = await accessTokenPortalApi.put<AsyncState<UpdateNoticetDto>>(
      `/admin/notices/`+updateNoticetDto.ntcSn,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data', // multipart/form-data 헤더 설정
        },
      }
    );

    // console.log('수정 결과 api:', JSON.stringify(response.data, null, 2));

   //  const response = await axios.post(`${config.portalApiUrl}/notices`, formData);
    return response.data;
  } catch (error) {
    // console.log('저장 결과 error api:', JSON.stringify(error, null, 2));
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: 'Failed to create notice',
      },
    };
  }
};



export async function deleteNotice(createNoticetDto: CreateNoticetDto): Promise<any | null> {
  try {
    const response = await accessTokenPortalApi.delete<AsyncState<CreateNoticetDto>>(
      `/admin/notices/`+createNoticetDto.ntcSn,
    );

    // console.log('저장 결과 api:', JSON.stringify(response.data, null, 2));

   //  const response = await axios.post(`${config.portalApiUrl}/notices`, formData);
    return response.data;
  } catch (error) {
    // console.log('저장 결과 error api:', JSON.stringify(error, null, 2));
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: 'Failed to create notice',
      },
    };
  }
};


export async function getFaqList(searchSupportDto: SearchSupportDto): Promise<any | null> {
  try {
    const response = await accessTokenPortalApi.get<ApiResponseList>(
      `/faq`,
      {
        params :  searchSupportDto,
      }
    );

    return response.data; // 업데이트된 사용자 데이터를 반환
    
  } catch (error) {
    console.error('Error updated user:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: 'Failed to updated user',
      },
    };
  }
}

export async function getFaq(faqSn: number): Promise<any | null> {
  try {
    const response = await axios.get<ApiResponse | null>(
      `${config.portalApiUrl}/faq/`+faqSn,
    );

    // console.log('api 리턴 결과:', JSON.stringify(response.data, null, 2));
    
    return response.data;
  } catch (error) {
    console.error('Failed to select:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: 'Failed to create faq',
      },
    };
  }
};

export async function postFaq(createFaqDto: CreateFaqDto): Promise<any | null> {
  try {
    
    const response = await accessTokenPortalApi.post<CreateFaqDto>(
      `/faq`,
      createFaqDto,      
    );

    // console.log('저장 결과 api:', JSON.stringify(response.data, null, 2));

   //  const response = await axios.post(`${config.portalApiUrl}/notices`, formData);
    return response.data;
  } catch (error:any) {
    var message = "Failed to create faq";
    
    if (error.message == "Request failed with status code 401") {
      message = "로그인 후 사용이 가능합니다.";
    }
    
    // console.log('저장 결과 error api:', JSON.stringify(error, null, 2));
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: message,
      },
    };
  }
};

export async function putFaq(updateFaqDto: UpdateFaqDto): Promise<any | null> {
  try {
    const response = await accessTokenPortalApi.put<UpdateFaqDto>(
      `/faq/`+updateFaqDto.faqSn,
      updateFaqDto,
    );

    // console.log('수정 결과 api:', JSON.stringify(response.data, null, 2));

    return response.data;
  } catch (error) {
    // console.log('수정 결과 error api:', JSON.stringify(error, null, 2));
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: 'Failed to update faq',
      },
    };
  }
};

export async function deleteFaq(faqSn: number): Promise<any | null> {
  try {
    
    const response = await accessTokenPortalApi.delete<any>(
      `/faq/`+faqSn,
    );

    // console.log('삭제 결과 api:', JSON.stringify(response.data, null, 2));

    return response.data;
  } catch (error) {
    // console.log('삭제 결과 error api:', JSON.stringify(error, null, 2));
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: 'Failed to delete faq',
      },
    };
  }
};

export async function getContactUsList(searchSupportDto: SearchSupportDto): Promise<any | null> {
  try {
    const response = await accessTokenPortalApi.get<ApiResponseList>(
      `/contactUs`,
      {
        params :  searchSupportDto,
      }
    );

    return response.data; // 업데이트된 사용자 데이터를 반환
    
  } catch (error) {
    console.error('Error updated user:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: 'Failed to updated user',
      },
    };
  }
}

export async function getContactUs(inqSn: number): Promise<any | null> {
  try {
    const response = await accessTokenPortalApi.get<ApiResponse | null>(
      `/contactUs/`+inqSn,
    );

    // console.log('api 리턴 결과:', JSON.stringify(response.data, null, 2));
    
    return response.data;
  } catch (error) {
    console.error('Failed to select:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: 'Failed to create user',
      },
    };
  }
};

export async function postContactUs(createContactUsDto: CreateContactUsDto): Promise<any | null> {
  const formData = new FormData();
  if (createContactUsDto.fileList && createContactUsDto.fileList.length > 0) {
    formData.append('fileList', createContactUsDto.fileList[0]); 
  }
  
  // console.log('createContactUsDto 전송 결과:', JSON.stringify(createContactUsDto, null, 2));

  // createNoticetDto의 모든 속성을 FormData에 추가
  Object.entries(createContactUsDto).forEach(([key, value]) => {
    if (key != "fileList") {
      formData.append(key, value); 
    }
  });

  // console.log('formData 전송 결과:', JSON.stringify(formData, null, 2));
  try {
    const response = await accessTokenPortalApi.post<CreateContactUsDto>(
      `/contactUs`,
      formData,
    );

    // console.log('저장 결과 api:', JSON.stringify(response.data, null, 2));

   //  const response = await axios.post(`${config.portalApiUrl}/notices`, formData);
    return response.data;
  } catch (error:any) {
    var message = "Failed to create contactUs";
    
    if (error.message == "Request failed with status code 401") {
      message = "로그인 후 사용이 가능합니다.";
    }
    
    // console.log('저장 결과 error api:', JSON.stringify(error, null, 2));
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: message,
      },
    };
  }
};

export async function putContactUs(updateContactUsDto: UpdateContactUsDto): Promise<any | null> {
  const formData = new FormData();

  // createNoticetDto의 모든 속성을 FormData에 추가
  Object.entries(updateContactUsDto).forEach(([key, value]) => {
    // 파일 리스트인 경우, 배열로 처리
    if (key != "inqSn") {
      if (Array.isArray(value)) {
        value.forEach(file => {
          formData.append(key, file); // 파일 리스트 추가
        });
      } else {
        formData.append(key, value); // 일반 필드 추가
      }
    }
    
  });

    
  try {
    
    const response = await accessTokenPortalApi.put<UpdateContactUsDto>(
      `/contactUs/`+updateContactUsDto.inqSn,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data', // multipart/form-data 헤더 설정
        },
      }
    );

    // console.log('수정 결과 api:', JSON.stringify(response.data, null, 2));

    return response.data;
  } catch (error) {
    // console.log('수정 결과 error api:', JSON.stringify(error, null, 2));
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: 'Failed to update ContactUs',
      },
    };
  }
};

export async function deleteContactUs(inqSn: number): Promise<any | null> {
  try {
    
    const response = await accessTokenPortalApi.delete<any>(
      `/contactUs/`+inqSn,
    );

    // console.log('삭제 결과 api:', JSON.stringify(response.data, null, 2));

    return response.data;
  } catch (error) {
    // console.log('삭제 결과 error api:', JSON.stringify(error, null, 2));
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: 'Failed to delete ContactUs',
      },
    };
  }
};