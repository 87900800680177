import { FC } from 'react';

interface contentProps{
    children : React.ReactNode;
    title : string;
}

const ContentLayout :FC<contentProps> = ({ title , children}) => {
    return (
        <div className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12"><h2 className='page-title'>{title}</h2></div>
                </div>
                {children}
            </div>
        </div>
    )
}

export default ContentLayout;