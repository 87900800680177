import { createAsyncAction } from 'typesafe-actions';
import { AxiosError } from 'axios';
import { ErrorResponse, SuccessResponse } from '../../types/Response';

export const GET_OVERVIEW = 'api/GET_OVERVIEW';
export const GET_OVERVIEW_SUCCESS = 'api/GET_OVERVIEW_SUCCESS';
export const GET_OVERVIEW_ERROR = 'api/GET_OVERVIEW_ERROR';
export const GET_SIGNUP = 'api/GET_SIGNUP';
export const GET_SIGNUP_SUCCESS = 'api/GET_SIGNUP_SUCCESS';
export const GET_SIGNUP_ERROR = 'api/GET_SIGNUP_ERROR';
export const GET_PAYMNT = 'api/GET_PAYMNT';
export const GET_PAYMNT_SUCCESS = 'api/GET_PAYMNT_SUCCESS';
export const GET_PAYMNT_ERROR = 'api/GET_PAYMNT_ERROR';

export const getOverviewAsync = createAsyncAction(
  GET_OVERVIEW,
  GET_OVERVIEW_SUCCESS,
  GET_OVERVIEW_ERROR
)<void, SuccessResponse<any> | ErrorResponse, AxiosError>();

export const getSignupAsync = createAsyncAction(
  GET_SIGNUP,
  GET_SIGNUP_SUCCESS,
  GET_SIGNUP_ERROR
)<void, SuccessResponse<any> | ErrorResponse, AxiosError>();

export const getPurcasesAsync = createAsyncAction(
  GET_PAYMNT,
  GET_PAYMNT_SUCCESS,
  GET_PAYMNT_ERROR
)<void, SuccessResponse<any> | ErrorResponse, AxiosError>();