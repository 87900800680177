import { createAsyncAction } from 'typesafe-actions';
import { AxiosError } from 'axios';
import { ErrorResponse, SuccessResponse } from '../../types/Response';
import { PotalCommDto } from '../../types/PotalCommDto';
import { ResultPaymntDto, SelectPaymntDto } from '../../types/BuyDto';

export const FIND_USER_BUY_LIST = 'api/FIND_USER_BUY_LIST';
export const FIND_USER_BUY_LIST_SUCCESS = 'api/FIND_USER_BUY_LIST_SUCCESS';
export const FIND_USER_BUY_LIST_ERROR = 'api/FIND_USER_BUY_LIST_ERROR';

export const findScrtnListAsync = createAsyncAction(
  FIND_USER_BUY_LIST,
  FIND_USER_BUY_LIST_SUCCESS,
  FIND_USER_BUY_LIST_ERROR
)<SelectPaymntDto, SuccessResponse<ResultPaymntDto[], PotalCommDto> | ErrorResponse, AxiosError>();
