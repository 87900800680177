import { ChangeEvent, FC, FormEvent, useEffect, useState } from 'react';
import './login.scss';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../modules';
import { loginAsync } from '../../modules/authuser';

const Login: FC = () => {
    const [userId, setUserId] = useState('');
    const [userPw, setUserPw] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const authuser = useSelector((state: RootState) => state.auth);

    // 로그인 성공 시 후속 작업 처리
    useEffect(() => {
        const token = localStorage.getItem('access_token');
        if (token) {
            navigate('/'); // 로그인 성공 시 홈 페이지로 리다이렉트
        }
    }, [localStorage.getItem('access_token'), navigate]);

    // 에러가 발생했을 때 처리
    useEffect(() => {
        if (authuser.data.error) {
            alert('로그인에 실패했습니다. 다시 시도해 주세요.');
        }
    }, [authuser.data.error]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.name === 'userId') {
            setUserId(event.target.value);
        } else if (event.target.name === 'userPw') {
            setUserPw(event.target.value);
        }
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!userId || !userPw) {
            alert('아이디와 비밀번호를 입력하세요.');
            return;
        }

        // console.log('Form submitted, calling login');
        try {
            const response = dispatch(loginAsync.request({ username: userId, password: userPw }));

            setUserId('');
            setUserPw('');
        } catch (error) {
            console.error('로그인 실패:', error);
            alert('로그인에 실패했습니다. 다시 시도해주세요.');
        }
    };

    return (
        <div className='login'>
            <div className="d-flex justify-content-center">
                <div className='login-frame'>
                    <div className="d-flex justify-content-center mb-5">
                        <img src="/logo@216x39.png" alt="logo" />
                    </div>
                    <form className="login-form" onSubmit={handleSubmit}>
                        <div className="form-group mb-2">
                            <label htmlFor="userid">아이디</label>
                            <input
                                type="text"
                                id="userId"
                                name='userId'
                                className='form-control'
                                value={userId}
                                onChange={handleChange}
                                placeholder='아이디를 입력해 주세요.'
                                required
                            />
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="userid">비밀번호</label>
                            <input
                                type="password"
                                id="password"
                                name='userPw'
                                className='form-control'
                                value={userPw}
                                onChange={handleChange}
                                placeholder="영문, 숫자, 특수문자 조합 10~16자리"
                                required
                            />
                        </div>
                        <div className='d-grid mb-2'>
                            <button type='submit' className='btn btn-primary'>로그인</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
export default Login;
