import { FC, useState } from "react";
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import InputGroup from 'react-bootstrap/InputGroup';
import Accordion from 'react-bootstrap/Accordion';
import SideLayout from '../../containers/sidemenu';
import Header from '../../containers/header';
import Paging from '../../components/Paging';
import ContentLayout from '../../containers/contentLayout';

const FAQ :FC = () => {
    return (
        <div className="wrapper">
            <SideLayout />
            {/* leftside-menu */}
            <div className='content-page'>
                <Header />
                <ContentLayout title='제품관리'>
                    <div className='row'>
                        <form action="">
                            <div className='dash-wrap-cont dash-info-wrap'>
                                <div className="row mb-3">
                                    <div className="col-1 col-form-label">
                                        서비스 대상
                                    </div>
                                    <div className="col-11">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault01" />
                                            <label className="form-check-label" htmlFor="flexCheckDefault01">
                                                전체
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault02" />
                                            <label className="form-check-label" htmlFor="flexCheckDefault02">
                                                게시
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault03" />
                                            <label className="form-check-label" htmlFor="flexCheckDefault03">
                                                미게시
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-1 col-form-label">
                                        검색어
                                    </div>  
                                    <div className="col-3">
                                        <select className="form-select form-select-sm" aria-label="Default select example">
                                            <option selected>전체</option>
                                            <option value="1">검색</option>
                                            <option value="2">내용</option>
                                        </select>
                                    </div>
                                    <div className="col-8">
                                        <input className="form-control form-control-sm" type="text" placeholder="제목, 내용, 이름, 이메일로검색" aria-label=".form-control-sm example" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-1 col-form-label">
                                        조회기간
                                    </div>  
                                    <div className="col-2">
                                        <input className="form-control form-control-sm" type="date" placeholder="" aria-label="" />
                                    </div>
                                    <div className="col-2">
                                        <input className="form-control form-control-sm" type="date" placeholder="" aria-label="" />
                                    </div>
                                    
                                    <div className="col-7">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="dataAll" />
                                            <label className="form-check-label" htmlFor="dataAll">
                                                전체
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="Year" />
                                            <label className="form-check-label" htmlFor="Year">
                                                1년
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="Month6" />
                                            <label className="form-check-label" htmlFor="Month6">
                                                6개월
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="Month1" />
                                            <label className="form-check-label" htmlFor="Month1">
                                                1개월
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="Week" />
                                            <label className="form-check-label" htmlFor="Week">
                                                1주일
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center gap-2 mt-3">
                                    <button type="button" className="btn btn-secondary btn-sm">초기화</button>
                                    <button type="button" className="btn btn-primary btn-sm">검색</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    
                    <div className="display-count mb-1 d-flex justify-content-between mb-2">
                        <span>총 게시물 : 9,999</span>
                        <button type="button" className="btn btn-secondary btn-sm">FAQ 등록</button>
                    </div>

                    <div className="row">
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>디지털 트윈 지도 서비스 디지털트윈 에셋 활용 방안</Accordion.Header>
                                <Accordion.Body>
                                디지털트윈 에셋 활용 방안을 안내합니다
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>AI 데이터 생성관리 서비스 AI 데이터 생성관리를 위한 학습 방안</Accordion.Header>
                                <Accordion.Body>
                                디지털트윈 에셋 활용 방안을 안내합니다
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>

                    {/* paging */}
                    <Paging />
                    {/* paging */}
                </ContentLayout> 
            </div>
        </div>
    )
    
}
export default FAQ;
