import { takeEvery } from 'redux-saga/effects';
import createAsyncSaga from '../lib';
import { DELETE_USER, deleteUserAsync, FIND_USER, FIND_USER_LIST, findUserAsync, findUserListAsync, UPDATE_USER, updateUserAsync } from './actions';
import { deleteUser, findUser, findUserList, updateUser } from '../../api/users';


const findUserListSaga = createAsyncSaga(findUserListAsync, findUserList);
const findUserSaga = createAsyncSaga(findUserAsync, findUser);
const updateUserSaga = createAsyncSaga(updateUserAsync, updateUser);
const deleteUserSaga = createAsyncSaga(deleteUserAsync, deleteUser);

export function* userSaga() {
  yield takeEvery(FIND_USER_LIST, findUserListSaga);
  yield takeEvery(FIND_USER, findUserSaga);
  yield takeEvery(UPDATE_USER, updateUserSaga);
  yield takeEvery(DELETE_USER, deleteUserSaga);
}
