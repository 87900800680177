import { FC, useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../modules/";
import { getCommCdListAsync } from '../modules/common';

interface SearchFormProps {
    objectId : string;
    cdGrpId  : string;
    defValue? : string;
    onChange?: (id : string , value: string) => void; // onChange prop 추가
}

const RadioBox:FC<SearchFormProps> = ({ objectId, cdGrpId, defValue , onChange }) => {

    const dispatch = useDispatch();
    const comboInfo  = useSelector((state:RootState) => state.common.data);
    

    useEffect(() => {
        
        dispatch(getCommCdListAsync.request({}));

        // console.log('리턴 결과:', JSON.stringify(comboInfo, null, 2));
      },[]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(objectId , e.target.value); // 선택된 값 전달
        }
    };

    let resultList = [];

    if (comboInfo.data && comboInfo.data.data) {
        for (let i=0;i<comboInfo.data.data.length;i++) {
            let combo = comboInfo.data.data[i];
            let nwObjectId = objectId + "_" + i;

            if (cdGrpId == combo.cdGrpId) {
                // options.push(<option key={combo.cmCdId + '_' + combo.cmCdId} value={combo.cmCdId}>{combo.cdVl}</option>);
                resultList.push(
                <div key={nwObjectId} className="form-check form-check-inline">
                    <input   className="form-check-input" type="radio" name={objectId} id={nwObjectId} value={combo.cmCdId} checked={defValue === combo.cmCdId} onChange={handleChange} />
                    <label className="form-check-label" htmlFor={nwObjectId} style={{cursor:"pointer"}}>
                        {combo.cdVl}
                    </label>
                </div>
                );
            }
        }
    }

    
    return (
        <div>
            {resultList}
        </div>
        
    )
}

export default RadioBox;