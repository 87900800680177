import { createReducer } from 'typesafe-actions';
import { UserState, UserAction } from './types';
import { deleteUserAsync, findUserAsync, findUserListAsync, updateUserAsync } from './actions';
import { asyncState, createAsyncReducer, transformToArray } from '../lib/reducerUtils';

const initialState: UserState = {
  userList: asyncState.initial(),
  selectedUser: asyncState.initial(),
  data: asyncState.initial(),
};

const user = createReducer<UserState, UserAction>(initialState)
.handleAction(
  findUserListAsync.request,
  (state) =>({
    ...state,
    loading: true,
    error: null,
    userList: asyncState.load(),
  })
)
.handleAction(
  findUserListAsync.success,
  (state, action) => ({
    ...state,
    userList: asyncState.success(action.payload),  // 목록 성공
    loading: false,
    error: null,
  })
)
.handleAction(
  findUserListAsync.failure,
  (state, action) => ({
    ...state,
    userList: asyncState.error(action.payload),  // 목록 실패
    loading: false,
    error: action.payload,
  })
)
.handleAction(
  findUserAsync.request,
  (state) =>({
    ...state,
    loading: true,
    error: null,
    selectedUser: asyncState.load(),  // 선택된 유저 로딩 중
  })
)
.handleAction(
  findUserAsync.success,
  (state, action) => ({
    ...state,
    selectedUser: asyncState.success(action.payload),  // 선택된 유저 성공
    loading: false,
    error: null,
  })
)
.handleAction(
  findUserAsync.failure,
  (state, action) => ({
    ...state,
    selectedUser: asyncState.error(action.payload),  // 선택된 유저 실패
    loading: false,
    error: action.payload,
  })
).handleAction(
  updateUserAsync.request,
  (state) =>({
    ...state,
    loading: true,
    error: null,
  })
)
.handleAction(
  updateUserAsync.success,
  (state, action) => ({
    ...state,
    data: asyncState.success(action.payload),
    loading: false,
    error: null,
  })
)
.handleAction(
  updateUserAsync.failure,
  (state, action) => ({
    ...state,
    data: asyncState.error(action.payload),
    loading: false,
    error: action.payload,
  })
).handleAction(
  deleteUserAsync.request,
  (state) =>({
    ...state,
    loading: true,
    error: null,
  })
)
.handleAction(
  deleteUserAsync.success,
  (state, action) => ({
    ...state,
    data: asyncState.success(action.payload),
    loading: false,
    error: null,
  })
)
.handleAction(
  deleteUserAsync.failure,
  (state, action) => ({
    ...state,
    data: asyncState.error(action.payload),
    loading: false,
    error: action.payload,
  })
)
;

export default user;