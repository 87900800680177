import { createAsyncAction } from 'typesafe-actions';
import { AxiosError } from 'axios';
import { ErrorResponse, SuccessResponse } from '../../types/Response';
import { PotalCommDto } from '../../types/PotalCommDto';
import { CreatePrdctDto, PrdctDetailDto, PrdctDto, SelectPrdctDto } from '../../types/PrdctDto';

export const FIND_PRDCT_LIST = 'api/FIND_PRDCT_LIST';
export const FIND_PRDCT_LIST_SUCCESS = 'api/FIND_PRDCT_LIST_SUCCESS';
export const FIND_PRDCT_LIST_ERROR = 'api/FIND_PRDCT_LIST_ERROR';
export const FIND_PRDCT_ITEM_LIST = 'api/FIND_PRDCT_ITEM_LIST';
export const FIND_PRDCT_ITEM_SUCCESS = 'api/FIND_PRDCT_ITEM_SUCCESS';
export const FIND_PRDCT_ITEM_ERROR = 'api/FIND_PRDCT_ITEM_ERROR';
export const UPDATE_PRDCT = 'api/UPDATE_PRDCT';
export const UPDATE_PRDCT_SUCCESS = 'api/UPDATE_PRDCT_SUCCESS';
export const UPDATE_PRDCT_ERROR = 'api/UPDATE_PRDCT_ERROR';

export const GET_MAPP_DASH = 'api/GET_MAPP_DASH'
export const GET_MAPP_DASH_SUCCESS = 'api/GET_MAPP_DASH_SUCCESS'
export const GET_MAPP_DASH_ERROR = 'api/GET_MAPP_DASH_ERROR'

export const GET_BUY_USE_LIST         = 'api/GET_BUY_USE_LIST'
export const GET_BUY_USE_LIST_SUCCESS = 'api/GET_BUY_USE_LIST_SUCCESS'
export const GET_BUY_USE_LIST_ERROR   = 'api/GET_BUY_USE_LIST_ERROR'


export const findPrdctListAsync = createAsyncAction(
  FIND_PRDCT_LIST,
  FIND_PRDCT_LIST_SUCCESS,
  FIND_PRDCT_LIST_ERROR
)<string, SuccessResponse<PrdctDto[], PotalCommDto> | ErrorResponse, AxiosError>();

export const findPrdctItemListAsync = createAsyncAction(
  FIND_PRDCT_ITEM_LIST,
  FIND_PRDCT_ITEM_SUCCESS,
  FIND_PRDCT_ITEM_ERROR
)<string, SuccessResponse<PrdctDetailDto[], PotalCommDto> | ErrorResponse, AxiosError>();

export const createPrdctAsync = createAsyncAction(
  UPDATE_PRDCT,
  UPDATE_PRDCT_SUCCESS,
  UPDATE_PRDCT_ERROR
)<{sltnId: string, createPrdctDto : CreatePrdctDto}, SuccessResponse<CreatePrdctDto> | ErrorResponse, AxiosError>();