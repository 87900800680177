import axios from 'axios';
import { SelectNeospectraReqDto } from '../types/dashboard/SelectNeospectraReqDto';
import config from '../config';
import accessTokenPortalApi, { accessTokenInfoseedApi, accessTokenMapprimeApi, accessTokenNeospectraApi } from './api';
import { ApiResponseList } from '../types/common/CommonApi';


interface MapprimeDashboardApiResponse {
  count?: { project: number, asset: { progress: number, done: number } }, original: number;
  datasize?: { id: number, name: string, size: number }[];
  query?: any;
  result: string;
  message: string;
  page?: number;
}

interface NeospectraUsagesServiesLeft {
  category_code: string;
  category_name: string;
  available: number;
  used: number;
  percent: number;
}

interface InfoseedDashboardApiResponse {
  status: string;
  data: {
    PRDCT_NM: string;
    START_DATE: string;
    END_DATE: string;
    TOTAL_MEMORY: string;
    USE_MEMORY: string;
    TOTAL_DRN_CNT: string;
    USE_DRN_CNT: string;
    TOTAL_EQM_CNT: string;
    USE_EQM_CNT: string;
    TOTAL_PLT_CNT: string;
    USE_PLT_CNT: string;
    TOTAL_INVS_CNT: string;
    USE_INVS_CNT: string;
    DRN_PRJ_CNT: string;
    RE_DRN_PRJ_CNT: string;
    COM_DRN_PRJ_CNT: string;
    PIC_PRJ_CNT: string;
    PIC_FILE_CNT: string;
    COM_PIC_PRJ_CNT: string;
    PRJ_DATA_AMOUNT: [
      {
        PRJ_NM: string;
        FILE_CNT: string;
        USE_AMOUNT: string;
      }
    ]
  }
}

// 날짜별 작업 프로젝트 조회
export async function getNeospectraProjectsStats({ access_token, param }: { access_token: string, param: SelectNeospectraReqDto }): Promise<any | null> {
  try {

    const returnMap = {
      resultList1: []
      , resultList2: []
    };

    param.category = "OBJECT_DETECTION";
    const response = await accessTokenNeospectraApi.get<[]>(
      `/project/v1/a/usages/projects/stats`,
      {
        params: param
      }
    );

    returnMap.resultList1 = response.data;

    param.category = "OBJECT_DELETION";
    const response2 = await accessTokenNeospectraApi.get<[]>(
      `/project/v1/a/usages/projects/stats`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'x-ncp-apigw-api-key': `${config.neospectraApiKey}`,
        },
        params: param
      }
    );

    returnMap.resultList2 = response2.data;
    // console.log('api 리턴 결과:', JSON.stringify(response.data, null, 2));
    
    return returnMap;
  } catch (error) {
    // 토큰 인증 실패 처리
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      alert("토큰 인증이 실패하였습니다.");
      console.error('토큰 인증 실패: 로그인 페이지로 리디렉션');
      // 로그아웃 처리 및 리디렉션 또는 리프레시 토큰 요청
      localStorage.removeItem('access_token'); // 토큰 삭제
      window.location.href = '/'; // 로그인 페이지로 리디렉션
    } else {
      console.error('Failed to select:', error);
    }

    return null;
  }
};

// 날짜별 작업 통계 조회
export async function getNeospectraJobsStats({ access_token, param }: { access_token: string, param: SelectNeospectraReqDto }): Promise<any | null> {
  try {

    const returnMap = {
      resultList1: []
      , resultList2: []
    };

    param.category = "OBJECT_DETECTION";
    const response = await accessTokenNeospectraApi.get<[]>(
      `/project/v1/a/usages/jobs/stats`,
      {
        params: param
      }
    );

    returnMap.resultList1 = response.data;

    param.category = "OBJECT_DELETION";
    const response2 = await accessTokenNeospectraApi.get<[]>(
      `/project/v1/a/usages/jobs/stats`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'x-ncp-apigw-api-key': `${config.neospectraApiKey}`,
        },
        params: param
      }
    );

    returnMap.resultList2 = response2.data;
    // console.log('api 리턴 결과:', JSON.stringify(response.data, null, 2));

    return returnMap;
  } catch (error) {
    // 토큰 인증 실패 처리
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      alert("토큰 인증이 실패하였습니다.");
      console.error('토큰 인증 실패: 로그인 페이지로 리디렉션');
      // 로그아웃 처리 및 리디렉션 또는 리프레시 토큰 요청
      localStorage.removeItem('access_token'); // 토큰 삭제
      window.location.href = '/'; // 로그인 페이지로 리디렉션
    } else {
      console.error('Failed to select:', error);
    }

    return null;
  }
};

// 서비스별 남은 사용자 조회
export async function getNeospectraUsagesServiesLeft(ognzGrpSn: number): Promise<any[] | null> {
  try {

    const response = await accessTokenNeospectraApi.get<any[]>(
      `/project/v1/a/usages/services/left`,
      {
        params: {
          org_id: ognzGrpSn,
        },
      }
    );
    // console.log('getNeospectraUsagesServiesLeft 리턴 결과:', JSON.stringify(response.data, null, 2));
    return response.data;
  } catch (error) {
    // 토큰 인증 실패 처리
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      alert("토큰 인증이 실패하였습니다.");
      console.error('토큰 인증 실패: 로그인 페이지로 리디렉션');
      // 로그아웃 처리 및 리디렉션 또는 리프레시 토큰 요청
      localStorage.removeItem('access_token'); // 토큰 삭제
      window.location.href = '/'; // 로그인 페이지로 리디렉션
    } else {
      console.error('Failed to select:', error);
    }

    return null;
  }
};

// 저장소 사용량 조회
export async function getNeospectraUsagesCurrent(ognzGrpSn: number): Promise<any | null> {
  try {

      const response = await accessTokenNeospectraApi.get<any>(
          `/storage/v1/a/usages/current`,
          {
            params: {
              org_id: ognzGrpSn,
            }
          }
      );
      // console.log('api 리턴 결과:', JSON.stringify(response.data, null, 2));

      return response.data;
  } catch (error) {
      console.error('Failed to storage/v1/e/usages/current:', error);

      return null;
  }
};

// Mapprime 정보를 받아온다.
export async function getMapprimeDashboard(ognzUserGrpSn: number): Promise<any | null> {
  try {
    // console.log("type: ", typeof ognzUserGrpSn)
    const response = await accessTokenMapprimeApi.get<Promise<MapprimeDashboardApiResponse> | null>(
      `/data/portal/dashboard/${ognzUserGrpSn}`,
    );
    // console.log('getMapprimeDashboard 리턴 결과:', JSON.stringify(response, null, 2));

    return response.data;
  } catch (error) {
    console.error('Failed to select:', error);
    return {
      result: 'error',
      message: `${error}`,
    };
  }
};

// Infoseed 정보를 받아온다.
export async function getInfoseedDashboard({ ognzId, ognzUserGrpSn: ognzGrpSn }: { ognzId: string, ognzUserGrpSn: number }): Promise<InfoseedDashboardApiResponse | any | null> {
  try {
    // console.log("type: ", typeof ognzGrpSn)
    const response = await accessTokenInfoseedApi.get<Promise<InfoseedDashboardApiResponse> | null>(
      `/${config.infoseedApiVer}/common/admin-mypage`,
      {
        params: {
          ognzId,
          ognzUserGrpSn: ognzGrpSn,
        }
      }
    );
    // console.log('getInfoseedDashboard 리턴 결과:', JSON.stringify(response, null, 2));

    return response.data;
  } catch (error) {
    console.error('Failed to select:', error);
    return {
      result: 'error',
      message: `${error}`,
    };
  }
};

export async function getBuyScrtnList({ sltnId, currentPageNo, pageUnit, ognzUserGrpSn }: { sltnId: string, currentPageNo: number, pageUnit: number, ognzUserGrpSn: number }): Promise<any | null> {
  try {
    // console.log("type: ", typeof ognzUserGrpSn)
    const response = await accessTokenPortalApi.get<Promise<any> | null>(
      `/buy/adminScrtn`,
      {
        params: {
          sltnId,
          currentPageNo,
          pageUnit,
          ognzUserGrpSn
        }
      }
    );
    // console.log('getInfoseedDashboard 리턴 결과:', JSON.stringify(response, null, 2));

    return response.data;
  } catch (error) {
    console.error('Failed to select:', error);
    return {
      result: 'error',
      message: `${error}`,
    };
  }
};

// 현재 사용중인 구독 관련 목록을 조회한다.
export async function getBuyUseList(ognzUserGrpSn: number): Promise<any | null> {
  try {
    
    const response = await accessTokenPortalApi.get<Promise<ApiResponseList> | null>(
      `${config.portalApiUrl}/buy/adminUse`,{
        params: {ognzUserGrpSn }
      }
    );

    return response.data;
  } catch (error) {
    console.error('Failed to select:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: '조회시 오류가 발생하였습니다.',
      },
    };
  }
};

