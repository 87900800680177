import { takeEvery } from 'redux-saga/effects';
import createAsyncSaga from '../lib';
import { FIND_USER_BUY_LIST, findScrtnListAsync } from './actions';
import { paymentsList } from '../../api/buy';


const findBuyListSaga = createAsyncSaga(findScrtnListAsync, paymentsList);

export function* buySaga() {
  yield takeEvery(FIND_USER_BUY_LIST, findBuyListSaga);
}
