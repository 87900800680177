import { createReducer } from "typesafe-actions";
import { CountAction, CountState } from "./types";
import { SET_INCREASE, SET_DECREASE } from "./actions";

// 초기 상태 선언
const initialState: CountState = 0;

// 리듀서
const reducer = createReducer<CountState, CountAction>(initialState, {
    [SET_INCREASE]: (state) => state + 1
        ,
    [SET_DECREASE]: (state) => state - 1
});

export default reducer;