import { getType, AsyncActionCreatorBuilder } from "typesafe-actions";
import { AnyAction } from "redux";
import { PotalCommDto } from "../../types/PotalCommDto";

export type AsyncState<T, E = any> = {
  status?: string | null;
  data: T | null;
  meta: any | null;
  loading: boolean;
  error: E | null;
};

export const asyncState = {
  initial: <T, E = any>(initialData?: T, meta?: any): AsyncState<T, E> => ({
    loading: false,
    data: initialData || null,
    meta: meta || null,
    error: null
  }),
  load: <T, E = any>(data?: T, meta?: any): AsyncState<T, E> => ({
    loading: true,
    data: data || null,
    meta: meta || null,
    error: null
  }),
  success: <T, E = any>(data: T, meta?: any): AsyncState<T, E> => ({
    loading: false,
    data,
    meta: meta || null,
    error: null
  }),
  error: <T, E>(error: E, meta?: any): AsyncState<T, E> => ({
    loading: false,
    data: null,
    meta: meta || null,
    error: error
  })
};
type AnyAsyncActionCreator = AsyncActionCreatorBuilder<any, any, any>;
export function createAsyncReducer<S, AC extends AnyAsyncActionCreator, K extends keyof S>(
  asyncActionCreator: AC,
  key: K
) {
  return (state: S, action: AnyAction) => {
    const [request, success, failure] = [
      asyncActionCreator.request,
      asyncActionCreator.success,
      asyncActionCreator.failure
    ].map(getType);
    switch (action.type) {
      case request:
        return {
          ...state,
          [key]: asyncState.load()
        };
      case success:
        return {
          ...state,
          [key]: asyncState.success(action.payload)
        };
      case failure:
        return {
          ...state,
          [key]: asyncState.error(action.payload)
        };
      default:
        return state;
    }
  };
}
export function transformToArray<AC extends AnyAsyncActionCreator>(asyncActionCreator: AC) {
  const { request, success, failure } = asyncActionCreator;
  return [request, success, failure];
}