import { FC, useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../modules/";
import SideLayout from '../../containers/sidemenu';
import Header from '../../containers/header';
import ContentLayout from '../../containers/contentLayout';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { SearchSupportDto } from '../../types/support/SearchSupportDto';
import Pagination from '../../components/Pagination'
import { PotalCommDto } from '../../types/PotalCommDto';
import { getContactUsListAsync } from '../../modules/support';
import '../../styles/components/icon.scss';
import CheckBoxForm from "../../components/CheckBox";
import StartEndDt from "../../components/StartEndDt";

const ContactUsList : FC = () => {
    const [pageingDto, setPageing] = useState<PotalCommDto>({
        currentPageNo : 1
      , pageUnit : 10
      , pageSize : 10
      , firstIndex : 0
      , totalCnt   : 0
      , firstPageNo : 1
      , lastPageNo : 1
      , prevPageNo : 0
      , nextPageNo : 0
      , listTotalCnt : 0
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const supportInfo  = useSelector((state:RootState) => state.support.data);
    const [searchParams, setFormData] = useState<SearchSupportDto>({
        searchOrderBy : "REG_DT"
      , currentPageNo : 1
      , pageUnit : 10
    });

    var resultList = [];
    var listTotalCnt = 0;
    var totalCnt = 0;
    var pageUnit = 10;
    var currentPageNo = 1;
    var totalIndexCount = 0;

    if (supportInfo.data ) {
        if (supportInfo.data.meta) {
            totalCnt = Number(supportInfo.data.meta.totalCnt);
            pageUnit = supportInfo.data.meta.pageUnit;
            currentPageNo = supportInfo.data.meta.currentPageNo;
            totalIndexCount = supportInfo.data.meta.totalIndexCount;
        }
        
        if (supportInfo.data.data) {
           listTotalCnt = Number(supportInfo.data.data.length);
        }

        if (supportInfo.data.data) {
            listTotalCnt = Number(supportInfo.data.data.length);
 
             for (var i=0;i<supportInfo.data.data.length;i++) {
                 var result = supportInfo.data.data[i];
                 var listNo = totalCnt - (pageUnit * (currentPageNo -1) + i);
                 var atflOrgnlNmExtHtml = [] ;
 
                 if (result.atflOrgnlNmExt && result.atflOrgnlNmExt.length > 0) {
                    var atflOrgnlNmExt = result.atflOrgnlNmExt;
                    atflOrgnlNmExtHtml.push(<i className={'icon--'+atflOrgnlNmExt}></i>); 
                 }
 
                resultList.push(
                    <tr key={"tr_"+listNo}  style={{ height: "54px" }}>
                        <td style={{ textAlign: "center" }}>{listNo}</td>
                        <td><button key={"inqTtl_"+listNo} className='link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover' value={result.inqSn} onClick={(e) => onModify(e.currentTarget.value)}>
                                {result.inqTtl}
                            </button>
                        </td>
                        <td>{result.inqTypeNm}</td>
                        <td style={{ textAlign: "center" }}>{result.rspnsCn ? "답변완료" : "처리중"}</td>
                        <td style={{ textAlign: "center" }}>{result.regUserNm}</td>
                        <td style={{ textAlign: "center" }}>{result.regDt}</td>

                        <td style={{ textAlign: "center" }}>
                            {atflOrgnlNmExtHtml}
                        </td>
                    </tr>)
                }
         }
    }

    useEffect(() => {
        onSearch(1);
    },[]);

    useEffect(() => {
        if (supportInfo.data) {
            if (supportInfo.data.meta) {
                setPageing({ 
                    currentPageNo : supportInfo.data.meta.currentPageNo
                  , pageUnit : supportInfo.data.meta.pageUnit
                  , pageSize : supportInfo.data.meta.pageSize
                  , firstIndex : supportInfo.data.meta.firstIndex
                  , totalCnt   : supportInfo.data.meta.totalCnt
                  , firstPageNo : supportInfo.data.meta.firstPageNo
                  , lastPageNo : supportInfo.data.meta.lastPageNo
                  , prevPageNo : supportInfo.data.meta.prevPageNo
                  , nextPageNo : supportInfo.data.meta.nextPageNo
                  , listTotalCnt : supportInfo.data.data.length
              });
            }
        }
    },[supportInfo]);

    // SELECT BOX 변경시 
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value , currentPageNo: 1 }));
    };

    // 조회기간 버튼 클릭 핸들러
    const handleSearchDtChange = (startId : string , startValue: string , endId : string , endValue: string) => {
        setFormData((prev) => ({ ...prev, [startId]: startValue , [endId]: endValue })); // 선택된 값 업데이트
    };

    // CHECKBOX 변경시
    const handleCheckBoxChange = (id : string , value: string) => {
        setFormData((prev) => ({ ...prev, [id]: value })); // 선택된 값 업데이트
    };
     
    // 초기화 버튼 클릭시
    const onSearchReset = async () => {  
        setFormData({
            searchOrderBy : "REG_DT"
          , pageUnit : 10
        });
    }

    const onSearch = async (pageNo:number) => {  
        // await setFormData({...searchParams , currentPageNo: pageNo });

        const fetchData = async () => {
            searchParams.currentPageNo = pageNo;
            await dispatch(getContactUsListAsync.request(searchParams));
        };

        fetchData();
    }

    //등록 버튼 클릭시
    const onWriter = async () => {  
        navigate('/contactUsWrite')
    }

    //등록 버튼 클릭시
    const onModify = async (inqSn : string) => {  
        navigate('/ContactUsDetail/'+inqSn);
    }

    return (
        <div className="wrapper">
            <SideLayout />
            {/* leftside-menu */}
            <div className='content-page'>
                <Header />
                <ContentLayout title='문의사항 조회'>
                    {/* Content */}
                    <div className="row">
                        <div className="container">
                            {/* search */}
                            <div className='row'>
                                <div className='dash-wrap-cont dash-info-wrap'>
                                    <div className="row mb-3">
                                        <div className="col-1 col-form-label">
                                            게시 여부
                                        </div>
                                        <div className="col-5">
                                            <CheckBoxForm 
                                                objectId = "searchPstgSttsList"
                                                cdGrpId  = "RLS_STNG_CD"
                                                onChange={handleCheckBoxChange}
                                            />
                                        </div>
                                        <div className="col-1 col-form-label">
                                            답변 여부
                                        </div>
                                        <div className="col-5">
                                            <CheckBoxForm 
                                                objectId = "searchInqSttsList"
                                                cdGrpId  = "INQ_STTS_CD"
                                                onChange={handleCheckBoxChange}
                                            />
                                        </div>
                                    </div>  
                                    
                                    <div className="row mb-3">
                                        <div className="col-1 col-form-label">
                                            문의 항목
                                        </div>
                                        <div className="col-11">
                                            <CheckBoxForm 
                                                objectId = "searchTypeList"
                                                cdGrpId  = "INQ_TYPE_CD" 
                                                onChange={handleCheckBoxChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <div className="col-1 col-form-label">
                                            검색어
                                        </div>  
                                        <div className="col-3">
                                            <select className="form-select form-select-sm" id="searchKeywordType" name="searchKeywordType" aria-label="Default select example" onChange={handleChange}>
                                                <option value="" selected>전체</option>
                                                <option value="TIT">제목</option>
                                                <option value="CN">내용</option>
                                            </select>
                                        </div>
                                        <div className="col-8">
                                            <input className="form-control form-control-sm" type="text" placeholder="제목, 내용 으로검색" aria-label=".form-control-sm example" id="searchKeyword" name="searchKeyword" onChange={handleChange}/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <StartEndDt title="조회기간" startObjectId="searchStartDt"  endObjectId = "searchEndDt" onChange={handleSearchDtChange} />
                                    </div>
                                    <div className="d-flex justify-content-center gap-2 mt-3">
                                        <button type="button" className="btn btn-secondary btn-sm" onClick={onSearchReset}>초기화</button>
                                        <button type="button" className="btn btn-primary btn-sm" onClick={() => onSearch(1)}>검색</button>
                                    </div>
                                </div>
                            </div>
                            {/* board-controls */}

                            <div className="row">
                                <div className="display-count mb-1 d-flex justify-content-between">
                                    <span>총 게시물 : {totalCnt}</span>
                                    <span>페이지 : {currentPageNo}/{totalIndexCount}</span>
                                    <button type="button" className="btn btn-secondary btn-sm" onClick={onWriter} style={{display:"none"}}>문의사항 등록</button>
                                </div>
                                <table className="table table-bordered table-hover">
                                    <thead>
                                        <th className='text-center'>번호</th>
                                        <th className='text-center'>제목</th>
                                        <th className='text-center'>문의 항목</th>
                                        <th className='text-center'>답변 여부</th>
                                        <th className='text-center'>작성자</th>
                                        <th className='text-center'>등록일</th>
                                        <th className='text-center'>첨부파일</th>
                                    </thead>
                                    <tbody>
                                        {resultList}
                                    </tbody>
                                </table>
                                { supportInfo.data && supportInfo.data.meta  ? ( 
                                        <Pagination  pageingDto={supportInfo.data.meta} onClick={onSearch} />        
                                    ) : ( <span> </span> )
                                }
                            </div>
                        </div>
                    </div>
                </ContentLayout>
            </div>
        </div>
    )
};

export default ContactUsList;