import axios from 'axios';
import config from '../config';
import { ErrorResponse, SuccessResponse } from '../types/Response';
import accessTokenPortalApi from './api';


export async function overview(): Promise<SuccessResponse<any> | ErrorResponse> {
    try {
        const response = await accessTokenPortalApi.get<SuccessResponse<any> | ErrorResponse>(
            `/stat/overview`,
        );

        if (response.data.status === 'success') {
            return response.data;
        } else {
            return {
                status: 'error',
                error: {
                    code: 'API_CALL_FAILED',
                    message: 'Failed to fetch overview',
                },
            };
        }
    } catch (error) {
        console.error('Error fetching overview:', error);
        return {
            status: 'error',
            error: {
                code: 'NETWORK_ERROR',
                message: 'An error occurred while fetching the overview',
            },
        };
    }
}

export async function signup(): Promise<SuccessResponse<any> | ErrorResponse> {
    try {
        const response = await accessTokenPortalApi.get<SuccessResponse<any> | ErrorResponse>(
            `/stat/signup`,
        );

        if (response.data.status === 'success') {
            return response.data;
        } else {
            return {
                status: 'error',
                error: {
                    code: 'API_CALL_FAILED',
                    message: 'Failed to fetch signup',
                },
            };
        }
    } catch (error) {
        console.error('Error fetching signup:', error);
        return {
            status: 'error',
            error: {
                code: 'NETWORK_ERROR',
                message: 'An error occurred while fetching the signup',
            },
        };
    }
}

export async function paymnt(): Promise<SuccessResponse<any> | ErrorResponse> {
    try {
        const response = await accessTokenPortalApi.get<SuccessResponse<any> | ErrorResponse>(
            `/stat/purchases`,
        );

        if (response.data.status === 'success') {
            return response.data;
        } else {
            return {
                status: 'error',
                error: {
                    code: 'API_CALL_FAILED',
                    message: 'Failed to fetch purchases',
                },
            };
        }
    } catch (error) {
        console.error('Error fetching purchases:', error);
        return {
            status: 'error',
            error: {
                code: 'NETWORK_ERROR',
                message: 'An error occurred while fetching the purchases',
            },
        };
    }
}