import { createReducer } from 'typesafe-actions';
import { CommonState, CommonAction } from './types';
import { getCommCdListAsync , deleteFileAsync  } from './actions';
import { asyncState, createAsyncReducer, transformToArray } from '../lib/reducerUtils';
import { transform } from 'typescript';
import { TransformStream } from 'stream/web';

const initialState: CommonState = {
  data: asyncState.initial(),
  delFile:asyncState.initial()
};
const commonReducer  = createReducer<CommonState, CommonAction>(initialState)
.handleAction(
  getCommCdListAsync.request, // 요청 액션
  (state) => ({
    ...state,
    loading: true,
    error: null,// 로딩 상태로 변경
  })
)
.handleAction(
  getCommCdListAsync.success, // 성공 액션
  (state, action) => ({
    ...state,
    data: asyncState.success(action.payload),
    loading: false,
    error: null,// 성공 상태로 업데이트
  })
)
.handleAction(
  getCommCdListAsync.failure, // 실패 액션
  (state, action) => ({
    ...state,
    data: asyncState.error(action.payload), // 에러 상태로 업데이트
    loading: false,
    error: action.payload,
  })
)
.handleAction(
  deleteFileAsync.request, // 요청 액션
  (state) => ({
    ...state,
    loading: true,
    error: null,// 로딩 상태로 변경
  })
)
.handleAction(
  deleteFileAsync.success, // 성공 액션
  (state, action) => ({
    ...state,
    delFile: asyncState.success(action.payload),
    loading: false,
    error: null,// 성공 상태로 업데이트
  })
)
.handleAction(
  deleteFileAsync.failure, // 실패 액션
  (state, action) => ({
    ...state,
    data: asyncState.error(action.payload), // 에러 상태로 업데이트
    loading: false,
    error: action.payload,
  })
)


export default commonReducer ;