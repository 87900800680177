import { FC } from "react";
import { Link } from 'react-router-dom';

const NotFound: FC = () => {
    return (
        <div className='notfound'>
            <h1>페이지를 찾을 수 없습니다</h1>
        </div>
    )
}

export default NotFound;