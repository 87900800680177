import { FC, useEffect,useState } from "react";
import { format } from 'date-fns';

interface SearchFormProps {
    title : string
    startObjectId : string;
    startObjectVl? : string;
    endObjectId : string;
    endObjectVl? : string;
    eventType? : string
    onChange?: (startId : string , startValue: string , endId : string , endValue: string) => void; // onChange prop 추가
}

const StartEndDt:FC<SearchFormProps> = ({ title , startObjectId, startObjectVl , endObjectId, endObjectVl , eventType , onChange }) => {

    if (!startObjectVl) {
        startObjectVl = "";
    }

    if (!endObjectVl) {
        endObjectVl = "";
    }
    
    const [startDt, setStartDt] = useState(startObjectVl);
    const [endDt  , setEndDt] = useState(endObjectVl);
    
    useEffect(() => {
        
        if (startObjectVl) {
            setStartDt(startObjectVl);
        }
        
        if (endObjectVl) {
            setEndDt(endObjectVl);
        }

      },[startObjectVl,endObjectVl]);

    useEffect(() => {
        if (onChange) {
            onChange(startObjectId , startDt , endObjectId , endDt); // 선택된 값 전달
        } 
        
      },[startDt , endDt]);

    const handleStartChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setStartDt(e.target.value);
    };

    const handleEndChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEndDt(e.target.value);
    };

    const getCurrentDate = () => format(new Date(), 'yyyy-MM-dd');
    // 날짜를 조정하는 함수
    const getDay = (days: number) => format(new Date(Date.now() + days * 24 * 60 * 60 * 1000), 'yyyy-MM-dd');

    const getYear = (year : number) => {
        const currentDate = new Date();
        currentDate.setFullYear(currentDate.getFullYear() + year); // 현재 월에 6개월 추가
        return format(currentDate, 'yyyy-MM-dd');
    };

    const getMonths = (month : number) => {
        const currentDate = new Date();
        currentDate.setMonth(currentDate.getMonth() + month); // 현재 월에 6개월 추가
        return format(currentDate, 'yyyy-MM-dd');
    };

    // 조회기간 버튼 클릭 핸들러
    const handlePeriodChange = (period: string) => {
        if (eventType && eventType == 'INSERT') {
            setStartDt(getCurrentDate());
        }
        
        switch (period) {
            case 'dataAll':
                if (eventType && eventType == 'INSERT') {
                    setEndDt("9999-12-31");
                } else {
                    setStartDt("");
                    setEndDt("");
                }
                
                break;
            case 'year':
                if (eventType && eventType == 'INSERT') {
                    setEndDt(getYear(+1));
                } else {
                    setStartDt(getYear(-1));
                    setEndDt(getCurrentDate());
                }

                break;
            case 'month6':
                if (eventType && eventType == 'INSERT') {
                    setEndDt(getMonths(+6));
                } else {
                    setStartDt(getMonths(-6));
                    setEndDt(getCurrentDate());
                }

                break;
            case 'month1':
                if (eventType && eventType == 'INSERT') {
                    setEndDt(getMonths(+1));
                } else {
                    setStartDt(getMonths(-1));
                    setEndDt(getCurrentDate());
                }

                break;
            case 'week':
                if (eventType && eventType == 'INSERT') {
                    setEndDt(getDay(+7));
                } else {
                    setStartDt(getDay(-7));
                    setEndDt(getCurrentDate());
                }
                
                break;
            default:
                break;
        }
    };

    let titleHtml = <span></span>;
    if (title != "") {
        titleHtml = <div className="col-1 col-form-label">{title}</div>;
    }


    
    return (
    <div className="row">
        {titleHtml}
        <div className="col-2">
            <input className="form-control form-control-sm" type="date" id={startObjectId} name={startObjectId} placeholder="" aria-label="" value={startDt} onChange={handleStartChange} />
        </div>
        <div className="col-2">
            <input className="form-control form-control-sm" type="date" id={endObjectId} name={endObjectId} placeholder="" aria-label="" value={endDt} onChange={handleEndChange} />
        </div>
        
        <div className="col-7" style={{paddingTop:"4px"}}>
            <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="flexRadioDefault" id="dataAll" onChange={() => handlePeriodChange('dataAll')} />
                <label className="form-check-label" htmlFor="dataAll" style={{cursor:"pointer"}}>
                    전체
                </label>
            </div>
            <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="flexRadioDefault" id="Year" onChange={() => handlePeriodChange('year')} />
                <label className="form-check-label" htmlFor="Year" style={{cursor:"pointer"}}>
                    1년
                </label>
            </div>
            <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="flexRadioDefault" id="Month6" onChange={() => handlePeriodChange('month6')} />
                <label className="form-check-label" htmlFor="Month6" style={{cursor:"pointer"}}>
                    6개월
                </label>
            </div>
            <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="flexRadioDefault" id="Month1" onChange={() => handlePeriodChange('month1')} />
                <label className="form-check-label" htmlFor="Month1" style={{cursor:"pointer"}}>
                    1개월
                </label>
            </div>
            <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="flexRadioDefault" id="Week" onChange={() => handlePeriodChange('week')} />
                <label className="form-check-label" htmlFor="Week" style={{cursor:"pointer"}}>
                    1주일
                </label>
            </div>
        </div>
    </div>
    )
}

export default StartEndDt;