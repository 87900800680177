import { createReducer } from 'typesafe-actions';
import { UserGroupState, UserAction } from './types';
import { deleteUserGroupMemberAsync, findUserGroupListAsync, findUserGroupMemberListAsync, updateGroupUserRoleAsync, updateUserGroupMemberAsync } from './actions';
import { asyncState, createAsyncReducer, transformToArray } from '../lib/reducerUtils';

const initialState: UserGroupState = {
  userGroupList: asyncState.initial(),
  userGroupMemberList: asyncState.initial(),
  selectedUser: asyncState.initial(),
  data: asyncState.initial(),
};

const user = createReducer<UserGroupState, UserAction>(initialState)
.handleAction(
  findUserGroupListAsync.request,
  (state) =>({
    ...state,
    loading: true,
    error: null,
    userGroupList: asyncState.load(),
  })
)
.handleAction(
  findUserGroupListAsync.success,
  (state, action) => ({
    ...state,
    userGroupList: asyncState.success(action.payload),  // 목록 성공
    loading: false,
    error: null,
  })
)
.handleAction(
  findUserGroupListAsync.failure,
  (state, action) => ({
    ...state,
    userGroupList: asyncState.error(action.payload),  // 목록 실패
    loading: false,
    error: action.payload,
  })
).handleAction(
  findUserGroupMemberListAsync.request,
  (state) =>({
    ...state,
    loading: true,
    error: null,
    userGroupMemberList: asyncState.load(),
  })
)
.handleAction(
  findUserGroupMemberListAsync.success,
  (state, action) => ({
    ...state,
    userGroupMemberList: asyncState.success(action.payload),  // 목록 성공
    loading: false,
    error: null,
  })
)
.handleAction(
  findUserGroupMemberListAsync.failure,
  (state, action) => ({
    ...state,
    userGroupMemberList: asyncState.error(action.payload),  // 목록 실패
    loading: false,
    error: action.payload,
  })
).handleAction(
  updateUserGroupMemberAsync.request,
  (state) =>({
    ...state,
    loading: true,
    error: null,
    data: asyncState.load(),
  })
)
.handleAction(
  updateUserGroupMemberAsync.success,
  (state, action) => ({
    ...state,
    data: asyncState.success(action.payload),  // 목록 성공
    loading: false,
    error: null,
  })
)
.handleAction(
  updateUserGroupMemberAsync.failure,
  (state, action) => ({
    ...state,
    data: asyncState.error(action.payload),  // 목록 실패
    loading: false,
    error: action.payload,
  })
).handleAction(
  deleteUserGroupMemberAsync.request,
  (state) =>({
    ...state,
    loading: true,
    error: null,
    data: asyncState.load(),
  })
)
.handleAction(
  deleteUserGroupMemberAsync.success,
  (state, action) => ({
    ...state,
    data: asyncState.success(action.payload),  // 목록 성공
    loading: false,
    error: null,
  })
)
.handleAction(
  deleteUserGroupMemberAsync.failure,
  (state, action) => ({
    ...state,
    data: asyncState.error(action.payload),  // 목록 실패
    loading: false,
    error: action.payload,
  })
)
.handleAction(
  updateGroupUserRoleAsync.request,
  (state) =>({
    ...state,
    loading: true,
    error: null,
  })
)
.handleAction(
  updateGroupUserRoleAsync.success,
  (state, action) => ({
    ...state,
    loading: false,
    error: null,
  })
)
.handleAction(
  updateGroupUserRoleAsync.failure,
  (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  })
);

export default user;