import { createReducer } from 'typesafe-actions';
import { UserState, UserAction } from './types';
import { findCntnListAsync } from './actions';
import { asyncState, createAsyncReducer, transformToArray } from '../lib/reducerUtils';

const initialState: UserState = {
  data: asyncState.initial(),
};

const user = createReducer<UserState, UserAction>(initialState)
.handleAction(
  findCntnListAsync.request,
  (state) =>({
    ...state,
    loading: true,
    error: null,
    userList: asyncState.load(),
  })
).handleAction(
  findCntnListAsync.success,
  (state, action) => ({
    ...state,
    data: asyncState.success(action.payload),  // 목록 성공
    loading: false,
    error: null,
  })
).handleAction(
  findCntnListAsync.failure,
  (state, action) => ({
    ...state,
    data: asyncState.error(action.payload),  // 목록 실패
    loading: false,
    error: action.payload,
  })
);

export default user;