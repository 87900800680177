import { FC, useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../modules";
import { useNavigate } from 'react-router-dom';
import SideLayout from '../../containers/sidemenu';
import Header from '../../containers/header';
import ContentLayout from '../../containers/contentLayout';
import Pagination from '../../components/Pagination'
import CheckBoxForm from "../../components/CheckBox";
import StartEndDt from "../../components/StartEndDt";
import '../../styles/components/icon.scss';
import { SearchSupportDto } from '../../types/support/SearchSupportDto';
import { PotalCommDto } from '../../types/PotalCommDto';
import { getFaqListAsync } from '../../modules/support';

const FaqList: FC = () => {

    // 결과값 리턴
    const dispatch = useDispatch();
    const supportInfo  = useSelector((state:RootState) => state.support.faqList);

    const navigate = useNavigate();

    // 변수 선언
    const [pageingDto, setPageing] = useState<PotalCommDto>({
        currentPageNo : 1
      , pageUnit : 10
      , pageSize : 10
      , firstIndex : 0
      , totalCnt   : 0
      , firstPageNo : 0
      , lastPageNo : 0
      , prevPageNo : 0
      , nextPageNo : 0
      , listTotalCnt : 0
    });
    const [searchParams, setFormData] = useState<SearchSupportDto>({
        searchOrderBy : "REG_DT"
      , currentPageNo : 1
      , pageUnit : 10
    });

    // 초기 접근시 조회
    useEffect(() => {
        onSearch(1);
    },[]);

    // SELECT BOX 변경시 
    const selecthandleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value , currentPageNo: 1 }));
    };

    // INPUT BOX 변경시 
    const inputHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value , currentPageNo: 1}));
    };

    // 조회기간 버튼 클릭 핸들러
    const handleSearchDtChange = (startId : string , startValue: string , endId : string , endValue: string) => {
        setFormData((prev) => ({ ...prev, [startId]: startValue , [endId]: endValue })); // 선택된 값 업데이트
    };

    // CHECKBOX 변경시
    const handleCheckBoxChange = (id : string , value: string) => {
        setFormData((prev) => ({ ...prev, [id]: value })); // 선택된 값 업데이트
    };

    // 초기화 버튼 클릭시
    const onSearchReset = async () => {  
        setFormData({
            searchOrderBy : "REG_DT"
          , pageUnit : 10
        });
    }

    // 조회 버튼 클릭시
    const onSearch = async (pageNo:number) => {  
      
        const fetchData = async () => {
            searchParams.currentPageNo = pageNo;

            await dispatch(getFaqListAsync.request(searchParams));
        };

        fetchData();
    }

    //등록 버튼 클릭시
    const onWriter = async () => {  
        navigate('/faqWrite')
    }

    //등록 버튼 클릭시
    const onModify = async (faqSn : string) => {  
        navigate('/faqWrite/'+faqSn);
    }

    // 조회 목록 HTML 생성
    var resultList = [];
    var listTotalCnt = 0;
    var totalCnt = 0;
    var pageUnit = 10;
    var currentPageNo = 1;
    var totalIndexCount = 0;
    // console.log('리턴 결과:', JSON.stringify(supportInfo, null, 2));
    if (supportInfo.data ) {
        if (supportInfo.data.meta) {
            totalCnt = Number(supportInfo.data.meta.totalCnt);
            pageUnit = supportInfo.data.meta.pageUnit;
            currentPageNo = supportInfo.data.meta.currentPageNo;
            totalIndexCount = supportInfo.data.meta.totalIndexCount;
        }
        
        if (supportInfo.data.data) {
           listTotalCnt = Number(supportInfo.data.data.length);

           if (supportInfo.data.data.length == 0) {
                resultList.push(
                <tr key={"tr_0"}  style={{ height: "54px" }}>
                    <td colSpan={6} style={{ textAlign: "center" }}>조회된 결과가 없습니다.</td>
                </tr>)
           } else {
            for (var i=0;i<supportInfo.data.data.length;i++) {
                var result = supportInfo.data.data[i];
                var listNo = totalCnt - (pageUnit * (currentPageNo -1) + i);


                resultList.push(
                    <tr key={"tr_"+listNo}  style={{ height: "54px" }}>
                        <td style={{ textAlign: "center" }}>{listNo}</td>
                        <td>{result.faqTypeNm}</td>
                        <td><button key={"faqTtl_"+listNo} className='link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover' value={result.faqSn} onClick={(e) => onModify(e.currentTarget.value)}>
                                {result.faqTtl}
                            </button>
                        </td>
                        <td style={{ textAlign: "center" }}>{result.pstgSttsNm}</td>
                        <td style={{ textAlign: "center" }}>{result.regDt}</td>
                        <td style={{ textAlign: "center" }}>{result.regUserNm}</td>
                    </tr>)
            }
           }

            
        }

         // console.log('리턴 결과:', JSON.stringify(supportInfo, null, 2));
    }



    return (
        <div className="wrapper">
            <SideLayout />
            {/* leftside-menu */}
            <div className='content-page'>
                <Header />
                <ContentLayout title='FAQ 조회'>
                    {/* Content */}

                    <div className='row'>
                        <div className='dash-wrap-cont dash-info-wrap'>
                            <div className="row mb-3">
                                <div className="col-1 col-form-label">
                                    게시 여부
                                </div>
                                <div className="col-11">
                                    <CheckBoxForm 
                                        objectId = "searchPstgSttsList"
                                        cdGrpId  = "PSTG_STTS_CD"
                                        onChange={handleCheckBoxChange}
                                    />
                                </div>
                            </div>  
                            
                            <div className="row mb-3">
                                <div className="col-1 col-form-label">
                                    구분
                                </div>
                                <div className="col-11">
                                    <CheckBoxForm 
                                        objectId = "searchTypeList"
                                        cdGrpId  = "FAQ_TYPE_CD" 
                                        onChange={handleCheckBoxChange}
                                    />
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-1 col-form-label">
                                    검색어
                                </div>  
                                <div className="col-3">
                                    <select className="form-select form-select-sm" id="searchKeywordType" name="searchKeywordType" aria-label="Default select example" onChange={selecthandleChange}>
                                        <option value="" selected>전체</option>
                                        <option value="TIT">제목</option>
                                        <option value="CN">내용</option>
                                    </select>
                                </div>
                                <div className="col-8">
                                    <input className="form-control form-control-sm" type="text" placeholder="제목, 내용 으로검색" aria-label=".form-control-sm example" id="searchKeyword" name="searchKeyword" onChange={inputHandleChange}/>
                                </div>
                            </div>
                            <div className="row">
                                <StartEndDt title="등록기간" startObjectId="searchStartDt"  endObjectId = "searchEndDt" onChange={handleSearchDtChange} />
                            </div>
                            <div className="d-flex justify-content-center gap-2 mt-3">
                                <button type="button" className="btn btn-secondary btn-sm" onClick={onSearchReset}>초기화</button>
                                <button type="button" className="btn btn-primary btn-sm" onClick={() => onSearch(1)}>검색</button>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="display-count mb-1 d-flex justify-content-between">
                            <span>총 게시물 : {totalCnt}</span>
                            <span>페이지 : {currentPageNo}/{totalIndexCount}</span>
                            <button type="button" className="btn btn-secondary btn-sm" onClick={onWriter}>FAQ 등록</button>
                        </div>
                        <table className="table table-bordered table-hover">
                            <colgroup>
                                <col style={{ width: '120px' }} />
                                <col style={{ width: '200px' }} />
                                <col style={{ width: 'auto' }} />
                                <col style={{ width: '200px' }} />
                                <col style={{ width: '200px' }} />
                                <col style={{ width: '200px' }} />
                            </colgroup>
                            <thead>
                                <th className='text-center'>번호</th>
                                <th className='text-center'>구분</th>
                                <th className='text-center'>제목</th>
                                <th className='text-center'>게시여부</th>
                                <th className='text-center'>등록일</th>
                                <th className='text-center'>작성자</th>
                            </thead>
                            <tbody>
                                {resultList}
                            </tbody>
                        </table>
                        { supportInfo.data && supportInfo.data.meta  ? ( 
                                <Pagination  pageingDto={supportInfo.data.meta} onClick={onSearch} />        
                            ) : ( <span> </span> )
                        }
                    </div>
                </ContentLayout>
            </div>
        </div>
    )
};

export default FaqList;
