import axios from 'axios';
import { SearchSupportDto } from '../types/support/SearchSupportDto';
import {PotalCommDto} from '../types/PotalCommDto';
import config from '../config';
import accessTokenPortalApi from './api';
import {ApiResponseList , ApiResponse} from '../types/common/CommonApi';

export async function getComboList(searchCommonDto: any): Promise<any | null> {
  try {
    const response = await axios.get<Promise<ApiResponseList> | null>(
      `${config.portalApiUrl}/comm/combo`,
      {params : searchCommonDto }
    );

    return response.data;
  } catch (error) {
    console.error('Failed to create user:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: 'Failed to create user',
      },
    };
  }
};

export async function getFileDownLoad(fileType : string , groupId : number , fileId : number): Promise<void | null> {

  window.location.href = `${config.portalApiUrl}/common/fileDownload?fileType=${fileType}&groupId=${groupId}&fileId=${fileId}`;
  
}


export async function deleteFile(searchCommonDto: any): Promise<any | null> {
  try {
    const response = await accessTokenPortalApi.delete<Promise<ApiResponseList> | null>(
      `/common/deleteFile`,
      {
        params : searchCommonDto 
      }
    );

    // console.log('삭제 결과 api:', JSON.stringify(response.data, null, 2));

   //  const response = await axios.post(`${config.portalApiUrl}/notices`, formData);
    return response.data;
  } catch (error) {
    // console.log('저장 결과 error api:', JSON.stringify(error, null, 2));
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: 'Failed to delete file',
      },
    };
  }
};
