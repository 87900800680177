import { FC, useEffect, useRef, useState } from "react";
import SideLayout from '../../containers/sidemenu';
import Header from '../../containers/header';
import ContentLayout from '../../containers/contentLayout';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../modules";
import { format } from 'date-fns';
import Pagination from "../../components/Pagination";
import { SelectUserCntnLogDto, UserCntnLog } from "../../types/CntnDto";
import { findCntnListAsync } from "../../modules/cntn";

const CntnLog: FC = () => {
    const [selectedPeriod, setSelectedPeriod] = useState<string>("all");
    const dispatch = useDispatch();
    const cntnList = useSelector((state: RootState) => state.cntn.data);
    const [searchParams, setSearchParams] = useState<SelectUserCntnLogDto>({
        searchType: 'user_id', // 검색어 조건
        searchKeyword: '',
        currentPageNo: 1,
        searchOrder: '',
        fromDate: '',   // 시작일자
        toDate: '', // 종료일자
    });

    // 현재 날짜를 가져오기 위한 함수
    const getCurrentDate = () => format(new Date(), 'yyyy-MM-dd');

    // 조회기간 버튼 클릭 핸들러
    // 기간 설정 핸들러
    const handlePeriodChange = (period: string) => {
        let fromDate = '', toDate = getCurrentDate();
        setSelectedPeriod(period);
        switch (period) {
            case 'year':
                fromDate = format(new Date(Date.now() - 365 * 24 * 60 * 60 * 1000), 'yyyy-MM-dd');
                break;
            case 'sixMonths':
                fromDate = format(new Date(Date.now() - 183 * 24 * 60 * 60 * 1000), 'yyyy-MM-dd');
                break;
            case 'oneMonth':
                fromDate = format(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000), 'yyyy-MM-dd');
                break;
            case 'oneWeek':
                fromDate = format(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000), 'yyyy-MM-dd');
                break;
            default:
                fromDate = '';
                toDate = '';
        }

        // 상태 업데이트
        setSearchParams(prev => ({
            ...prev,
            fromDate,
            toDate,
        }));
    };

    // 초기화 버튼 클릭 핸들러
    const handleReset = () => {
        // 검색 조건 초기화
        setSearchParams({
            searchType: '',
            searchKeyword: '',
            currentPageNo: 1,
            searchOrder: '',
            fromDate: '',
            toDate: '',
        });
    };

    // 검색어와 권한 변경 핸들러
    const handleInputChange = (field: keyof SelectUserCntnLogDto, value: string) => {
        setSearchParams(prev => ({
            ...prev,
            [field]: value,
        }));
    };

    const [isdropOpen, setIsdropOpen] = useState(false);
    const handleDrop = () => {
        setIsdropOpen(!isdropOpen);
    }

    const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onSearch(1);
    };

    const onSearch = async (pageNo: number) => {
        setSearchParams({ ...searchParams, currentPageNo: pageNo })
        dispatch(findCntnListAsync.request({
            ...searchParams,
            currentPageNo: pageNo,
        }));
    }

    useEffect(() => {
        dispatch(findCntnListAsync.request(searchParams));
    }, [dispatch]);

    return (
        <div className="wrapper">
            <SideLayout />
            {/* leftside-menu */}
            <div className='content-page'>
                <Header />
                <ContentLayout title='회원 접속 관리'>
                    {/* 검색 폼 */}
                    <div className='row'>
                        <form onSubmit={handleSearch}>
                            <div className='dash-wrap-cont dash-info-wrap'>

                                {/* 검색어 입력 필드 */}
                                <div className="row mb-3">
                                    <div className="col-1 col-form-label">
                                        검색어
                                    </div>
                                    <div className="col-3">
                                        <select
                                            className="form-select form-select-sm"
                                            aria-label="Default select example"
                                            value={searchParams.searchType}
                                            onChange={(e) => handleInputChange('searchType', e.target.value)}
                                        >
                                            <option value="user_id">아이디</option>
                                            <option value="user_ip">IP</option>
                                        </select>
                                    </div>
                                    <div className="col-8">
                                        <input
                                            className="form-control form-control-sm"
                                            type="text"
                                            placeholder="아이디, IP로검색"
                                            aria-label=".form-control-sm example"
                                            value={searchParams.searchKeyword}
                                            onChange={(e) => handleInputChange('searchKeyword', e.target.value)}
                                        />
                                    </div>
                                </div>
                                {/* 조회 기간 필드 */}
                                <div className="row">
                                    <div className="col-1 col-form-label">
                                        조회기간
                                    </div>
                                    <div className="col-2">
                                        <input
                                            className="form-control form-control-sm"
                                            type="date"
                                            value={searchParams.fromDate || ''}
                                            onChange={(e) => handleInputChange('fromDate', e.target.value)}
                                            placeholder=""
                                            aria-label="Start Date"
                                        />
                                    </div>
                                    <div className="col-2">
                                        <input
                                            className="form-control form-control-sm"
                                            type="date"
                                            value={searchParams.toDate || ''}
                                            onChange={(e) => handleInputChange('toDate', e.target.value)}
                                            placeholder=""
                                            aria-label="End Date"
                                        />
                                    </div>
                                    {/* 조회기간 라디오 버튼들 */}
                                    <div className="col-7">
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id="dataAll"
                                                checked={selectedPeriod === 'all'}
                                                onChange={() => handlePeriodChange('all')}
                                            />
                                            <label className="form-check-label" htmlFor="dataAll">
                                                전체
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id="Year"
                                                checked={selectedPeriod === 'year'}
                                                onChange={() => handlePeriodChange('year')}
                                            />
                                            <label className="form-check-label" htmlFor="Year">
                                                1년
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id="Month6"
                                                checked={selectedPeriod === 'sixMonths'}
                                                onChange={() => handlePeriodChange('sixMonths')}
                                            />
                                            <label className="form-check-label" htmlFor="Month6">
                                                6개월
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id="Month1"
                                                checked={selectedPeriod === 'oneMonth'}
                                                onChange={() => handlePeriodChange('oneMonth')}
                                            />
                                            <label className="form-check-label" htmlFor="Month1">
                                                1개월
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id="Week"
                                                checked={selectedPeriod === 'oneWeek'}
                                                onChange={() => handlePeriodChange('oneWeek')}
                                            />
                                            <label className="form-check-label" htmlFor="Week">
                                                1주일
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center gap-2 mt-3">
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-sm"
                                        onClick={handleReset}
                                    >
                                        초기화
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-sm"
                                    >
                                        검색
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    {/* 유저 목록 테이블 */}
                    <div className="row">
                        <div className="display-count mb-1 d-flex justify-content-between">
                            <span>총 접속 건수 : {cntnList.data?.meta?.totalCnt ? cntnList.data.meta.totalCnt.toLocaleString() : 0}</span>
                        </div>
                        <table className="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th className='text-center'>번호</th>
                                    <th className='text-center'>사용자 아이디</th>
                                    <th className='text-center'>IP</th>
                                    <th className='text-center'>로그인 시작</th>
                                </tr>
                            </thead>
                            <tbody>
                                {cntnList.loading ? (
                                    <tr>
                                        <td className="text-center"> loading...</td>
                                    </tr>
                                ) : cntnList.data?.data?.length > 0 ? (
                                    cntnList.data.data.map((cntn: UserCntnLog, index: number) => (
                                        <tr key={index}>
                                            <td className='text-center'>
                                                {
                                                    cntnList.data.meta.totalCnt -
                                                    (cntnList.data.meta.currentPageNo - 1) * cntnList.data.meta.pageSize - index
                                                }
                                            </td>
                                            <td className='text-center'>{cntn.userId}</td>
                                            <td className='text-center'>{cntn.ipAddr}</td>
                                            <td className='text-center'>{new Date(cntn.loginDt!).toLocaleString()}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={6} className='text-center'>데이터가 없습니다.</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        {cntnList.data && cntnList.data.meta ? (
                            <Pagination key="1" pageingDto={cntnList.data.meta} onClick={onSearch} />
                        ) : (<span></span>)
                        }
                    </div>
                </ContentLayout>
            </div>
        </div>
    )
}
export default CntnLog;