import { FC, Fragment, useEffect, useRef, useState } from "react";
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import InputGroup from 'react-bootstrap/InputGroup';
import SideLayout from '../../containers/sidemenu';
import Header from '../../containers/header';
import ContentLayout from '../../containers/contentLayout';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './editor.scss';
import { useDispatch, useSelector } from "react-redux";
import { createPrdctAsync, findPrdctItemListAsync, findPrdctListAsync } from "../../modules/prdct";
import { RootState } from "../../modules";
import { CreatePrdctDto, PrdctDetailDto, PrdctDetailExtended, PrdctDto } from "../../types/PrdctDto";


const Prdct: FC = () => {
    const [key, setKey] = useState<string | undefined>('GEO_NIC');
    const [currentOgnzName, setCurrentOgnzName] = useState<string>("GEO_NIC");
    const dispatch = useDispatch();
    const prdtcListState = useSelector((state: RootState) => state.prdct.prdctList.data);
    const prdtcItemListState = useSelector((state: RootState) => state.prdct.prdctItemList.data);

    const [prdcts, setPrdcts] = useState<PrdctDto[]>([]);                       // 비용 정보
    const [prdctItems, setPrdctItems] = useState<PrdctDetailExtended[]>([]);    // 상세 구성 체크박스
    const [newOptItems, setNewOptItems] = useState<PrdctDetailExtended[]>([]);
    const [newDatItems, setNewDatItems] = useState<PrdctDetailExtended[]>([]);
    const [optItems, setOptItems] = useState<PrdctDetailExtended[]>([]);
    const [datItems, setDatItems] = useState<PrdctDetailExtended[]>([]);

    const createPrdctState = useSelector((state: RootState) => state.prdct.data);
    const prevLoadingRef = useRef<boolean>(false);

    // New refs to store the initial state
    const initialPrdcts = useRef<PrdctDto[]>([]);
    const initialPrdctItems = useRef<PrdctDetailExtended[]>([]);

    // 제품 그룹 ID와 라벨 매핑
    const prdctIdToLabel: { [key: string]: string } = {
        'PRDCT_GRP001': 'Basic',
        'PRDCT_GRP002': 'Standard',
        'PRDCT_GRP003': 'Premium',
        'PRDCT_GRP004': 'Custom'
    };

    // 비용 정보 항목 추가 핸들러
    const handleAddOptItem = () => {
        const productIds = Object.keys(prdctIdToLabel);
        const newOptItem = {
            itemName: "새 항목",
            sltnId: currentOgnzName,
            monthPrice: 0,
            yearPrice: 0,
            description: "",
            prdctIds: productIds,
            itemValues: productIds.map(() => 'UNCHECK'),
            itemType: "OPT",
            regDt: new Date(),
            regUserId: localStorage.getItem('userId'),
            modDt: new Date(),
            modUserId: localStorage.getItem('userId'),
        };
        setNewOptItems([...newOptItems, newOptItem]);
    };

    // 선택 추가 버튼 클릭
    const handleAddDatItem = () => {
        const newDatItem: PrdctDetailExtended = {
            sltnId: currentOgnzName,
            itemName: "새 데이터 항목",
            prdctIds: Object.keys(prdctIdToLabel), // Include all product IDs by default
            itemValues: [],
            itemType: "DAT",
        };
        setNewDatItems([...newDatItems, newDatItem]);
    };

    // 탭 변경
    const handleSelect = (selectedKey: string) => {
        setOptItems([]);
        setDatItems([]);
        setNewOptItems([]);
        setNewDatItems([]);
        handleCallList(selectedKey);
        setKey(selectedKey);
    };

    // 목록 불러오기
    const handleCallList = (ognzName: string) => {
        dispatch(findPrdctListAsync.request(ognzName));
        setCurrentOgnzName(ognzName);
    };


    const handleProductChange = (index: number, field: keyof PrdctDto, value: any) => {
        const updatedProducts = [...prdcts];
        if (field === 'prdctMonthlyPrice' || field === 'prdctYearlyPrice') {
            if (isNaN(value) || value === '') {
                updatedProducts[index][field] = null; // 빈 값은 null로 처리
            } else {
                updatedProducts[index][field] = parseInt(value); // 숫자로 변환
            }
        } else {
            updatedProducts[index][field] = value;
        }

        setPrdcts(updatedProducts);
    };

    const handleItemOptChange = (index: number, updatedItem: PrdctDetailExtended) => {
        const updatedItems = [...optItems];
        updatedItem.itemValues = updatedItem.itemValues.map(value => value === "CHECK" ? "CHECK" : "UNCHECK");
        updatedItems[index] = updatedItem;
        setOptItems(updatedItems);
    };

    const handleItemDatChange = (index: number, updatedItem: PrdctDetailExtended) => {
        const updatedItems = [...datItems];
        updatedItems[index] = updatedItem;
        setDatItems(updatedItems);
    };

    const handleUpdatePrdct = () => {

        const hasEmptyItemValueDatItems = datItems.some(item => item.itemValues.length === 0 || item.itemValues.includes(''));
        if (hasEmptyItemValueDatItems) {
            alert("데이터 항목에 값이 입력되지 않았습니다. 모든 항목에 값을 입력해주세요.");
            return; // 함수 종료
        }

        const hasEmptyItemValue = newDatItems.some(item => item.itemValues.length === 0 || item.itemValues.includes(''));
        // 값이 없는 항목이 있으면 alert를 띄우고 함수를 중단합니다.
        if (hasEmptyItemValue) {
            alert("데이터 항목에 값이 입력되지 않았습니다. 모든 항목에 값을 입력해주세요.");
            return; // 함수 종료
        }
        const transformedPrdctItems = [...optItems, ...datItems].map(item => {
            return {
                itemName: item.itemName,
                sltnId: item.sltnId,
                itemType: item.itemType,
                prdctId: item.prdctIds.join('|'),
                itemValue: item.itemValues.join('|'),
                useYn: 'Y',
                regDt: new Date(),
                regUserId: localStorage.getItem('userId'),
                modDt: new Date(),
                modUserId: localStorage.getItem('userId'),
            };
        });

        const transformedNewOptItems = newOptItems.map(item => {
            return {
                itemName: item.itemName,
                sltnId: item.sltnId,
                itemType: item.itemType,
                prdctId: item.prdctIds.join('|'),
                itemValue: item.itemValues.join('|'),
                useYn: 'Y',
                regDt: new Date(),
                regUserId: localStorage.getItem('userId'),
                modDt: new Date(),
                modUserId: localStorage.getItem('userId'),
            };
        });

        const transformedNewDatItems = newDatItems.map(item => {
            return {
                itemName: item.itemName,
                sltnId: item.sltnId,
                itemType: item.itemType,
                prdctId: item.prdctIds.join('|'),
                itemValue: item.itemValues.join('|'),
                useYn: 'Y',
                regDt: new Date(),
                regUserId: localStorage.getItem('userId'),
                modDt: new Date(),
                modUserId: localStorage.getItem('userId'),
            };
        });

        const transformedPrdcts: PrdctDto[] = prdcts.map(item => {
            return {
                prdctSn: item.prdctSn,
                sltnId: item.sltnId,
                prdctId: item.prdctId,
                prdctName: item.prdctName,
                prdctDesc: item.prdctDesc,
                prdctMonthlyPrice: item.prdctMonthlyPrice,
                prdctYearlyPrice: item.prdctYearlyPrice,
                useYn: "Y",
                regUserId: localStorage.getItem('userId'),
                regDt: new Date(),
                modUserId: localStorage.getItem('userId'),
                modDt: new Date(),
            };
        });

        const newCreatePrdctDto = {
            dataProducts: transformedPrdcts,
            dataItems: [...transformedPrdctItems, ...transformedNewOptItems, ...transformedNewDatItems]
        }
        // console.log(newCreatePrdctDto);
        dispatch(createPrdctAsync.request({ sltnId: currentOgnzName, createPrdctDto: newCreatePrdctDto }));
    }

    const handleDeleteOptItem = (indexToDelete: number) => {
        const updatedItems = [...optItems];
        updatedItems.splice(indexToDelete, 1);
        setOptItems(updatedItems);
    };

    const handleDeleteNewOptItem = (indexToDelete: number) => {
        const updatedItems = [...newOptItems];
        updatedItems.splice(indexToDelete, 1);
        setNewOptItems(updatedItems);
    };

    const handleDeleteDatItem = (indexToDelete: number) => {
        const updatedItems = [...datItems];
        updatedItems.splice(indexToDelete, 1);
        setDatItems(updatedItems);
    };

    const handleDeleteNewDatItem = (indexToDelete: number) => {
        const updatedItems = [...newDatItems];
        updatedItems.splice(indexToDelete, 1);
        setNewDatItems(updatedItems);
    };

    const handleReset = () => {
        setPrdcts(initialPrdcts.current);
        setPrdctItems(initialPrdctItems.current);
        setNewDatItems([]);
        setNewOptItems([]);
    };

    // 컴포넌트가 마운트될 때 데이터를 불러옴
    useEffect(() => {
        dispatch(findPrdctListAsync.request("GEO_NIC"));
    }, [dispatch]);

    // prdctListState 변경 시 prdcts 상태 업데이트
    useEffect(() => {
        if (
            prdtcListState &&
            !prdtcListState.loading &&
            !prdtcListState.error &&
            Array.isArray(prdtcListState.data)
        ) {
            const newPrdcts = prdtcListState.data;

            // 이전 상태와 비교하여 변경된 경우에만 업데이트
            if (JSON.stringify(prdcts) !== JSON.stringify(newPrdcts)) {
                setPrdcts(newPrdcts);
                // prdctItemListState를 요청
                initialPrdcts.current = newPrdcts;
                dispatch(findPrdctItemListAsync.request(currentOgnzName)); // 여기서 호출
            }
        }
    }, [prdtcListState]);

    // prdctItemListState 변경 시 prdctItems 상태 업데이트
    useEffect(() => {
        if (prdtcItemListState &&
            prdtcItemListState.data &&
            Array.isArray(prdtcItemListState.data)) {

            const processedItems = prdtcItemListState.data.map((item: any) => {
                const prdctIds = typeof item.prdctId === 'string' ? item.prdctId.split('|').map((id: string) => id.trim()) : [];
                const itemValues = typeof item.itemValue === 'string' ? item.itemValue.split('|').map((value: string) => value.trim()) : [];
                return {
                    sltnId: currentOgnzName,
                    itemName: item.itemName || '',
                    itemType: item.itemType || '',
                    prdctIds: prdctIds,
                    itemValues: itemValues
                } as PrdctDetailDto;
            });

            initialPrdctItems.current = processedItems;

            setPrdctItems(processedItems);
        } else {
            setPrdctItems([]);
        }
    }, [prdtcItemListState]);

    useEffect(() => {
        if (prdctItems.length > 0) {
            setOptItems(prdctItems.filter(item => item.itemType === 'OPT'));
            setDatItems(prdctItems.filter(item => item.itemType === 'DAT'));
        }
    }, [prdctItems]); // prdctItems가 변경될 때만 실행

    useEffect(() => {
        if (prevLoadingRef.current && createPrdctState.loading === false) {
            if (createPrdctState.error) {
                alert('저장에 실패하였습니다');
                window.location.reload();
            } else if (createPrdctState.data) {
                alert('저장되었습니다');
                window.location.reload();
            }
        }
        prevLoadingRef.current = createPrdctState.loading;
    }, [createPrdctState]);

    return (
        <div className="wrapper">
            <SideLayout />
            {/* leftside-menu */}
            <div className='content-page'>
                <Header />
                <ContentLayout title='제품관리'>
                    <div className='row'>
                        <Tabs
                            activeKey={key}
                            onSelect={(k) => handleSelect(k!)}
                            id="fill-tab-example"
                            className="mb-3"
                            fill
                        >
                            <Tab eventKey="GEO_NIC" title="영상촬영 서비스"></Tab>
                            <Tab eventKey="SIMMETA" title="AI 데이터 생성관리 서비스"></Tab>
                            <Tab eventKey="MAPPRIME" title="디지털트윈 지도 서비스"></Tab>
                        </Tabs>
                    </div>
                    <div className="display-count d-flex justify-content-between align-items-center">
                        <h3 className='dash-ti mb-0'>비용 정보</h3>

                    </div>
                    <Table bordered size="sm">
                        <colgroup>
                            <col style={{ width: '150px' }} />
                            <col style={{ width: '150px' }} />
                            <col style={{ width: 'auto' }} />
                        </colgroup>
                        <tbody>
                            {(prdcts || []).map((product, index) => (
                                <>
                                    <tr key={`product-${index}-1`}>
                                        <th rowSpan={3}>{product.prdctName}</th>
                                        <td>1개월</td>
                                        <td>
                                            <InputGroup>
                                                <Form.Control
                                                    value={product.prdctMonthlyPrice || ''}
                                                    onChange={(e) => handleProductChange(index, 'prdctMonthlyPrice', e.target.value)}
                                                    aria-label="1개월 가격 입력"
                                                />
                                                <InputGroup.Text>원</InputGroup.Text>
                                            </InputGroup>
                                        </td>
                                    </tr>
                                    <tr key={`product-${index}-2`}>
                                        <td>12개월</td>
                                        <td>
                                            <InputGroup>
                                                <Form.Control
                                                    value={product.prdctYearlyPrice || ''}
                                                    onChange={(e) => handleProductChange(index, 'prdctYearlyPrice', e.target.value)}
                                                    aria-label="12개월 가격 입력"
                                                />
                                                <InputGroup.Text>원</InputGroup.Text>
                                            </InputGroup>
                                        </td>
                                    </tr>
                                    <tr key={`product-${index}-3`}>
                                        <td>설명</td>
                                        <td>
                                            <Form.Control
                                                type="text"
                                                value={product.prdctDesc || ''}
                                                onChange={(e) => handleProductChange(index, 'prdctDesc', e.target.value)}
                                                placeholder="*50자 내외로 작성"
                                            />
                                        </td>
                                    </tr>
                                </>
                            ))}
                        </tbody>
                    </Table>
                    <div className="display-count d-flex justify-content-between align-items-center">
                        <h3 className='dash-ti mb-2'>상세 구성</h3>
                        <div>
                            <button
                                type="button"
                                className="btn btn-secondary btn-sm"
                                onClick={handleAddOptItem}
                            >
                                + 선택 추가
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary btn-sm m-1"
                                onClick={handleAddDatItem}
                            >
                                + 데이터 추가
                            </button>
                        </div>
                    </div>
                    <Table bordered size="sm">
                        <colgroup>
                            <col style={{ width: '250px' }} />
                            <col style={{ width: '150px' }} />
                            <col style={{ width: 'auto' }} />
                        </colgroup>
                        <tbody>
                            {optItems.map((item, index) => (
                                <tr key={`opt-item-${index}`}>
                                    <th>
                                        {item.itemName}
                                        <button
                                            type="button"
                                            className="btn btn-danger btn-sm ms-2"
                                            onClick={() => handleDeleteOptItem(index)}
                                        >
                                            삭제
                                        </button>
                                    </th>
                                    <td colSpan={3}>
                                        <Form>
                                            {/* 'Select All' Checkbox */}
                                            <Form.Check
                                                inline
                                                label="전체"
                                                name={`group-${index}`}
                                                type="checkbox"
                                                id={`select-all-${index}`}
                                                key={`select-all-${index}`}
                                                checked={item.itemValues.every(value => value === 'CHECK')}
                                                onChange={(e) => {
                                                    const updatedItemValues = item.itemValues.map(() =>
                                                        e.target.checked ? 'CHECK' : ''
                                                    );
                                                    const updatedItem = {
                                                        ...item,
                                                        itemValues: updatedItemValues
                                                    };
                                                    handleItemOptChange(index, updatedItem);
                                                }}
                                            />
                                            {item.prdctIds.map((prdctId, idx) => {
                                                const label = prdctIdToLabel[prdctId] || prdctId;
                                                const isChecked = item.itemValues[idx] === 'CHECK';
                                                return (
                                                    <Form.Check
                                                        inline
                                                        label={label}
                                                        name={`group-${index}`}
                                                        type="checkbox"
                                                        id={`inline-${index}-${idx}`}
                                                        key={`inline-${index}-${idx}`}
                                                        checked={isChecked}
                                                        onChange={(e) => {
                                                            const updatedItemValues = [...item.itemValues];
                                                            updatedItemValues[idx] = e.target.checked ? 'CHECK' : '';
                                                            const updatedItem = {
                                                                ...item,
                                                                itemValues: updatedItemValues
                                                            };
                                                            handleItemOptChange(index, updatedItem);
                                                        }}
                                                    />
                                                );
                                            })}
                                        </Form>
                                    </td>
                                </tr>
                            ))}
                            {newOptItems.map((item, index) => (
                                <tr key={`new-opt-item-${index}`}>
                                    <th>
                                        <div className="item-container">
                                            <Form.Control
                                                type="text"
                                                value={item.itemName}
                                                onChange={(e) => {
                                                    const updatedItems = [...newOptItems];
                                                    updatedItems[index].itemName = e.target.value;
                                                    setNewOptItems(updatedItems);
                                                }}
                                                placeholder="항목 이름"
                                            />
                                            <button
                                                type="button"
                                                className="btn btn-danger btn-sm ms-2 delete-button"
                                                onClick={() => handleDeleteNewOptItem(index)}
                                            >
                                                삭제
                                            </button>
                                        </div>
                                    </th>
                                    <td colSpan={3}>
                                        <Form>
                                            {/* 'Select All' Checkbox */}
                                            <Form.Check
                                                inline
                                                label="전체"
                                                name={`new-opt-group-${index}`}
                                                type="checkbox"
                                                id={`new-select-all-${index}`}
                                                key={`new-select-all-${index}`}
                                                checked={item.itemValues.every(value => value === 'CHECK')}
                                                onChange={(e) => {
                                                    const updatedItems = [...newOptItems];
                                                    const updatedItem = { ...updatedItems[index] };
                                                    updatedItem.itemValues = updatedItem.itemValues.map(() => e.target.checked ? 'CHECK' : 'UNCHECK');
                                                    updatedItems[index] = updatedItem;
                                                    setNewOptItems(updatedItems);
                                                }}
                                            />
                                            {Object.keys(prdctIdToLabel).map((prdctId, idx) => (
                                                <Form.Check
                                                    inline
                                                    label={prdctIdToLabel[prdctId]}
                                                    name={`new-opt-group-${index}`}
                                                    type="checkbox"
                                                    id={`new-opt-${index}-${idx}`}
                                                    key={`new-opt-${index}-${idx}`}
                                                    checked={item.itemValues[idx] === 'CHECK'}
                                                    onChange={(e) => {
                                                        const updatedItems = [...newOptItems];
                                                        const updatedItem = { ...updatedItems[index] };
                                                        const prdctIndex = Object.keys(prdctIdToLabel).indexOf(prdctId);
                                                        updatedItem.itemValues[prdctIndex] = e.target.checked ? 'CHECK' : 'UNCHECK';
                                                        updatedItems[index] = updatedItem;
                                                        setNewOptItems(updatedItems);
                                                    }}
                                                />
                                            ))}
                                        </Form>
                                    </td>
                                </tr>
                            ))}
                            {/* Render DAT items */}
                            {datItems.map((item, index) => (
                                <Fragment key={`dat-item-${index}`}>
                                    {item.prdctIds.map((prdctId, idx) => {
                                        const label = prdctIdToLabel[prdctId] || prdctId;
                                        const value = item.itemValues[idx];
                                        return (
                                            <tr key={`dat-item-${index}-${idx}`}>
                                                {idx === 0 && (
                                                    <th rowSpan={item.prdctIds.length}>
                                                        {item.itemName}
                                                        <button
                                                            type="button"
                                                            className="btn btn-danger btn-sm ms-2"
                                                            onClick={() => handleDeleteDatItem(index)}
                                                        >
                                                            삭제
                                                        </button>
                                                    </th>
                                                )}
                                                <td>{label}</td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        value={value}
                                                        onChange={(e) => {
                                                            const updatedItemValues = [...item.itemValues];
                                                            updatedItemValues[idx] = e.target.value;
                                                            const updatedItem = {
                                                                ...item,
                                                                itemValues: updatedItemValues
                                                            };
                                                            handleItemDatChange(index, updatedItem);
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </Fragment>
                            ))}
                            {/* Render new DAT items */}
                            {newDatItems.map((item, index) => (
                                <Fragment key={`new-dat-item-${index}`}>
                                    {item.prdctIds.map((prdctId, idx) => {
                                        const label = prdctIdToLabel[prdctId] || prdctId;
                                        const value = item.itemValues[idx] || '';
                                        return (
                                            <tr key={`new-dat-item-${index}-${idx}`}>
                                                {idx === 0 && (
                                                    <th rowSpan={item.prdctIds.length}>
                                                        <div className="item-container">
                                                            <Form.Control
                                                                type="text"
                                                                value={item.itemName}
                                                                onChange={(e) => {
                                                                    const updatedItems = [...newDatItems];
                                                                    updatedItems[index].itemName = e.target.value;
                                                                    setNewDatItems(updatedItems);
                                                                }}
                                                                placeholder="항목 이름"
                                                            />
                                                            <button
                                                                type="button"
                                                                className="btn btn-danger btn-sm ms-2 delete-button"
                                                                onClick={() => handleDeleteNewDatItem(index)}
                                                            >
                                                                삭제
                                                            </button>
                                                        </div>
                                                    </th>
                                                )}
                                                <td>{label}</td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        value={value}
                                                        onChange={(e) => {
                                                            const updatedItems = [...newDatItems];
                                                            updatedItems[index].itemValues[idx] = e.target.value;
                                                            setNewDatItems(updatedItems);
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </Fragment>
                            ))}
                        </tbody>
                    </Table>
                    <div className="d-flex justify-content-end">
                        <button
                            type="button"
                            className="btn btn-secondary btn-sm"
                            onClick={handleReset}
                        >
                            취소
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary btn-sm ms-2"
                            onClick={handleUpdatePrdct}
                        >
                            저장
                        </button>
                    </div>
                </ContentLayout>
            </div>
        </div>
    )
}
export default Prdct;
