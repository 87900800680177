import { takeEvery } from 'redux-saga/effects';
import createAsyncSaga from '../lib';
import { GET_OVERVIEW, GET_PAYMNT, GET_SIGNUP, getOverviewAsync, getPurcasesAsync, getSignupAsync } from './actions';
import { overview, paymnt, signup } from '../../api/stat';


const getOverviewSaga = createAsyncSaga(getOverviewAsync, overview);
const getSignupSaga = createAsyncSaga(getSignupAsync, signup);
const getPaymntSaga = createAsyncSaga(getPurcasesAsync, paymnt);

export function* statSaga() {
  yield takeEvery(GET_OVERVIEW, getOverviewSaga);
  yield takeEvery(GET_SIGNUP, getSignupSaga);
  yield takeEvery(GET_PAYMNT, getPaymntSaga);
}
