import { takeEvery } from 'redux-saga/effects';
import createAsyncSaga from '../lib';
import { CREATE_OGNZ, createOgnzAsync, DELETE_OGNZ, deleteOgnzAsync, FIND_OGNZ, FIND_OGNZ_LIST, findOgnzAsync, findOgnzListAsync, UPDATE_OGNZ, updateOgnzAsync } from './actions';
import { createOgnz, deleteOgnz, findOgnz, findOgnzList, updateOgnz } from '../../api/ognz';


const findOgnzListSaga = createAsyncSaga(findOgnzListAsync, findOgnzList);
const findOgnzSaga = createAsyncSaga(findOgnzAsync, findOgnz);
const updateOgnzSaga = createAsyncSaga(updateOgnzAsync, updateOgnz);
const deleteOgnzSaga = createAsyncSaga(deleteOgnzAsync, deleteOgnz);
const createOgnzSaga = createAsyncSaga(createOgnzAsync, createOgnz);

export function* ognzSaga() {
  yield takeEvery(FIND_OGNZ_LIST, findOgnzListSaga);
  yield takeEvery(FIND_OGNZ, findOgnzSaga);
  yield takeEvery(UPDATE_OGNZ, updateOgnzSaga);
  yield takeEvery(DELETE_OGNZ, deleteOgnzSaga);
  yield takeEvery(CREATE_OGNZ, createOgnzSaga);
}
