import { getNeospectraUsagesServiesLeftAsync, getMapprimeDashboardAsync, GET_MAPPRIME_DASHBOARD, GET_MYPAGE_NEO_PROJECT_STATS, getNeospectraProjectsStatsAsync, getNeospectraJobsStatsAsync, GET_MYPAGE_NEO_JOBS_STATS, getInfoseedDashboardAsync, GET_INFOSEED_DASHBOARD, getBuyScrtnListAsync, GET_BUY_SCRTN_LIST, getBuyUseListAsync, getNeospectraUsagesCurrentAsync, GET_MYPAGE_NEO_USAGES_CURRENT } from './actions';
import { GET_MYPAGE_NEO_USAGES_SERVIES_LEFT } from './actions';
import { getNeospectraUsagesServiesLeft, getMapprimeDashboard, getNeospectraJobsStats, getInfoseedDashboard, getNeospectraProjectsStats, getBuyScrtnList, getBuyUseList, getNeospectraUsagesCurrent } from '../../api/dashboard';
import { takeEvery } from 'redux-saga/effects';
import createAsyncSaga from '../lib';
import { GET_BUY_USE_LIST } from '../prdct';

// GEO_NIC
const getInfoseedDashboardSaga = createAsyncSaga(getInfoseedDashboardAsync, getInfoseedDashboard);

// SEMMETA
const getNeospectraJobsDetectionStatsSaga = createAsyncSaga(getNeospectraProjectsStatsAsync, getNeospectraProjectsStats);
const getNeospectraJobsDeletionStatsSaga = createAsyncSaga(getNeospectraJobsStatsAsync, getNeospectraJobsStats);
const getNeospectraUsagesServiesLeftSaga = createAsyncSaga(getNeospectraUsagesServiesLeftAsync, getNeospectraUsagesServiesLeft);
const getNeospectraUsagesCurrentSaga = createAsyncSaga(getNeospectraUsagesCurrentAsync, getNeospectraUsagesCurrent);

// MEPPRIME
const getMapprimeDashboardSaga = createAsyncSaga(getMapprimeDashboardAsync, getMapprimeDashboard);

const getBuyScrtnListSaga = createAsyncSaga(getBuyScrtnListAsync, getBuyScrtnList);
const getBuyUseListSaga = createAsyncSaga(getBuyUseListAsync, getBuyUseList);


export function* dashboardSaga() {
       // GEO_NIC
       yield takeEvery(GET_INFOSEED_DASHBOARD, getInfoseedDashboardSaga);

       // SEMMETA
       // yield takeEvery(GET_MYPAGE_NEO_PROJECT_CNT, getNeospectraProjectCntSaga);
       // yield takeEvery(GET_MYPAGE_NEO_PROJECT_STATS, getNeospectraProjectStatsSaga);
       yield takeEvery(GET_MYPAGE_NEO_PROJECT_STATS, getNeospectraJobsDetectionStatsSaga);
       yield takeEvery(GET_MYPAGE_NEO_JOBS_STATS, getNeospectraJobsDeletionStatsSaga);
       yield takeEvery(GET_MYPAGE_NEO_USAGES_SERVIES_LEFT, getNeospectraUsagesServiesLeftSaga);
       yield takeEvery(GET_MYPAGE_NEO_USAGES_CURRENT, getNeospectraUsagesCurrentSaga);

       // MAPPRIME
       yield takeEvery(GET_MAPPRIME_DASHBOARD, getMapprimeDashboardSaga);

       yield takeEvery(GET_BUY_SCRTN_LIST, getBuyScrtnListSaga);
       yield takeEvery(GET_BUY_USE_LIST, getBuyUseListSaga);
}
