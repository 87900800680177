import { FC, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './Dashboard.scss';
import SideLayout from '../../containers/sidemenu';
import Header from '../../containers/header';
import { Bar, BarChart, CartesianGrid, Cell, Legend, Line, LineChart, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { RootState } from '../../modules';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs } from 'react-bootstrap';
import { getBuyScrtnListAsync, getBuyUseListAsync, getInfoseedDashboardAsync, getMapprimeDashboardAsync, getNeospectraJobsStatsAsync, getNeospectraProjectsStatsAsync, getNeospectraUsagesCurrentAsync, getNeospectraUsagesServiesLeftAsync } from '../../modules/dashboard';
import { findUserGroupListAsync, findUserGroupMemberListAsync } from '../../modules/usergroup';
import { SelectUserDto } from '../../types/UserDto';
import { SelectUserGroupDto } from '../../types/UserGroupDto';
import Pagination from '../../components/Pagination';
import { filesize } from 'filesize';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const Dashboard: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { ognzId, ognzGrpSn } = location.state || {}; // ognzGrpSn이 없을 때 대비

    const infoseedDashboard = useSelector((state: RootState) => state.dashboard.infoseedDashboard);   // 인포시드 대쉬보드
    const neoJobsStat = useSelector((state: RootState) => state.dashboard.neoJobsStat);   // 작업 통계
    const neoProjectStat = useSelector((state: RootState) => state.dashboard.neoProjectsStat); // 프로젝트 통계
    const neoUsagesServies = useSelector((state: RootState) => state.dashboard.neoUsagesServies);   // 사용량 조회
    const neoUsagesCurrent = useSelector((state: RootState) => state.dashboard.neoUsagesCurrent);   // 사용량 조회
    const mapprimeDashboard = useSelector((state: RootState) => state.dashboard.mapprimeDashboard);   // 맵프라임 대쉬보드
    const buyScrtnList = useSelector((state: RootState) => state.dashboard.buyScrtnList);   // 결제정보 대쉬보드
    const buyUseList = useSelector((state: RootState) => state.dashboard.buyUseList);   //  구독정보 대쉬보드

    // 탭 상태 관리
    const [activeTab, setActiveTab] = useState<string>('GEO_NIC');

    // 그래프 및 데이터 관련 상태
    const [assetStatus, setAssetStatus] = useState<any[]>([]);
    const [modalUserList, setModalUserList] = useState<SelectUserGroupDto[]>([]);
    const userGroupMemberList = useSelector((state: RootState) => state.usergroup.userGroupMemberList);
    const [searchGroupParams, setSearchGroupParams] = useState<SelectUserDto>({
        currentPageNo: 1,
    });

    const onSearch = async (pageNo: number) => {
        setSearchGroupParams(prev => ({
            ...prev,
            currentPageNo: pageNo
        }));
        dispatch(findUserGroupMemberListAsync.request({
            ognzGrpSn: ognzGrpSn,
            selectUserDto: searchGroupParams
        }));

    }

    const onClickTab = async (sltnId: string, currentPageNo: number) => {
        setActiveTab(sltnId);
        dispatch(getBuyScrtnListAsync.request({
            sltnId: sltnId
            , currentPageNo: currentPageNo
            , pageUnit: 3
            , ognzUserGrpSn: ognzGrpSn
        }))
    }

    function formatDate(date: Date): string {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    useEffect(() => {
        const fetchBuyUser = async () => {
            // console.log('ognzGrpSn:', ognzGrpSn);
            // 기관 그룹 사용자 목록
            dispatch(findUserGroupMemberListAsync.request({ ognzGrpSn: ognzGrpSn, selectUserDto: searchGroupParams }));

            const startDay = new Date();

            const daysToAdd = 10;
            startDay.setDate(startDay.getDate() - daysToAdd);

            const endDate = formatDate(new Date());
            const startDate = formatDate(startDay);

            // 인포시드
            dispatch(getInfoseedDashboardAsync.request({ ognzId: ognzId, ognzUserGrpSn: ognzGrpSn }));

            // 네오스펙트라 프로젝트 그래프
            await dispatch(getNeospectraProjectsStatsAsync.request({
                param: {
                    org_id: ognzGrpSn,
                    start_date: startDate,
                    end_date: endDate,
                }
            }));

            // 네오스펙트라 작업 그래프
            await dispatch(getNeospectraJobsStatsAsync.request({
                param: {
                    org_id: ognzGrpSn,
                    start_date: startDate,
                    end_date: endDate,
                }
            }));

            // 서비스별 남은 사용량 조회
            dispatch(getNeospectraUsagesServiesLeftAsync.request(ognzGrpSn));
            // 맵프라임 통계 조회
            dispatch(getMapprimeDashboardAsync.request(ognzGrpSn));

            dispatch(getBuyScrtnListAsync.request({
                sltnId: 'GEO_NIC'
                , currentPageNo: 1
                , pageUnit: 3
                , ognzUserGrpSn: ognzGrpSn
            }))
            dispatch(getBuyUseListAsync.request(ognzGrpSn));
            dispatch(getNeospectraUsagesCurrentAsync.request(ognzGrpSn));
        };

        fetchBuyUser();
    }, []);

    useEffect(() => {
        if (mapprimeDashboard && mapprimeDashboard.data && mapprimeDashboard.data.result != "error") {

            const asset = [];
            asset.push({ name: "생성중인 에셋", value: (mapprimeDashboard?.data?.count.asset?.progress ? mapprimeDashboard?.data?.count.asset?.progress : 0) })
            asset.push({ name: "완료된 에셋", value: (mapprimeDashboard?.data?.count.asset?.done ? mapprimeDashboard?.data?.count.asset?.done : 0) })
            setAssetStatus(asset)

            // console.log("mapprimeDashboard: ", mapprimeDashboard);
        }
    }, [mapprimeDashboard]);

    useEffect(() => {
        // console.log("assetStatus: ", assetStatus);
    }, [assetStatus]);

    useEffect(() => {
        if (userGroupMemberList.data?.data) {
            setModalUserList(userGroupMemberList.data.data);
        }
    }, [userGroupMemberList]);

    return (
        <div className="wrapper">
            <SideLayout />
            {/* leftside-menu */}
            <div className='content-page'>
                <Header />

                <div className="content">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-12"><h2 className='page-title'>기관 그룹 대쉬보드</h2></div>
                        </div>
                        <div className="row">
                            <div className="display-count mb-1 d-flex justify-content-between">
                                <span>기관 그룹 사용자 목록</span>
                            </div>
                            <table className="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th className='text-center'>번호</th>
                                        <th className='text-center'>소속 기관</th>
                                        <th className='text-center'>아이디</th>
                                        <th className='text-center'>이름</th>
                                        <th className='text-center'>이메일</th>
                                        <th className='text-center'>사용자 권한</th>
                                        <th className='text-center'>프로젝트 권한</th>
                                        <th className='text-center'>등록일</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userGroupMemberList.loading ? (
                                        <tr>
                                            <td className="text-center"> loading...</td>
                                        </tr>
                                    ) : modalUserList.map.length > 0 ? (
                                        modalUserList.map((user: SelectUserGroupDto, index: number) => (
                                            <tr key={index}>
                                                <td className='text-center'>{
                                                    userGroupMemberList.data.meta.totalCnt -
                                                    (userGroupMemberList.data.meta.currentPageNo - 1) * userGroupMemberList.data.meta.pageSize - index
                                                }
                                                </td>
                                                <td className='text-center'>{user.ognzName}</td>
                                                <td className='text-center'>{user.userId}</td>
                                                <td className='text-center'>{user.userName}</td>
                                                <td className='text-center'>{user.email}</td>
                                                <td className='text-center'>{user.userRoleCd === 'ADMIN' ? '관리자' : '사용자'}</td>
                                                <td className='text-center'>{user.roleCd === 'ADMIN' ? '관리자' : '사용자'}</td>
                                                <td className='text-center'>{new Date(user.regDt!).toLocaleDateString()}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={6} className='text-center'>데이터가 없습니다.</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            {userGroupMemberList.data?.meta ? (
                                <Pagination key="1" pageingDto={userGroupMemberList.data.meta} onClick={onSearch} />
                            ) : (<span></span>)
                            }
                        </div>

                        <div className="mb-4"></div>

                        <div className="row">
                            <div className='col-12'>
                                <Tabs
                                    activeKey={activeTab}
                                    onSelect={(k) => onClickTab(k!, 1)}
                                    id="fill-tab-example"
                                    className="mb-3"
                                    fill
                                >
                                    <Tab eventKey="GEO_NIC" title="영상촬영 서비스"></Tab>
                                    <Tab eventKey="SIMMETA" title="AI 데이터 생성관리 서비스"></Tab>
                                    <Tab eventKey="MAPPRIME" title="디지털트윈 지도 서비스"></Tab>
                                </Tabs>
                            </div>
                        </div>
                        <div className="row">
                            <div className="display-count mb-1 d-flex justify-content-between">
                                <span>구독정보</span>
                            </div>
                            <table className="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th className='text-center'>상품명</th>
                                        <th className='text-center'>상품금액</th>
                                        <th className='text-center'>구독기간</th>
                                        <th className='text-center'>사용량</th>
                                        <th className='text-center'>추가금액</th>
                                        <th className='text-center'>다음 결제일</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {buyUseList.data?.data?.length > 0 ? (
                                        (() => {
                                            const item = buyUseList.data.data.find((item: any) => item.sltnId === activeTab);

                                            if (!item) {
                                                return (
                                                    <tr>
                                                        <td colSpan={6} className="text-center">
                                                            데이터가 없습니다.
                                                        </td>
                                                    </tr>
                                                );
                                            }

                                            switch (activeTab) {
                                                case "GEO_NIC":
                                                    return (
                                                        <tr>
                                                            <td className="text-center">{item.prdctNm}</td>
                                                            <td className="text-center">{Number(item.prdctAmt).toLocaleString()}</td>
                                                            <td className="text-center">{item.startDate} ~ {item.endDate}</td>
                                                            <td className="text-center">
                                                                {(() => {
                                                                    const useMemory = Number(infoseedDashboard?.data?.data?.USE_MEMORY || 0); // 사용 메모리
                                                                    const totalMemory = Number(infoseedDashboard?.data?.data?.TOTAL_MEMORY || 0); // 총 메모리

                                                                    const formatMemory = (memory: number) => {
                                                                        if (memory >= 1024) {
                                                                            // GB로 변환
                                                                            return `${(memory / 1024).toFixed(1)} GB`;
                                                                        }
                                                                        // MB 그대로 표시
                                                                        return `${memory} MB`;
                                                                    };

                                                                    const percentage =
                                                                        totalMemory > 0
                                                                            ? ((useMemory / totalMemory) * 100).toFixed(1)
                                                                            : "0.0";

                                                                    return `${formatMemory(useMemory)} / ${formatMemory(totalMemory)} (${percentage}%)`;
                                                                })()}</td>
                                                            <td className="text-center">
                                                                {(() => {
                                                                    const useMemory = Number(infoseedDashboard?.data?.data?.USE_MEMORY || 0); // 사용 메모리 (MB)
                                                                    const totalMemory = Number(infoseedDashboard?.data?.data?.TOTAL_MEMORY || 0); // 총 메모리 (MB)

                                                                    // 초과 메모리 계산 (GB로 변환)
                                                                    const excessMemoryGB = Math.max(0, (useMemory - totalMemory) / 1024); // MB → GB, 음수 방지

                                                                    // 초과 메모리에 따른 추가 금액 계산
                                                                    const additionalCost = Math.ceil(excessMemoryGB) * 10000; // 1GB당 10,000원

                                                                    return additionalCost > 0
                                                                        ? `${additionalCost.toLocaleString()} 원` // 추가 금액 표시
                                                                        : "0 원"; // 초과 메모리가 없으면 0 원
                                                                })()}
                                                            </td>
                                                            <td className="text-center">{(() => {
                                                                const endDate = new Date(item.endDate);
                                                                endDate.setDate(endDate.getDate() + 1);
                                                                const year = endDate.getFullYear();
                                                                const month = String(endDate.getMonth() + 1).padStart(2, '0');
                                                                const day = String(endDate.getDate()).padStart(2, '0');
                                                                return `${year}.${month}.${day}`;
                                                            })()}</td>
                                                        </tr>
                                                    );

                                                case "SIMMETA":
                                                    return (
                                                        <tr>
                                                            <td className="text-center">{item.prdctNm}</td>
                                                            <td className="text-center">{Number(item.prdctAmt).toLocaleString()}</td>
                                                            <td className="text-center">{item.startDate} ~ {item.endDate}</td>
                                                            <td className="text-center">{`${neoUsagesCurrent?.data?.used} GB / ${neoUsagesCurrent?.data?.available} GB (${((neoUsagesCurrent?.data?.used / neoUsagesCurrent?.data?.available) * 100).toFixed(1)}%)`}</td>
                                                            <td className="text-center">{Math.max(0, (Math.ceil(Number(neoUsagesCurrent?.data?.used) - Number(neoUsagesCurrent?.data?.available)) * 10000)).toLocaleString() + " 원"}</td>
                                                            <td className="text-center">{(() => {
                                                                const endDate = new Date(item.endDate);
                                                                endDate.setDate(endDate.getDate() + 1);
                                                                const year = endDate.getFullYear();
                                                                const month = String(endDate.getMonth() + 1).padStart(2, '0');
                                                                const day = String(endDate.getDate()).padStart(2, '0');
                                                                return `${year}.${month}.${day}`;
                                                            })()}</td>
                                                        </tr>
                                                    );

                                                case "MAPPRIME":
                                                    return (
                                                        <tr>
                                                            <td className="text-center">{item.prdctNm}</td>
                                                            <td className="text-center">{Number(item.prdctAmt).toLocaleString()}</td>
                                                            <td className="text-center">{item.startDate} ~ {item.endDate}</td>
                                                            <td className="text-center">
                                                                {mapprimeDashboard.data?.size?.total
                                                                    ? `${filesize(mapprimeDashboard.data.size.current, { base: 2, round: 2, symbols: { GiB: "GB", MB: "MB", KB: "KB", Bytes: "B" } })} / ${filesize(mapprimeDashboard.data.size.total, { base: 2, round: 2, symbols: { GiB: "GB", MB: "MB", KB: "KB", Bytes: "B" } })} (${((mapprimeDashboard.data.size.current / mapprimeDashboard.data.size.total) * 100).toFixed(2)}%)`
                                                                    : "N/A"}
                                                            </td>
                                                            <td className="text-center">
                                                                {(() => {
                                                                    const currentSize = mapprimeDashboard.data?.size?.current || 0;
                                                                    const totalSize = mapprimeDashboard.data?.size?.total || 0;
                                                                    const excessUsage = Math.max(0, currentSize - totalSize) / (1024 ** 3);
                                                                    const additionalAmount = Math.ceil(excessUsage) * 10000;
                                                                    return additionalAmount.toLocaleString() + " 원";
                                                                })()}
                                                            </td>
                                                            <td className="text-center">{(() => {
                                                                const endDate = new Date(item.endDate);
                                                                endDate.setDate(endDate.getDate() + 1);
                                                                const year = endDate.getFullYear();
                                                                const month = String(endDate.getMonth() + 1).padStart(2, '0');
                                                                const day = String(endDate.getDate()).padStart(2, '0');
                                                                return `${year}.${month}.${day}`;
                                                            })()}</td>
                                                        </tr>
                                                    );

                                                default:
                                                    return (
                                                        <tr>
                                                            <td colSpan={6} className="text-center">
                                                                잘못된 탭 선택입니다.
                                                            </td>
                                                        </tr>
                                                    );
                                            }
                                        })()
                                    ) : (
                                        <tr>
                                            <td colSpan={6} className="text-center">
                                                데이터가 없습니다.
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div className="mb-4"></div>

                        <div className="row">
                            <div className="display-count mb-1 d-flex justify-content-between">
                                <span>결제 이력</span>
                            </div>
                            <table className="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th className='text-center'>상품명</th>
                                        <th className='text-center'>결제 금액</th>
                                        <th className='text-center'>결제일</th>
                                        <th className='text-center'>이용기간</th>
                                        <th className='text-center'>결제 수단</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {buyScrtnList.data?.data?.length > 0 ? (buyScrtnList.data.data.map((buyScrtn: any, index: number) => (
                                        <tr key={index}>
                                            <td className='text-center'>{buyScrtn.stlmNm}</td>
                                            <td className='text-center'>{Number(buyScrtn.stlmAmt).toLocaleString()}</td>
                                            <td className='text-center'>{buyScrtn.stlmCmptnDt}</td>
                                            <td className='text-center'>{buyScrtn.startDate} ~ {buyScrtn.endDate}</td>
                                            <td className='text-center'>{buyScrtn.stlmMthdNm}</td>
                                        </tr>
                                    ))
                                    ) : (
                                        <tr>
                                            <td colSpan={6} className='text-center'>데이터가 없습니다.</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            {userGroupMemberList.data?.meta ? (
                                <Pagination key="1" pageingDto={userGroupMemberList.data.meta} onClick={onSearch} />
                            ) : (<span></span>)
                            }
                        </div>
                        <div className="mb-4"></div>

                        {/* 탭에 따른 내용 렌더링 */}
                        {activeTab === 'GEO_NIC' && (
                            <>
                                {/* GEO_NIC 탭의 내용 */}

                                <div className="row">
                                    <div className='col-12'>
                                        <div className='dash-wrap-cont dash-info-wrap'>
                                            <h3 className='dash-ti'>비행촬영 프로젝트</h3>
                                            <div className='flex'>
                                                <div className='dash-info-item'>
                                                    <div className="dash-link-item">
                                                        <h4>등록된 프로젝트 수</h4>
                                                        <strong>{infoseedDashboard.data?.data?.DRN_PRJ_CNT ? Number(infoseedDashboard.data?.data?.DRN_PRJ_CNT + infoseedDashboard.data?.data?.PIC_PRJ_CNT).toLocaleString() : 0}</strong>
                                                    </div>
                                                </div>
                                                <div className='dash-info-item'>
                                                    <div className="dash-link-item">
                                                        <h4>촬영요청</h4>
                                                        <strong>{infoseedDashboard.data?.data?.RE_DRN_PRJ_CNT ? Number(infoseedDashboard.data?.data?.RE_DRN_PRJ_CNT).toLocaleString() : 0}</strong>
                                                    </div>
                                                </div>
                                                <div className='dash-info-item'>
                                                    <div className="dash-link-item">
                                                        <h4>완료 된 프로젝트 수</h4>
                                                        <strong>{infoseedDashboard.data?.data?.COM_DRN_PRJ_CNT ? Number(infoseedDashboard.data?.data?.COM_DRN_PRJ_CNT + infoseedDashboard.data?.data?.COM_PIC_PRJ_CNT).toLocaleString() : 0}</strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* GEO_NIC 탭의 내용 */}
                                <div className="row">
                                    <div className='col-12'>
                                        <div className='dash-wrap-cont dash-info-wrap'>
                                            <h3 className='dash-ti'>현장조사사진 프로젝트</h3>
                                            <div className='flex'>
                                                <div className='dash-info-item'>
                                                    <div className="dash-link-item">
                                                        <h4>등록된 프로젝트 수</h4>
                                                        <strong>{infoseedDashboard.data?.data?.PIC_PRJ_CNT ? Number(infoseedDashboard.data?.data?.PIC_PRJ_CNT).toLocaleString() : 0}</strong>
                                                    </div>
                                                </div>
                                                <div className='dash-info-item'>
                                                    <div className="dash-link-item">
                                                        <h4>저장된 파일 수</h4>
                                                        <strong>{infoseedDashboard.data?.data?.PIC_FILE_CNT ? Number(infoseedDashboard.data?.data?.PIC_FILE_CNT).toLocaleString() : 0}</strong>
                                                    </div>
                                                </div>
                                                <div className='dash-info-item'>
                                                    <div className="dash-link-item">
                                                        <h4>완료된 프로젝트 수</h4>
                                                        <strong>{infoseedDashboard.data?.data?.COM_PIC_PRJ_CNT ? Number(infoseedDashboard.data?.data?.COM_PIC_PRJ_CNT).toLocaleString() : 0}</strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='dash-wrap-cont'>
                                            <div className="col-content">
                                                <h3 className='dash-ti'>장비/인력 현황</h3>

                                                {/* 드론 장비 */}
                                                <div className={`progress-ui theme-blue`}>
                                                    <label htmlFor={"드론장비"} style={{ width: "10%", minWidth: "100px" }}>{"드론장비"}</label>
                                                    <span style={{ width: `10%` }}>
                                                        {Math.round(
                                                            ((Number(infoseedDashboard.data?.data?.USE_DRN_CNT ?? 0) /
                                                                Math.max(Number(infoseedDashboard.data?.data?.TOTAL_DRN_CNT ?? 1), 1)) * 100)
                                                        )} %
                                                    </span>
                                                    <div className="progress-group">
                                                        <progress
                                                            id={'item.id'}
                                                            max="100"
                                                            value={(
                                                                (Number(infoseedDashboard.data?.data?.USE_DRN_CNT ?? 0) /
                                                                    Math.max(Number(infoseedDashboard.data?.data?.TOTAL_DRN_CNT ?? 1), 1)) * 100
                                                            )}
                                                        ></progress>
                                                        <span className="count">{`${infoseedDashboard.data?.data?.TOTAL_DRN_CNT ?? 0}`}</span>
                                                    </div>
                                                </div>

                                                {/* 모바일 장비 */}
                                                <div className={`progress-ui theme-blue`}>
                                                    <label htmlFor={"모바일 장비"} style={{ width: "10%", minWidth: "100px" }}>{"모바일 장비"}</label>
                                                    <span style={{ width: `10%` }}>
                                                        {Math.round(
                                                            ((Number(infoseedDashboard.data?.data?.USE_EQM_CNT ?? 0) /
                                                                Math.max(Number(infoseedDashboard.data?.data?.TOTAL_EQM_CNT ?? 1), 1)) * 100)
                                                        )} %
                                                    </span>
                                                    <div className="progress-group">
                                                        <progress
                                                            id={'item.id'}
                                                            max="100"
                                                            value={(
                                                                (Number(infoseedDashboard.data?.data?.USE_EQM_CNT ?? 0) /
                                                                    Math.max(Number(infoseedDashboard.data?.data?.TOTAL_EQM_CNT ?? 1), 1)) * 100
                                                            )}
                                                        ></progress>
                                                        <span className="count">{`${infoseedDashboard.data?.data?.TOTAL_EQM_CNT ?? 0}`}</span>
                                                    </div>
                                                </div>

                                                {/* 조종사 */}
                                                <div className={`progress-ui theme-blue`}>
                                                    <label htmlFor={"조종사"} style={{ width: "10%", minWidth: "100px" }}>{"조종사"}</label>
                                                    <span style={{ width: `10%` }}>
                                                        {Math.round(
                                                            ((Number(infoseedDashboard.data?.data?.USE_PLT_CNT ?? 0) /
                                                                Math.max(Number(infoseedDashboard.data?.data?.TOTAL_PLT_CNT ?? 1), 1)) * 100)
                                                        )} %
                                                    </span>
                                                    <div className="progress-group">
                                                        <progress
                                                            id={'item.id'}
                                                            max="100"
                                                            value={(
                                                                (Number(infoseedDashboard.data?.data?.USE_PLT_CNT ?? 0) /
                                                                    Math.max(Number(infoseedDashboard.data?.data?.TOTAL_PLT_CNT ?? 1), 1)) * 100
                                                            )}
                                                        ></progress>
                                                        <span className="count">{`${infoseedDashboard.data?.data?.TOTAL_PLT_CNT ?? 0}`}</span>
                                                    </div>
                                                </div>

                                                {/* 조사자 */}
                                                <div className={`progress-ui theme-blue`}>
                                                    <label htmlFor={"조사자"} style={{ width: "10%", minWidth: "100px" }}>{"조사자"}</label>
                                                    <span style={{ width: `10%` }}>
                                                        {Math.round(
                                                            ((Number(infoseedDashboard.data?.data?.USE_INVS_CNT ?? 0) /
                                                                Math.max(Number(infoseedDashboard.data?.data?.TOTAL_INVS_CNT ?? 1), 1)) * 100)
                                                        )} %
                                                    </span>
                                                    <div className="progress-group">
                                                        <progress
                                                            id={'item.id'}
                                                            max="100"
                                                            value={(
                                                                (Number(infoseedDashboard.data?.data?.USE_INVS_CNT ?? 0) /
                                                                    Math.max(Number(infoseedDashboard.data?.data?.TOTAL_INVS_CNT ?? 1), 1)) * 100
                                                            )}
                                                        ></progress>
                                                        <span className="count">{`${infoseedDashboard.data?.data?.TOTAL_INVS_CNT ?? 0}`}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* 프로젝트 별 데이터 현황 */}
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='dash-wrap-cont'>
                                            <div className="col-content" style={{ height: 300 }}>
                                                <h3 className='dash-ti'>프로젝트별 데이터 사용량</h3>
                                                {(() => {
                                                    const transformedData = infoseedDashboard.data?.data?.PRJ_DATA_AMOUNT.map((project: any) => ({
                                                        name: project.PRJ_NM, // BarChart의 X축 이름
                                                        usagePercentage: Number(project.FILE_CNT) > 0 ? (Number(project.USE_AMOUNT) / Number(project.FILE_CNT)) * 100 : 0,
                                                        useAmount: Number(project.USE_AMOUNT), // 사용량
                                                        fileCount: Number(project.FILE_CNT), // 총 파일 수
                                                    })) || []; // 데이터가 없을 경우 빈 배열

                                                    return (
                                                        <>
                                                            <ResponsiveContainer width="100%" height="85%">
                                                                <PieChart>
                                                                    <Tooltip />
                                                                    <Pie
                                                                        data={transformedData}
                                                                        dataKey="usagePercentage" // 사용률 (%)을 기준으로 파이 차트 값 설정
                                                                        nameKey="name" // 프로젝트 이름을 라벨로 사용
                                                                        cx="50%" // 차트 중심 X 좌표
                                                                        cy="50%" // 차트 중심 Y 좌표
                                                                        outerRadius={80} // 파이 차트 반지름
                                                                        fill="#8884d8" // 파이 색상
                                                                        label={(entry) => `${entry.name}: ${entry.usagePercentage.toFixed(1)}%`} // 라벨 표시
                                                                    />
                                                                </PieChart>
                                                            </ResponsiveContainer>
                                                        </>
                                                    );
                                                })()}
                                            </div>
                                            <table className="table table-bordered table-hover">
                                                <thead>
                                                    <tr>
                                                        <th className='text-center'>프로젝트명</th>
                                                        <th className='text-center'>파일수(개)</th>
                                                        <th className='text-center'>사용 용량(MB)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {infoseedDashboard.data?.data?.PRJ_DATA_AMOUNT.length > 0 ? (infoseedDashboard.data?.data?.PRJ_DATA_AMOUNT.map((item: any, index: number) => (
                                                        <tr key={index}>
                                                            <td className='text-center'>{item.PRJ_NM}</td>
                                                            <td className='text-center'>{item.FILE_CNT}</td>
                                                            <td className='text-center'>{item.USE_AMOUNT}</td>
                                                        </tr>
                                                    ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan={6} className='text-center'>데이터가 없습니다.</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </>
                        )}

                        {activeTab === 'SIMMETA' && (
                            <>
                                {neoUsagesServies && neoUsagesServies.data ? (
                                    <>
                                        {/* GEO_NIC 탭의 내용 */}
                                        <div className="row">
                                            <div className='col-12'>
                                                <div className='dash-wrap-cont dash-info-wrap'>
                                                    <h3 className='dash-ti'>참여중인 프로젝트</h3>
                                                    <div className='flex'>
                                                        <div className='dash-info-item'>
                                                            <div className="dash-link-item">
                                                                <h4>객체탐지</h4>
                                                                <strong>{neoUsagesServies.data[0].used.toLocaleString()}</strong>
                                                            </div>
                                                        </div>
                                                        <div className='dash-info-item'>
                                                            <div className="dash-link-item">
                                                                <h4>객체삭제</h4>
                                                                <strong>{neoUsagesServies.data[1].used.toLocaleString()}</strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='dash-wrap-cont'>
                                                    <div className="col-content">
                                                        <h3 className='dash-ti'>서비스 남은 수량</h3>
                                                        <div className={`progress-ui theme-blue`}>
                                                            <label htmlFor={neoUsagesServies.data[0].category_code} style={{ width: "10%", minWidth: "100px" }}>{neoUsagesServies.data[0].category_name}</label>
                                                            <span style={{ width: `10%` }}>{neoUsagesServies.data[0].percent} % </span>
                                                            <div className="progress-group">
                                                                <progress id={'item.id'} max="100" value={neoUsagesServies.data[0].percent}></progress>
                                                                <span className="count">{`${neoUsagesServies.data[0].used.toLocaleString()} / ${neoUsagesServies.data[0].available.toLocaleString()}`}</span>
                                                            </div>
                                                        </div>
                                                        <div className={`progress-ui theme-blue`}>
                                                            <label htmlFor={neoUsagesServies.data[1].category_code} style={{ width: "10%", minWidth: "100px" }}>{neoUsagesServies.data[1].category_name}</label>
                                                            <span style={{ width: `10%` }}>{neoUsagesServies.data[1].percent} % </span>
                                                            <div className="progress-group">
                                                                <progress id={'item.id'} max="100" value={neoUsagesServies.data[1].percent}></progress>
                                                                <span className="count">{`${neoUsagesServies.data[1].used.toLocaleString()} / ${neoUsagesServies.data[1].available.toLocaleString()}`}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        {/* 회원 가입 현황 및 제품 구매 현황 그래프 */}
                                        <div className="row">
                                            <div className='col-6'>
                                                <div className='dash-wrap-cont'>
                                                    <h3 className='dash-ti'>객체 탐지 (프로젝트)</h3>
                                                    <div style={{ height: 300 }}>
                                                        <ResponsiveContainer width="100%" height="100%">
                                                            <BarChart data={neoProjectStat.data?.resultList1} >
                                                                <CartesianGrid strokeDasharray="3 3" />
                                                                <XAxis dataKey="date" />
                                                                <YAxis type='number' allowDecimals={false} />
                                                                <Tooltip />
                                                                <Bar dataKey="count" fill="#8884d8" name="개" />
                                                            </BarChart>
                                                        </ResponsiveContainer>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-6'>
                                                <div className='dash-wrap-cont'>
                                                    <h3 className='dash-ti'>객체 탐지 (작업)</h3>
                                                    <div style={{ height: 300 }}>
                                                        <ResponsiveContainer width="100%" height="100%">
                                                            <BarChart data={neoJobsStat.data?.resultList1} >
                                                                <CartesianGrid strokeDasharray="3 3" />
                                                                <XAxis dataKey="date" />
                                                                <YAxis type='number' allowDecimals={false} />
                                                                <Tooltip />
                                                                <Bar dataKey="count" fill="#8884d8" name="개" />
                                                            </BarChart>
                                                        </ResponsiveContainer>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='col-6'>
                                                <div className='dash-wrap-cont'>
                                                    <h3 className='dash-ti'>객체 삭제 (프로젝트)</h3>
                                                    <div style={{ height: 300 }}>
                                                        <ResponsiveContainer width="100%" height="100%">
                                                            <BarChart data={neoProjectStat.data?.resultList2} >
                                                                <CartesianGrid strokeDasharray="3 3" />
                                                                <XAxis dataKey="date" />
                                                                <YAxis type='number' allowDecimals={false} />
                                                                <Tooltip />
                                                                <Bar dataKey="count" fill="#8884d8" name="개" />
                                                            </BarChart>
                                                        </ResponsiveContainer>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-6'>
                                                <div className='dash-wrap-cont'>
                                                    <h3 className='dash-ti'>객체 삭제 (작업)</h3>
                                                    <div style={{ height: 300 }}>
                                                        <ResponsiveContainer width="100%" height="100%">
                                                            <BarChart data={neoJobsStat.data?.resultList2} >
                                                                <CartesianGrid strokeDasharray="3 3" />
                                                                <XAxis dataKey="date" />
                                                                <YAxis type='number' allowDecimals={false} />
                                                                <Tooltip />
                                                                <Bar dataKey="count" fill="#8884d8" name="개" />
                                                            </BarChart>
                                                        </ResponsiveContainer>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <p>데이터를 불러오는 중입니다...</p>
                                )}
                            </>
                        )}

                        {activeTab === 'MAPPRIME' && (
                            <>
                                {mapprimeDashboard && mapprimeDashboard.data ? (
                                    <>
                                        {/* GEO_NIC 탭의 내용 */}
                                        <div className="row">
                                            <div className='col-12'>
                                                <div className='dash-wrap-cont dash-info-wrap'>
                                                    <h3 className='dash-ti'>참여중인 프로젝트</h3>
                                                    <div className='flex'>
                                                        <div className='dash-info-item'>
                                                            <div className="dash-link-item">
                                                                <h4>프로젝트 개수</h4>
                                                                <strong>{mapprimeDashboard?.data?.count?.project?.total || 0}</strong>
                                                            </div>
                                                        </div>
                                                        <div className='dash-info-item'>
                                                            <div className="dash-link-item">
                                                                <h4>에셋 개수</h4>
                                                                <strong>
                                                                    {
                                                                        (mapprimeDashboard?.data?.count?.asset?.total || 0)
                                                                    }
                                                                </strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* 프로젝트 별 데이터 현황 */}
                                        <div className="row">
                                            <div className='col-6'>
                                                <div className='dash-wrap-cont'>
                                                    <h3 className='dash-ti'>프로젝트 별 데이터 현황</h3>
                                                    <div style={{ height: 300 }}>
                                                        <ResponsiveContainer width="100%" height="100%">
                                                            <BarChart data={mapprimeDashboard?.data?.topProjects} >
                                                                <CartesianGrid strokeDasharray="3 3" />
                                                                <XAxis dataKey="name" />
                                                                <YAxis type='number' allowDecimals={false} width={80} tickFormatter={(value) => filesize(value)} />
                                                                <Tooltip formatter={(value) => filesize(Number(value))} />
                                                                <Bar dataKey="size" fill="#8884d8" name="용량" />
                                                            </BarChart>
                                                        </ResponsiveContainer>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-6'>
                                                <div className='dash-wrap-cont'>
                                                    <h3 className='dash-ti'>에셋 생성 현황</h3>
                                                    <div style={{ height: 300 }}>
                                                        <ResponsiveContainer width="100%" height="100%">
                                                            <PieChart>
                                                                <Pie
                                                                    data={assetStatus}
                                                                    cx="50%"
                                                                    cy="50%"
                                                                    innerRadius={60}
                                                                    outerRadius={80}
                                                                    fill="#8884d8"
                                                                    paddingAngle={5}
                                                                    dataKey="value"
                                                                >
                                                                    {assetStatus.map((entry, index) => (
                                                                        <Cell
                                                                            key={`cell-${index}`}
                                                                            fill={COLORS[index % COLORS.length]}
                                                                        />
                                                                    ))}
                                                                </Pie>
                                                                <Tooltip />
                                                                <Legend />
                                                            </PieChart>
                                                        </ResponsiveContainer>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='dash-wrap-cont'>
                                                        <div className="col-content">
                                                            <h3 className='dash-ti'>프로젝트, 에셋 현황</h3>

                                                            {/* 프로젝트 진행 상태 */}
                                                            <div className={`progress-ui theme-blue`}>
                                                                <label htmlFor={"사용 용량"} style={{ width: "10%", minWidth: "100px" }}>{"프로젝트"}</label>
                                                                <span style={{ width: `10%` }}>
                                                                    {(
                                                                        ((mapprimeDashboard.data?.count?.project?.current ?? 0) /
                                                                            (mapprimeDashboard.data?.count?.project?.total ?? 1)) * 100
                                                                    ).toFixed(1)} %
                                                                </span>
                                                                <div className="progress-group">
                                                                    <progress
                                                                        id={'item.id'}
                                                                        max="100"
                                                                        value={(
                                                                            ((mapprimeDashboard.data?.count?.project?.current ?? 0) /
                                                                                (mapprimeDashboard.data?.count?.project?.total ?? 1)) * 100
                                                                        ).toFixed(1)}
                                                                    ></progress>
                                                                    <span className="count">{`${mapprimeDashboard.data?.count?.project?.current ?? 0}`}</span>
                                                                </div>
                                                            </div>

                                                            {/* 에셋 진행 상태 */}
                                                            <div className={`progress-ui theme-blue`}>
                                                                <label htmlFor={"사용 용량"} style={{ width: "10%", minWidth: "100px" }}>{"에셋"}</label>
                                                                <span style={{ width: `10%` }}>
                                                                    {(
                                                                        ((mapprimeDashboard.data?.count?.asset?.progress ?? 0) +
                                                                            (mapprimeDashboard.data?.count?.asset?.done ?? 0)) /
                                                                        (mapprimeDashboard.data?.count?.asset?.total ?? 1) * 100
                                                                    ).toFixed(1)} %
                                                                </span>
                                                                <div className="progress-group">
                                                                    <progress
                                                                        id={'item.id'}
                                                                        max="100"
                                                                        value={(
                                                                            ((mapprimeDashboard.data?.count?.asset?.progress ?? 0) +
                                                                                (mapprimeDashboard.data?.count?.asset?.done ?? 0)) /
                                                                            (mapprimeDashboard.data?.count?.asset?.total ?? 1) * 100
                                                                        )}
                                                                    ></progress>
                                                                    <span className="count">
                                                                        {`${(mapprimeDashboard.data?.count?.asset?.progress ?? 0) +
                                                                            (mapprimeDashboard.data?.count?.asset?.done ?? 0)}`}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) :
                                    (
                                        <p>데이터를 불러오는 중입니다...</p>
                                    )}
                                <div className="d-flex justify-content-right gap-2 mt-3" style={{ justifyContent: 'flex-end' }}>
                                    <button type="button" className="btn btn-secondary  m-1" onClick={() => navigate(-1)}>목록</button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            {/* content-page */}
        </div>
    );
};

export default Dashboard;
