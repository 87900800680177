import { FC, useState } from "react";
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import InputGroup from 'react-bootstrap/InputGroup';
import SideLayout from '../../containers/sidemenu';
import Header from '../../containers/header';
import ContentLayout from '../../containers/contentLayout';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './editor.scss';


const Read :FC = () => {

    const [content, setContent] = useState<string>('');

  const handleChange = (value: string) => {
    setContent(value); // 에디터 내용 업데이트
  };

    
    return (
        <div className="wrapper">
            <SideLayout />
            {/* leftside-menu */}
            <div className='content-page'>
                <Header />
                <ContentLayout title='제품관리'>
                <div className='row'>
                            <Tabs
                                defaultActiveKey="영상촬영 서비스"
                                id="fill-tab-example"
                                className="mb-3"
                                fill
                            >
                                <Tab eventKey="영상촬영 서비스" title="영상촬영 서비스">
                                    <h3 className='dash-ti mb-2'>기본 정보</h3>
                                    <Table bordered size="sm">
                                        <colgroup>
                                            <col style={{width: '150px' }} />
                                            <col style={{width: 'auto' }} />
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th>내용</th>
                                                <td>
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    data={content} // 초기 데이터
                                                    onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setContent(data); // 에디터 내용 업데이트
                                                    }}
                                                />
                                                <div className="output">
                                                    <h3>Output:</h3>
                                                    <div dangerouslySetInnerHTML={{ __html: content }} />
                                                </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>제품명</th>
                                                <td><Form.Control type="text" /></td>
                                            </tr>
                                            <tr>
                                                <th>제품설명</th>
                                                <td><Form.Control type="text" placeholder="*50자 내외로 작성" /></td>
                                            </tr>
                                        </tbody>
                                    </Table>

                                    <div className="display-count d-flex justify-content-between align-items-center">
                                        <h3 className='dash-ti mb-0'>비용 정보</h3>
                                        <div>
                                            <button type="button" className="btn btn-secondary btn-sm ">+ 선택 추가</button>
                                            <button type="button" className="btn btn-secondary btn-sm m-1">+ 데이터 추가</button>
                                        </div>
                                    </div>
                                    <Table bordered size="sm">
                                        <colgroup>
                                            <col style={{width: '150px' }} />
                                            <col style={{width: '150px' }} />
                                            <col style={{width: 'auto' }} />
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th rowSpan={3}>Basic</th>
                                                <td>1개월</td>
                                                <td>
                                                    <InputGroup>
                                                        <Form.Control aria-label="Dollar amount (with dot and two decimal places)" />
                                                        <InputGroup.Text>원</InputGroup.Text>
                                                    </InputGroup>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>12개월</td>
                                                <td>
                                                    <InputGroup>
                                                        <Form.Control aria-label="Dollar amount (with dot and two decimal places)" />
                                                        <InputGroup.Text>원</InputGroup.Text>
                                                    </InputGroup>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>설명</td>
                                                <td><Form.Control type="text" placeholder="*50자 내외로 작성" /></td>
                                            </tr>
                                            <tr>
                                                <th rowSpan={3}>Standard</th>
                                                <td>1개월</td>
                                                <td>
                                                    <InputGroup>
                                                        <Form.Control aria-label="Dollar amount (with dot and two decimal places)" />
                                                        <InputGroup.Text>원</InputGroup.Text>
                                                    </InputGroup>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>12개월</td>
                                                <td>
                                                    <InputGroup>
                                                        <Form.Control aria-label="Dollar amount (with dot and two decimal places)" />
                                                        <InputGroup.Text>원</InputGroup.Text>
                                                    </InputGroup>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>설명</td>
                                                <td><Form.Control type="text" placeholder="*50자 내외로 작성" /></td>
                                            </tr>
                                            <tr>
                                                <th rowSpan={3}>Premium</th>
                                                <td>1개월</td>
                                                <td>
                                                    <InputGroup>
                                                        <Form.Control aria-label="Dollar amount (with dot and two decimal places)" />
                                                        <InputGroup.Text>원</InputGroup.Text>
                                                    </InputGroup>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>12개월</td>
                                                <td>
                                                    <InputGroup>
                                                        <Form.Control aria-label="Dollar amount (with dot and two decimal places)" />
                                                        <InputGroup.Text>원</InputGroup.Text>
                                                    </InputGroup>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>설명</td>
                                                <td><Form.Control type="text" placeholder="*50자 내외로 작성" /></td>
                                            </tr>
                                            <tr>
                                                <th rowSpan={3}>Custom</th>
                                                <td>1개월</td>
                                                <td>
                                                    <InputGroup>
                                                        <Form.Control aria-label="Dollar amount (with dot and two decimal places)" />
                                                        <InputGroup.Text>원</InputGroup.Text>
                                                    </InputGroup>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>12개월</td>
                                                <td>
                                                    <InputGroup>
                                                        <Form.Control aria-label="Dollar amount (with dot and two decimal places)" />
                                                        <InputGroup.Text>원</InputGroup.Text>
                                                    </InputGroup>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>설명</td>
                                                <td><Form.Control type="text" placeholder="*50자 내외로 작성" /></td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    
                                    <h3 className='dash-ti mb-2'>상세 구성</h3>
                                    <Table bordered size="sm">
                                        <colgroup>
                                            <col style={{width: '250px' }} />
                                            <col style={{width: '150px' }} />
                                            <col style={{width: 'auto' }} />
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th>공정관리</th>
                                                <td colSpan={3}>
                                                    <Form>
                                                        {['checkbox'].map((type) => (
                                                            <div key={`inline-${type}`}>
                                                                <Form.Check
                                                                    inline
                                                                    label="전체"
                                                                    name="group1"
                                                                    type="checkbox"
                                                                    id={`inline-${type}-1`}
                                                                />
                                                                <Form.Check
                                                                    inline
                                                                    label="Basic"
                                                                    name="group1"
                                                                    type="checkbox"
                                                                    id={`inline-${type}-2`}
                                                                />
                                                                <Form.Check
                                                                    inline
                                                                    label="Standard"
                                                                    name="group1"
                                                                    type="checkbox"
                                                                    id={`inline-${type}-3`}
                                                                />
                                                                <Form.Check
                                                                    inline
                                                                    label="Premium"
                                                                    name="group1"
                                                                    type="checkbox"
                                                                    id={`inline-${type}-4`}
                                                                />
                                                                <Form.Check
                                                                    inline
                                                                    label="Custom"
                                                                    name="group1"
                                                                    type="checkbox"
                                                                    id={`inline-${type}-5`}
                                                                />
                                                            </div>
                                                        ))}
                                                    </Form>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>장비/인력 관리</th>
                                                <td colSpan={3}>
                                                    <Form>
                                                        {['checkbox'].map((type) => (
                                                            <div key={`inline-${type}`}>
                                                                <Form.Check
                                                                    inline
                                                                    label="전체"
                                                                    name="group2"
                                                                    type="checkbox"
                                                                    id={`inline02-${type}-1`}
                                                                />
                                                                <Form.Check
                                                                    inline
                                                                    label="Basic"
                                                                    name="group2"
                                                                    type="checkbox"
                                                                    id={`inline02-${type}-2`}
                                                                />
                                                                <Form.Check
                                                                    inline
                                                                    label="Standard"
                                                                    name="group2"
                                                                    type="checkbox"
                                                                    id={`inline02-${type}-3`}
                                                                />
                                                                <Form.Check
                                                                    inline
                                                                    label="Premium"
                                                                    name="group2"
                                                                    type="checkbox"
                                                                    id={`inline02-${type}-4`}
                                                                />
                                                                <Form.Check
                                                                    inline
                                                                    label="Custom"
                                                                    name="group2"
                                                                    type="checkbox"
                                                                    id={`inline02-${type}-5`}
                                                                />
                                                            </div>
                                                        ))}
                                                    </Form>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>정밀격자 주소(geo.nick)</th>
                                                <td colSpan={3}>
                                                    <Form>
                                                        {['checkbox'].map((type) => (
                                                            <div key={`inline-${type}`}>
                                                                <Form.Check
                                                                    inline
                                                                    label="전체"
                                                                    name="group3"
                                                                    type="checkbox"
                                                                    id={`inline03-${type}-1`}
                                                                />
                                                                <Form.Check
                                                                    inline
                                                                    label="Basic"
                                                                    name="group3"
                                                                    type="checkbox"
                                                                    id={`inline03-${type}-2`}
                                                                />
                                                                <Form.Check
                                                                    inline
                                                                    label="Standard"
                                                                    name="group3"
                                                                    type="checkbox"
                                                                    id={`inline03-${type}-3`}
                                                                />
                                                                <Form.Check
                                                                    inline
                                                                    label="Premium"
                                                                    name="group3"
                                                                    type="checkbox"
                                                                    id={`inline03-${type}-4`}
                                                                />
                                                                <Form.Check
                                                                    inline
                                                                    label="Custom"
                                                                    name="group3"
                                                                    type="checkbox"
                                                                    id={`inline03-${type}-5`}
                                                                />
                                                            </div>
                                                        ))}
                                                    </Form>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>현장조사 사진(geo.pic)</th>
                                                <td colSpan={3}>
                                                    <Form>
                                                        {['checkbox'].map((type) => (
                                                            <div key={`inline-${type}`}>
                                                                <Form.Check
                                                                    inline
                                                                    label="전체"
                                                                    name="group4"
                                                                    type="checkbox"
                                                                    id={`inline04-${type}-1`}
                                                                />
                                                                <Form.Check
                                                                    inline
                                                                    label="Basic"
                                                                    name="group4"
                                                                    type="checkbox"
                                                                    id={`inline04-${type}-2`}
                                                                />
                                                                <Form.Check
                                                                    inline
                                                                    label="Standard"
                                                                    name="group4"
                                                                    type="checkbox"
                                                                    id={`inline04-${type}-3`}
                                                                />
                                                                <Form.Check
                                                                    inline
                                                                    label="Premium"
                                                                    name="group4"
                                                                    type="checkbox"
                                                                    id={`inline04-${type}-4`}
                                                                />
                                                                <Form.Check
                                                                    inline
                                                                    label="Custom"
                                                                    name="group4"
                                                                    type="checkbox"
                                                                    id={`inline04-${type}-5`}
                                                                />
                                                            </div>
                                                        ))}
                                                    </Form>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th rowSpan={4}>현장조사 사진 용량</th>
                                                <td>Basic</td>
                                                <td><Form.Control type="text" /></td>
                                            </tr>
                                            <tr>
                                                <td>Standard</td>
                                                <td><Form.Control type="text" /></td>
                                            </tr>
                                            <tr>
                                                <td>Premium</td>
                                                <td><Form.Control type="text" /></td>
                                            </tr>
                                            <tr>
                                                <td>custom</td>
                                                <td><Form.Control type="text" /></td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Tab>
                                <Tab eventKey="AI 데이터 생성관리 서비스" title="AI 데이터 생성관리 서비스">
                                    AI 데이터 생성관리 서비스
                                </Tab>
                                <Tab eventKey="디지털트윈 지도 서비스" title="디지털트윈 지도 서비스">
                                    디지털트윈 지도 서비스
                                </Tab>
                            </Tabs>
                        </div>
                </ContentLayout> 
            </div>
        </div>
    )
    
}
export default Read;
