import { FC, useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../modules/";
import { getCodeAsync , putCodeAsync , resetSiteMngDataAsync } from '../../modules/siteMng';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { UpdateCodeDto } from '../../types/common/UpdateCodeDto';

interface props {
    openModel : boolean;
    cdGrpId : string;
    cmCdId : string;
    handleModalClose: (refreshYn :string) => void; // onChange prop 추가
}

const UpdateCodeModal:FC<props> = ({ openModel , cdGrpId , cmCdId , handleModalClose }) => {

    const dispatch = useDispatch();

    const codeInfo    = useSelector((state:RootState) => state.siteMng.code);
    const codeUpdate  = useSelector((state:RootState) => state.siteMng.codeUpdate);
    
    const codeGrp = cdGrpId + "_" + cmCdId;
    const [updateCodeParams, setUpdateCode] = useState<UpdateCodeDto>({
        cdVl    : ""
      , cdDesc  : ""
      , cdOrr   : ""
      , etcCn1  : ""
      , etcCn2  : ""
      , etcCn3  : ""
  });
    
    const inputHandleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        const { name, value } = e.target;
        setUpdateCode((prev) => ({ ...prev, [name]: value}));
    };

    // modal 오픈시 상세정보 조회
    useEffect(() => {
        if (openModel && cdGrpId) {
            
            dispatch(getCodeAsync.request(codeGrp));
        }
    },[openModel]);

    // 조회 결과 적용
    useEffect(() => {
        if (codeInfo?.data?.data) {
            setUpdateCode((prev) => ({ ...prev 
                , cdVl    : codeInfo?.data?.data.cdVl
                , cdDesc  : codeInfo?.data?.data.cdDesc
                , cdOrr   : codeInfo?.data?.data.cdOrr
                , etcCn1  : codeInfo?.data?.data.etcCn1
                , etcCn2  : codeInfo?.data?.data.etcCn2
                , etcCn3  : codeInfo?.data?.data.etcCn3
            }));
        }
        
    },[codeInfo]);


    const onGrpSave = () => {
        
        if (!updateCodeParams.cdVl) {
            alert("코드명를 입력해 주십시오.");
            return;
        }

        if (!updateCodeParams.cdOrr) {
            alert("순서를 입력해 주십시오.");
            return;
        }

        dispatch(putCodeAsync.request({
            codeGrp : codeGrp 
          , updateCodeDto : updateCodeParams
        }));
    }

    // modal 오픈시 상세정보 조회
    useEffect(() => {
        if (codeUpdate && codeUpdate.data) {
            const status = codeUpdate.data.status;
    
            if (status == "error") {
                alert(codeUpdate.data.error.message);
            } else if (status == "success") {
                const fetchData = async () => {
                    await alert("수정 되었습니다.");
                    await dispatch(resetSiteMngDataAsync.request(null));
                    await returnModalClose("Y");
                };

                fetchData();
            }
        }
    },[codeUpdate]);

    const returnModalClose = (refresh:string) => {
        handleModalClose(refresh);
    }

    return (
        <Modal show={openModel} onHide={() => returnModalClose('N')} style={{top:"20%"}}>
            <Modal.Header closeButton>
                <Modal.Title>공통코드 수정</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h3 className='dash-ti mb-2'>필수 정보</h3>
                <Table bordered size="sm">
                    <colgroup>
                        <col style={{ width: '120px' }} />
                        <col style={{ width: 'auto' }} />
                    </colgroup>
                    <tbody>
                        <tr>    
                            <th>그룹코드</th>
                            <td style={{ paddingLeft: '10px' }}>
                            {cdGrpId}
                            </td>
                        </tr>
                        <tr>
                            <th><span style={{color:"red"}}>＊</span>코드</th>
                            <td style={{ paddingLeft: '10px' }}>
                                {cmCdId}
                            </td>
                        </tr>
                        <tr>
                            <th><span style={{color:"red"}}>＊</span>코드명</th>
                            <td style={{ paddingLeft: '10px' }}>
                                <input className="form-control form-control-sm" type="text" value={updateCodeParams.cdVl}  aria-label=".form-control-sm example" id="cdVl" name="cdVl" onChange={inputHandleChange}/>
                            </td>
                        </tr>
                        <tr>
                            <th><span style={{color:"red"}}>＊</span>순서</th>
                            <td style={{ paddingLeft: '10px' }}>
                                <input className="form-control form-control-sm" type="text" value={updateCodeParams.cdOrr}  aria-label=".form-control-sm example" id="cdOrr" name="cdOrr" onChange={inputHandleChange}/>
                            </td>
                        </tr>
                        <tr>
                            <th>설명</th>
                            <td style={{ paddingLeft: '10px' }}>
                                <input className="form-control form-control-sm" type="text" value={updateCodeParams.cdDesc}  aria-label=".form-control-sm example" id="cdDesc" name="cdDesc" onChange={inputHandleChange}/>
                            </td>
                        </tr>
                        <tr>
                            <th>기타1</th>
                            <td style={{ paddingLeft: '10px' }}>
                                <input className="form-control form-control-sm" type="text" value={updateCodeParams.etcCn1}  aria-label=".form-control-sm example" id="etcCn1" name="etcCn1" onChange={inputHandleChange}/>
                            </td>
                        </tr>
                        <tr>
                            <th>기타2</th>
                            <td style={{ paddingLeft: '10px' }}>
                                <input className="form-control form-control-sm" type="text" value={updateCodeParams.etcCn2}  aria-label=".form-control-sm example" id="etcCn2" name="etcCn2" onChange={inputHandleChange}/>
                            </td>
                        </tr>
                        <tr>
                            <th>기타3</th>
                            <td style={{ paddingLeft: '10px' }}>
                                <input className="form-control form-control-sm" type="text" value={updateCodeParams.etcCn3}  aria-label=".form-control-sm example" id="etcCn3" name="etcCn3" onChange={inputHandleChange}/>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onGrpSave}>
                    저장
                </Button>
                <Button variant="secondary" onClick={() => returnModalClose('N')}>
                    닫기
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default UpdateCodeModal;