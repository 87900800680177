import axios from 'axios';
import { CreateUserDto, SelectUserDto, UserDto } from '../types/UserDto';
import config from '../config';
import { PotalCommDto } from '../types/PotalCommDto';
import { ErrorResponse, SuccessResponse } from '../types/Response';
import { SelectUserGroupDto } from '../types/UserGroupDto';
import accessTokenPortalApi from './api';

const url = "usergroup";
export async function findUserGroupList(selectUserDto: SelectUserDto): Promise<SuccessResponse<SelectUserGroupDto[], PotalCommDto> | ErrorResponse> {
  try {
    const response = await accessTokenPortalApi.get<SuccessResponse<SelectUserGroupDto[], PotalCommDto> | ErrorResponse>(
      `/${url}`,
      {
        params: selectUserDto,
      }
    );
    if (response.data.status === 'success') {
      // console.log('success');
      return response.data;
    } else {
      // console.log('else');
      return response.data;
    }
  } catch (error) {
    console.error('Error fetching usergroup:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: 'Failed to fetch usergroup list',
      },
    };
  }
}

export async function findUserGroupMemberList({ ognzGrpSn, selectUserDto }: { ognzGrpSn: number, selectUserDto: SelectUserDto }): Promise<SuccessResponse<SelectUserGroupDto[], PotalCommDto> | ErrorResponse> {
  try {
    const response = await accessTokenPortalApi.get<SuccessResponse<SelectUserGroupDto[], PotalCommDto> | ErrorResponse>(
      `/${url}/${ognzGrpSn}`,
      {
        params: selectUserDto,
      }
    );
    if (response.data.status === 'success') {
      // console.log('success');
      return response.data;
    } else {
      // console.log('else');
      return response.data;
    }
  } catch (error) {
    console.error('Error fetching usergroup:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: 'Failed to fetch usergroup list',
      },
    };
  }
}

export async function updateGroupUser({ groupId, userIds, role }: { groupId: string, userIds: string[], role: string }): Promise<SuccessResponse<null> | ErrorResponse> {
  try {
    const response = await accessTokenPortalApi.put<SuccessResponse<null> | ErrorResponse>(
      `/${url}/group/${groupId}/users`,
      {
        userId: userIds,
        role: role
      },
    );

    if (response.data.status === 'success') {
      // console.log('success');
      return response.data;
    } else {
      // console.log('else');
      return response.data;
    }
  } catch (error) {
    console.error('Error deleting user from group:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: 'Failed to delete user from group',
      },
    };
  }
}

export async function deleteGroupUsers({ groupId, userIds }: { groupId: string, userIds: string[] }): Promise<SuccessResponse<null> | ErrorResponse> {
  try {
    const response = await accessTokenPortalApi.delete<SuccessResponse<null> | ErrorResponse>(
      `/${url}/group/${groupId}/users`,
      {
        data: {
          userId: userIds
        }
      }
    );

    if (response.data.status === 'success') {
      // console.log('success');
      return response.data;
    } else {
      // console.log('else');
      return response.data;
    }
  } catch (error) {
    console.error('Error deleting user from group:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: 'Failed to delete user from group',
      },
    };
  }
}

export async function updateGroupUserRole({
  ognzGrpSn,
  userId,
  sltnId,
  role
}: { ognzGrpSn: number; userId: string[]; sltnId: string; role: string }): Promise<SuccessResponse<null> | ErrorResponse> {
  try {
    // 여러 사용자의 ID와 권한을 전달할 DTO 구성
    const requestBody = {
      userId,
      sltnId,
      role,
      ognzGrpSn,
    };

    const response = await accessTokenPortalApi.put<SuccessResponse<null> | ErrorResponse>(
      `/usergroup/role/${ognzGrpSn}`,
      requestBody,
    );

    if (response.data.status === 'success') {
      // console.log('User updated successfully');
      return response.data; // 업데이트된 사용자 데이터를 반환
    } else {
      // console.log('Update failed with message:', response.data.error.message);
      return response.data; // 에러 데이터를 반환
    }
  } catch (error) {
    console.error('Error updated user:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: 'Failed to updated user',
      },
    };
  }
}

