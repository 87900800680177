import { deprecated } from "typesafe-actions";
const { createAction } = deprecated;

export const SET_INCREASE = "count/SET_INCREASE";
export const SET_DECREASE = "count/SET_DECREASE";
export const setIncrease = createAction(
  SET_INCREASE,
  (action) => (count: number) =>
    action({
      count,
    })
);
export const setDecrease = createAction(
  SET_DECREASE,
  (action) => (count: number) =>
    action({
      count,
    })
);