import { createAsyncAction } from 'typesafe-actions';
import { AxiosError } from 'axios';
import { CreateUserDto, ProfileDto, SelectUserDto, UserDto } from '../../types/UserDto';
import { ErrorResponse, SuccessResponse } from '../../types/Response';
import { PotalCommDto } from '../../types/PotalCommDto';

export const FIND_USER_LIST = 'api/FIND_USER_LIST';
export const FIND_USER_LIST_SUCCESS = 'api/FIND_USER_LIST_SUCCESS';
export const FIND_USER_LIST_ERROR = 'api/FIND_USER_LIST_ERROR';
export const FIND_USER = 'api/FIND_USER';
export const FIND_USER_SUCCESS = 'api/FIND_USER_SUCCESS';
export const FIND_USER_ERROR = 'api/FIND_USER_ERROR';
export const UPDATE_USER = 'api/UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'api/UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'api/UPDATE_USER_ERROR';
export const DELETE_USER = 'api/DELETE_USER';
export const DELETE_USER_SUCCESS = 'api/DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'api/DELETE_USER_ERROR';


export const findUserListAsync = createAsyncAction(
  FIND_USER_LIST,
  FIND_USER_LIST_SUCCESS,
  FIND_USER_LIST_ERROR
)<SelectUserDto, SuccessResponse<UserDto[], PotalCommDto> | ErrorResponse, AxiosError>();

export const findUserAsync = createAsyncAction(
  FIND_USER,
  FIND_USER_SUCCESS,
  FIND_USER_ERROR
)<string, SuccessResponse<ProfileDto> | ErrorResponse, AxiosError>();

export const updateUserAsync = createAsyncAction(
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR
)<{userId: string, userDto: UserDto}, SuccessResponse<null> | ErrorResponse, AxiosError>();

export const deleteUserAsync = createAsyncAction(
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR
)<string[], SuccessResponse<null> | ErrorResponse, AxiosError>();

